import type { RootStore } from "~redux/store";
import { injectStore as injectHubStore } from "~/eramHubConnection";
import { injectStore as injectTrackManagerStore } from "~/trackManager";
import { injectStore as injectSdStore } from "~/situationDisplayStore";
import { nexradManager } from "~/nexradManager";
import { nxBrightSelector } from "~redux/slices/eramStateSlice";

export const injectStore = (store: RootStore) => {
  void injectHubStore(store);
  injectSdStore(store);
  injectTrackManagerStore(store);

  const nxAlpha = nxBrightSelector(store.getState()) / 100;
  nexradManager.level1Graphics.alpha = nxAlpha;
  nexradManager.level2Graphics.alpha = nxAlpha;
  nexradManager.level3Graphics.alpha = nxAlpha;
};
