import React from "react";
import { Container } from "@pixi/react";
import { GeoMap } from "components/map/GeoMap";
import type { GeomapConfig, GeomapFilterButtonPosition } from "@poscon/shared-types";

type GeomapsProps = {
  geomapConfig: GeomapConfig;
};
export const Geomaps = ({ geomapConfig }: GeomapsProps) => {
  return (
    <Container name="GEOMAP_CONTAINER" sortableChildren zIndex={2} eventMode="none">
      {Array.from({ length: 20 }, (_, i) => (
        <GeoMap key={i} geomapConfig={geomapConfig} filterGroup={(i + 1) as GeomapFilterButtonPosition} />
      ))}
    </Container>
  );
};
