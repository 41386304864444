import React from "react";
import type { EramButtonId } from "types/eramButton";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { setConfirmClearAllMacros } from "~redux/slices/eramTempStateSlice";
import { colorNameMap, useOnUnmount } from "@poscon/shared-frontend";
import type { EramButtonProps } from "components/buttons/EramButton";
import { EramBaseButton } from "components/buttons/EramButton";
import { eramButtonActionMap } from "~redux/thunks/eramButtonActionMap";

export const EramCommandButton = (props: EramButtonProps<EramButtonId | "MACRO_BUTTON">) => {
  const dispatch = useRootDispatch();
  const commandActive = useRootSelector(
    (state) =>
      (props.buttonId !== "MACRO_BUTTON" && eramButtonActionMap[props.buttonId]?.selected?.(state)) ?? false,
  );
  const buttonAction = props.buttonId !== "MACRO_BUTTON" ? eramButtonActionMap[props.buttonId]?.action : undefined;
  useOnUnmount(() => {
    if (props.buttonId === "CLR_ALL_MACROS") {
      dispatch(setConfirmClearAllMacros(false));
    }
  });
  return (
    <EramBaseButton
      {...props}
      onmousedown={(event) => {
        if (buttonAction) {
          dispatch(buttonAction());
        }
        props.onmousedown?.(event);
      }}
      baseBgColor={commandActive ? colorNameMap.commandActiveBrown : colorNameMap.cyan}
    />
  );
};
