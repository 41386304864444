import React, { useRef } from "react";
import type { EramButtonId } from "types/eramButton";
import { masterToolbarButtonList } from "types/eramButton";
import { DOWN_ARROW, UP_ARROW } from "@poscon/shared-types";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import type { MovableToolbarId, ToolbarId } from "~redux/slices/eramStateSlice";
import {
  moveToolbarDown,
  moveToolbarUp,
  openToolbarListSelector,
  showHorizToolbarSelector,
  showLeftToolbarSelector,
  showMasterToolbarSelector,
  showMcaToolbarSelector,
  showRightToolbarSelector,
  toolbarIsRaisedSelector,
} from "~redux/slices/eramStateSlice";
import { useTearOffContext } from "contexts/tearOffContext";
import { GhostButton } from "components/buttons/GhostButton";
import { ButtonTypeMap } from "components/buttons/ButtonTypeMap";
import {
  colorNameMap,
  eramFontNameMap,
  useFocused,
} from "@poscon/shared-frontend";
import { useSituationDisplay } from "contexts/sdContext";
import {
  ToolbarContextProvider,
  useToolbarContext,
} from "contexts/toolbarContext";
import type { Graphics as PixiGraphics, Sprite as PixiSprite } from "pixi.js";
import { useButtonContext } from "contexts/buttonContext";
import { BitmapText, Container, Graphics } from "@pixi/react";
import { layerZIndexMap } from "~/layerZIndexMap";
import { InteractiveContainer } from "./utils/InteractiveContainer";

const arrowBaseBgColor = [0x4d / 0xff, 0x52 / 0xff, 0x4e / 0xff];

// disable this rule so that all menus in the toolbar close when it is moved and for the ghost buttons
/* eslint-disable react/no-array-index-key */

type ToolbarArrowProps = {
  toolbarId: MovableToolbarId;
};

const ToolbarArrows = ({ toolbarId }: ToolbarArrowProps) => {
  const dispatch = useRootDispatch();
  const { fontWidth, fontHeight, buttonHeight, fontSize } = useButtonContext();
  const [topList, bottomList] = useRootSelector(openToolbarListSelector);
  const upArrowRef = useRef<PixiGraphics>(null);
  const downArrowRef = useRef<PixiGraphics>(null);
  const upFocused = useFocused(upArrowRef);
  const downFocused = useFocused(downArrowRef);

  const topIndex = topList.indexOf(toolbarId);
  const bottomIndex = bottomList.indexOf(toolbarId);

  const showUpArrow = topIndex !== 0;
  const showDownArrow =
    bottomList.length === 0 || bottomIndex !== bottomList.length - 1;
  const fontName = eramFontNameMap[fontSize];

  return (
    <>
      {showUpArrow && (
        <InteractiveContainer y={1} key="UP">
          <Graphics
            name={`${toolbarId}_UP_ARROW`}
            eventMode="static"
            ref={upArrowRef}
            draw={(graphics) => {
              graphics.clear();
              graphics.lineStyle(1, upFocused ? 0xf0f0f0 : 0x494949);
              graphics.beginFill(arrowBaseBgColor);
              graphics.drawRect(1, 1, fontWidth, buttonHeight);
              graphics.endFill();
            }}
            onmousedown={() => {
              dispatch(moveToolbarUp(toolbarId));
            }}
          />
          <BitmapText
            text={UP_ARROW}
            y={1}
            eventMode="none"
            fontName={fontName}
            style={{
              fontName,
              tint: colorNameMap.white,
            }}
          />
        </InteractiveContainer>
      )}
      {showDownArrow && (
        <InteractiveContainer y={buttonHeight + 2} key="DOWN">
          <Graphics
            name={`${toolbarId}_DOWN_ARROW`}
            eventMode="static"
            ref={downArrowRef}
            draw={(graphics) => {
              graphics.clear();
              graphics.lineStyle(1, downFocused ? 0xf0f0f0 : 0x494949);
              graphics.beginFill(arrowBaseBgColor);
              graphics.drawRect(1, 1, fontWidth, buttonHeight);
              graphics.endFill();
            }}
            onmousedown={() => {
              dispatch(moveToolbarDown(toolbarId));
            }}
          />
          <BitmapText
            text={DOWN_ARROW}
            y={buttonHeight - fontHeight + 1}
            eventMode="none"
            fontName={fontName}
            style={{
              fontName,
              tint: colorNameMap.white,
            }}
          />
        </InteractiveContainer>
      )}
    </>
  );
};

type ToolbarButtonContainerProps = {
  toolbarId: ToolbarId;
  row?: 0 | 1;
  col?: number;
  lockedButtonList?: EramButtonId[];
  active: boolean;
};
const ToolbarButtonContainer = ({
  toolbarId,
  row,
  col,
  lockedButtonList,
  active,
}: ToolbarButtonContainerProps) => {
  const buttonList = useRootSelector(
    (state) => state.eram.toolbarButtonPositions,
  );
  const {
    rect: { width, height },
  } = useSituationDisplay();
  const { setActiveTearingTb } = useTearOffContext();

  const { fontWidth, buttonWidth, buttonHeight } = useButtonContext();

  const length =
    row !== undefined
      ? Math.floor((width - fontWidth - 2) / (buttonWidth + 2))
      : height / (buttonHeight + 2);

  let x = 0;
  let y = 0;
  if (row !== undefined) {
    x = fontWidth + 2;
    y = 1;
  }
  if (col !== undefined) {
    x = buttonWidth * col;
    y = 0;
  }

  return (
    <Container
      x={x}
      y={y}
      eventMode="static"
      sortableChildren
      onmouseenter={() => {
        setActiveTearingTb({ toolbarId, row });
      }}
      onmouseleave={() => setActiveTearingTb(null)}
    >
      {Array.from({ length }, (_, i) => {
        const v = lockedButtonList?.[i];
        if (v) {
          const buttonId = v;
          const Component = ButtonTypeMap[buttonId];
          const path = `${toolbarId}/${row ?? i}/${col ?? i}/${buttonId}`;
          return (
            <Component
              key={path}
              path={path}
              buttonId={buttonId}
              toolbarPosition={{ row: row ?? i, col: col ?? i }}
              toolbarDirection={row !== undefined ? "horizontal" : "vertical"}
              isLockedOnMasterToolbar
            />
          );
        }
        const buttonData = buttonList.find(
          (button) =>
            button.toolbarId === toolbarId &&
            ((row === undefined && button.row === i) || button.row === row) &&
            ((col === undefined && button.col === i) || button.col === col),
        );
        const buttonId = buttonData?.buttonId;
        const macroLabel = buttonData?.macroLabel;
        const Component = buttonId ? ButtonTypeMap[buttonId] : null;
        const path = `${toolbarId}/${row ?? i}/${col ?? i}/${buttonId}`;
        return Component && buttonId ? (
          <Component
            key={path}
            path={path}
            buttonId={buttonId}
            toolbarPosition={{ row: row ?? i, col: col ?? i }}
            macroLabel={macroLabel}
            toolbarDirection={row !== undefined ? "horizontal" : "vertical"}
          />
        ) : (
          <GhostButton
            key={i}
            toolbarId={toolbarId}
            row={row ?? i}
            col={col ?? i}
            active={active}
          />
        );
      })}
    </Container>
  );
};

type ToolbarProps = {
  toolbarId: MovableToolbarId;
};
const MovableToolbar = ({ toolbarId }: ToolbarProps) => {
  const [topList, bottomList] = useRootSelector(openToolbarListSelector);
  const { activeTearingTb } = useTearOffContext();
  const isRaised = useRootSelector((state) =>
    toolbarIsRaisedSelector(state, toolbarId),
  );
  const {
    rect: { width, height },
  } = useSituationDisplay();
  const { fillColor, tbBorderColor } = useToolbarContext();
  const { buttonHeight } = useButtonContext();

  const toolbarHeight = buttonHeight * 2 + 5;
  const topIndex = topList.indexOf(toolbarId);
  const bottomIndex = bottomList.indexOf(toolbarId);

  const x = 0;
  const y =
    topIndex >= 0
      ? topIndex * (toolbarHeight + 1)
      : height + 2 - (bottomList.length - bottomIndex) * (toolbarHeight + 1);

  return (
    <Container
      name={toolbarId}
      zIndex={isRaised ? 110 : 90}
      x={x}
      y={y}
      eventMode="static"
    >
      <Graphics
        draw={(graphics) => {
          graphics.clear();
          graphics.beginFill(fillColor);
          graphics.drawRect(0, 0, width, toolbarHeight);
          graphics.endFill();
          graphics.lineStyle(1, tbBorderColor);
          graphics.moveTo(0, 0);
          graphics.lineTo(width, 0);
          graphics.moveTo(0, toolbarHeight);
          graphics.lineTo(width, toolbarHeight);
        }}
      />
      <ToolbarArrows toolbarId={toolbarId} />
      <ToolbarButtonContainer
        toolbarId={toolbarId}
        lockedButtonList={
          toolbarId === "MASTER_TOOLBAR"
            ? masterToolbarButtonList[0]
            : undefined
        }
        row={0}
        active={
          activeTearingTb?.toolbarId === toolbarId && activeTearingTb?.row === 0
        }
      />
      <ToolbarButtonContainer
        toolbarId={toolbarId}
        lockedButtonList={
          toolbarId === "MASTER_TOOLBAR"
            ? masterToolbarButtonList[1]
            : undefined
        }
        row={1}
        active={
          activeTearingTb?.toolbarId === toolbarId && activeTearingTb?.row === 1
        }
      />
    </Container>
  );
};

type VerticalToolbarProps = { toolbarId: "LEFT_TOOLBAR" | "RIGHT_TOOLBAR" };
const VerticalToolbar = ({ toolbarId }: VerticalToolbarProps) => {
  const isRaised = useRootSelector((state) =>
    toolbarIsRaisedSelector(state, toolbarId),
  );
  const { activeTearingTb } = useTearOffContext();
  const {
    rect: { width, height },
  } = useSituationDisplay();
  const { fillColor, tbBorderColor } = useToolbarContext();
  const { buttonWidth } = useButtonContext();

  const x = toolbarId === "LEFT_TOOLBAR" ? 1 : width - (buttonWidth + 1);
  const y = 0;

  return (
    <Container zIndex={isRaised ? 110 : 90} x={x} y={y} eventMode="static">
      <Graphics
        draw={(graphics) => {
          graphics.clear();
          graphics.beginFill(fillColor);
          graphics.drawRect(-1, 0, buttonWidth + 2, height);
          graphics.endFill();

          const borderX = toolbarId === "LEFT_TOOLBAR" ? buttonWidth + 1 : -1;
          graphics.lineStyle(1, tbBorderColor);
          graphics.moveTo(borderX, 0);
          graphics.lineTo(borderX, height);
        }}
      />
      <Container x={toolbarId === "LEFT_TOOLBAR" ? 1 : 0} eventMode="static">
        <ToolbarButtonContainer
          toolbarId={toolbarId}
          col={0}
          active={activeTearingTb?.toolbarId === toolbarId}
        />
      </Container>
    </Container>
  );
};

export const MasterToolbar = () => (
  <MovableToolbar toolbarId="MASTER_TOOLBAR" />
);

const McaToolbar = () => <MovableToolbar toolbarId="MCA_TOOLBAR" />;

const HorizToolbar = () => <MovableToolbar toolbarId="HORIZ_TOOLBAR" />;

const LeftToolbar = () => (
  <VerticalToolbar toolbarId="LEFT_TOOLBAR" key="LEFT_TOOLBAR" />
);

const RightToolbar = () => (
  <VerticalToolbar toolbarId="RIGHT_TOOLBAR" key="RIGHT_TOOLBAR" />
);

type ToolbarContainerProps = {
  maskRef: React.MutableRefObject<PixiSprite | null>;
  x: number;
};
export const ToolbarContainer = React.memo(
  ({ maskRef, x }: ToolbarContainerProps) => {
    const [topList, bottomList] = useRootSelector(openToolbarListSelector);

    const showMasterToolbar = useRootSelector(showMasterToolbarSelector);
    const showMcaToolbar = useRootSelector(showMcaToolbarSelector);
    const showHorizToolbar = useRootSelector(showHorizToolbarSelector);
    const showLeftToolbar = useRootSelector(showLeftToolbarSelector);
    const showRightToolbar = useRootSelector(showRightToolbarSelector);

    return (
      <Container
        sortableChildren
        eventMode="static"
        mask={maskRef.current}
        zIndex={layerZIndexMap.toolbar}
        x={x}
      >
        <ToolbarContextProvider>
          {topList.map((toolbarId, index) => {
            switch (toolbarId) {
              case "MASTER_TOOLBAR":
                return showMasterToolbar ? (
                  <MasterToolbar key={`top-${toolbarId}-${index}`} />
                ) : null;
              case "MCA_TOOLBAR":
                return showMcaToolbar ? (
                  <McaToolbar key={`top-${toolbarId}-${index}`} />
                ) : null;
              case "HORIZ_TOOLBAR":
                return showHorizToolbar ? (
                  <HorizToolbar key={`top-${toolbarId}-${index}`} />
                ) : null;
              default:
                return null;
            }
          })}
          {bottomList.map((toolbarId, index) => {
            switch (toolbarId) {
              case "MASTER_TOOLBAR":
                return showMasterToolbar ? (
                  <MasterToolbar key={`bottom-${toolbarId}-${index}`} />
                ) : null;
              case "MCA_TOOLBAR":
                return showMcaToolbar ? (
                  <McaToolbar key={`bottom-${toolbarId}-${index}`} />
                ) : null;
              case "HORIZ_TOOLBAR":
                return showHorizToolbar ? (
                  <HorizToolbar key={`bottom-${toolbarId}-${index}`} />
                ) : null;
              default:
                return null;
            }
          })}
          {showLeftToolbar && <LeftToolbar />}
          {showRightToolbar && <RightToolbar />}
        </ToolbarContextProvider>
      </Container>
    );
  },
);
