import React, { useMemo } from "react";
import { View } from "components/utils/View";
import {
  backgroundOption,
  borderOption,
  brightOption,
  fontOption,
  linesOption,
  toggleOption,
  viewOptionSelector,
} from "~redux/slices/viewOptionSlice";
import { useRootSelector } from "~redux/hooks";
import {
  coordinationDataSelector,
  flightplanSelector,
  trackCoordinationDataSelector,
  trackSelector,
} from "~redux/slices/aircraftSlice";
import type { EramFlightplan, EramHoldAnnotations, TrackId } from "@poscon/shared-types";
import { formatUtcTime, sumBy } from "@poscon/shared-types";
import { useViewOptions, ViewOptionContextProvider } from "contexts/viewOptionContext";
import type { EramFontSize } from "@poscon/shared-frontend";
import {
  colorNameMap,
  computeColor,
  eramFontDimensionMap,
  eramFontNameMap,
  ViewItem,
} from "@poscon/shared-frontend";
import { BitmapText, Container } from "@pixi/react";

const view = "HOLD_LIST";

// @ts-ignore
const holdListFields = [
  {
    name: "AID",
    width: 13,
    textCreator: (fp: EramFlightplan) => fp.callsign,
  },
  {
    name: "ALT",
    width: 7,
    textCreator: (fp: EramFlightplan) => fp.assignedAltitude?.simple?.toString() ?? "",
  },
  {
    name: "EFC",
    width: 5,
    textCreator: (fp: EramFlightplan, holdData: EramHoldAnnotations) => formatUtcTime(holdData.efc),
  },
  {
    name: "DEST",
    width: 5,
    textCreator: (fp: EramFlightplan) => fp.destination,
  },
  {
    name: "RVR",
    width: 6,
    textCreator: () => "",
  },
  {
    name: "CAT",
    width: 6,
    textCreator: (fp: EramFlightplan) => "",
  },
];

const optionMap = {
  background: backgroundOption(view),
  border: borderOption(view),
  tearoff: toggleOption(view, "tearoff", "TEAROFF", 7),
  lines: linesOption(view, 5, 25),
  font: fontOption(view),
  bright: brightOption(view),
  altLim: toggleOption(view, "altLim", "ALT LIM...", 10),
  sort: toggleOption(view, "sort", "SORT", 4),
  filters: toggleOption(view, "filters", "FILTERS", 7),
  forceHold: toggleOption(view, "forceHold", "FORCE HOLD", 10),
};

type HoldListEntry = {
  trackId: TrackId;
  fix: string;
  efc: string;
};

const HoldListRow = ({ trackId, efc }: Omit<HoldListEntry, "fix">) => {
  const viewOptions = useViewOptions(view);
  const tint = computeColor(colorNameMap.white, viewOptions.bright / 100);
  const fontSize = viewOptions.font as EramFontSize;
  const fontName = eramFontNameMap[fontSize];
  const fontDimension = eramFontDimensionMap[fontName];
  const track = useRootSelector((state) => trackSelector(state, trackId));
  const coordinationData = useRootSelector((state) => trackCoordinationDataSelector(state, trackId));
  const fp = useRootSelector((state) => flightplanSelector(state, track?.fpId ?? null));

  return (
    <Container>
      {fp &&
        coordinationData?.holdAnnotations &&
        holdListFields.map(({ name, width, textCreator }, index) => {
          const x = sumBy(holdListFields.slice(0, index), (f) => f.width) * fontDimension.width;
          return (
            <BitmapText
              key={name}
              text={textCreator(fp, coordinationData.holdAnnotations!)}
              x={x + 2}
              style={{ fontName, tint }}
              eventMode="none"
            />
          );
        })}
    </Container>
  );
  // return fp ? (
  //   <div className={styles.row}>
  //     <div className={styles.aidCol}>{fp.callsign}</div>
  //     <div className={styles.col5}>{fp.assignedAltitude}</div>
  //     <div className={styles.col6}>{formatUtcTime(new Date(efc))}</div>
  //     <div className={styles.col6}>{fp.destination}</div>
  //     <div className={styles.col6} />
  //     <div className={styles.col6} />
  //   </div>
  // ) : null;
};

type HoldListGroupProps = {
  name: string;
  list: HoldListEntry[];
};
const HoldListGroup = ({ name, list }: HoldListGroupProps) => {
  const viewOptions = useViewOptions(view);
  const tint = computeColor(colorNameMap.white, viewOptions.bright / 100);
  const fontSize = viewOptions.font as EramFontSize;
  const fontName = eramFontNameMap[fontSize];
  const fontDimension = eramFontDimensionMap[fontName];

  return (
    <Container>
      <BitmapText text={name} x={fontDimension.width * 5} y={1} style={{ fontName, tint }} eventMode="none" />
      {list.map((e, index) => (
        <Container key={e.trackId} y={(index + 1) * (fontDimension.height + 3)}>
          <HoldListRow {...e} />
        </Container>
      ))}
    </Container>
  );
  // return (
  //   <>
  //     <div className={styles.headerRow}>
  //       <div className={styles.tearOffButton} />
  //       {name}
  //     </div>
  //     {list.map((e) => (
  //       <HoldListRow key={e.trackId} {...e} />
  //     ))}
  //   </>
  // );
};

export const HoldList = () => {
  // all we need is the hold annotations in coordination data
  const coordinationData = useRootSelector(coordinationDataSelector);
  const viewOptions = useRootSelector((state) => viewOptionSelector(state, view));

  const groups = useMemo(() => {
    const holdList: HoldListEntry[] = [];
    Object.entries(coordinationData).forEach(([trackId, d]) => {
      if (d.holdAnnotations) {
        holdList.push({
          trackId,
          fix: d.holdAnnotations.fix ?? "P/P",
          efc: d.holdAnnotations.efc.toString().padStart(4, "0"),
        });
      }
    });
    return Object.entries(
      holdList.reduce<Record<string, HoldListEntry[]>>((prev, cur) => {
        const group = prev[cur.fix] ?? [];
        return {
          ...prev,
          [cur.fix]: [...group, cur],
        };
      }, {})
    );
  }, [coordinationData]);

  const tint = computeColor(colorNameMap.white, viewOptions.bright / 100);
  const fontSize = viewOptions.font as EramFontSize;
  const fontName = eramFontNameMap[fontSize];
  const fontDimension = eramFontDimensionMap[fontName];

  const itemHeightList = groups.map(([name, list]) => {
    return (list.length + 1) * (fontDimension.height + 3);
  });

  const height = itemHeightList.reduce((a, b) => a + b, 0) + (fontDimension.height + 3) + 6;

  let y = 0;

  return (
    <ViewOptionContextProvider options={viewOptions}>
      <View width={40} height={height} view={view} optionMap={optionMap}>
        <Container x={1} y={1}>
          {holdListFields.map(({ name, width }, index) => {
            const x = sumBy(holdListFields.slice(0, index), (f) => f.width) * fontDimension.width;
            return (
              <ViewItem
                key={name}
                text={name}
                x={x}
                height={fontDimension.height + 1}
                fontSize={fontSize}
                tint={tint}
              />
            );
          })}
          <Container y={fontDimension.height + 4} eventMode="static">
            {groups.map(([name, list], index) => {
              const _y = y;
              const height = itemHeightList[index] ?? fontDimension.height + 3;
              y += height;
              return (
                <Container key={name} y={_y}>
                  <HoldListGroup key={name} name={name} list={list} />
                </Container>
              );
            })}
          </Container>
        </Container>
      </View>
    </ViewOptionContextProvider>
  );
};
