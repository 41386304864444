import { CommonContextProvider } from "@poscon/shared-frontend";
import React from "react";
import { useRootDispatch } from "~/redux/hooks";
import { setViewPosition } from "~/redux/slices/eramStateSlice";
import type { EramView } from "~/types/eramView";
import { processEramMessage } from "~redux/thunks/processEramMessage";

export const CCommonContextProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useRootDispatch();

  return (
    <CommonContextProvider
      setViewPosition={(view, pos) => dispatch(setViewPosition({ view: view as EramView, pos }))}
      dispatchCommand={(command) => dispatch(processEramMessage(command, true))}
    >
      {children}
    </CommonContextProvider>
  );
};
