import React, { createContext, useState } from "react";
import type { DrawItemType } from "~redux/slices/eramStateSlice";
import {
  _drawItemTypes,
  addDrawItem,
  drawCircleRadiusSelector,
  drawColorBrightSelector,
  drawColorSelector,
} from "~redux/slices/eramStateSlice";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { situationDisplayStore } from "~/situationDisplayStore";
import { includes, type ColorName, type Coordinate } from "@poscon/shared-types";
import type { EramFontSize } from "@poscon/shared-frontend";
import { useUiIsLocked } from "@poscon/shared-frontend";
import { usePixiMouseEventListener } from "@poscon/shared-frontend";
import { useSituationDisplay } from "contexts/sdContext";
import { useAnyDragging } from "contexts/dragContext";

export type DrawMode = "CLOSED" | "OPEN" | "EDIT" | "DELETE" | "DELETE_ALL" | DrawItemType;

function isDrawItemType(str: string): str is DrawItemType {
  return includes(_drawItemTypes, str);
}

type DrawContextValue = {
  anchor: "DISP" | "MAP";
  drawColor: ColorName;
  drawColorBright: number;
  drawFontSize: EramFontSize;
  drawMode: DrawMode;
  onItemClick: (drawItemType: DrawItemType) => void;
  setDrawMode: React.Dispatch<React.SetStateAction<DrawMode>>;
  toggleAnchor: () => void;
};

export const DrawContext = createContext<DrawContextValue | null>(null);

export const DrawContextProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useRootDispatch();
  const uiIsLocked = useUiIsLocked();
  const [anchor, setAnchor] = useState<"DISP" | "MAP">("MAP");
  const [_drawMode, setDrawMode] = useState<DrawMode>("CLOSED");
  const drawFontSize = useRootSelector((state) => state.eram.drawFontSize);
  const circleRadius = useRootSelector(drawCircleRadiusSelector);
  const drawColorBright = useRootSelector(drawColorBrightSelector);
  const drawColor = useRootSelector(drawColorSelector);
  const { sdRef } = useSituationDisplay();
  const { setAnyDragging } = useAnyDragging();

  const drawMode = uiIsLocked ? "CLOSED" : _drawMode;

  const onItemClick = (drawItemType: DrawItemType) => {
    setDrawMode((prev) => (prev === drawItemType ? "OPEN" : drawItemType));
  };

  usePixiMouseEventListener((event) => {
    if (isDrawItemType(drawMode)) {
      const coord = [event.clientX, event.clientY] satisfies Coordinate;
      const position = anchor === "MAP" ? situationDisplayStore.getLonLatFromSdCoord(coord) : coord;
      const id = window.isSecureContext
        ? crypto.randomUUID().replace(/-/g, "")
        : new Date().toJSON().replaceAll(/[-:.]/g, "");
      switch (drawMode) {
        case "CIRCLE":
          dispatch(
            addDrawItem({
              id,
              anchor,
              origin: position,
              element: {
                itemType: "CIRCLE",
                radius: anchor === "MAP" ? circleRadius : 5,
                color: drawColor,
                bright: drawColorBright,
              },
            }),
          );
          break;
        case "LINE":
          dispatch(
            addDrawItem({
              id,
              anchor,
              origin: position,
              element: {
                itemType: "LINE",
                coordinates: [position],
                color: drawColor,
                bright: drawColorBright,
                editMode: "ADD_POINT",
              },
            }),
          );
          break;
        case "RECT":
          dispatch(
            addDrawItem({
              id,
              anchor,
              origin: position,
              element: {
                itemType: "RECT",
                bottomRight: null,
                color: drawColor,
                bright: drawColorBright,
                editMode: "MOVE_CORNER",
              },
            }),
          );
          break;
        case "TEXT":
          dispatch(
            addDrawItem({
              id,
              anchor,
              origin: position,
              element: {
                itemType: "TEXT",
                color: drawColor,
                bright: drawColorBright,
                editMode: "EDIT_TEXT",
                text: "",
                fontSize: drawFontSize,
              },
            }),
          );
      }
      setDrawMode("OPEN");
      setAnyDragging(false);
    }
  }, sdRef);

  const contextValue: DrawContextValue = {
    anchor,
    drawMode,
    drawColor,
    drawColorBright,
    drawFontSize,
    onItemClick,
    setDrawMode,
    toggleAnchor: () => setAnchor(anchor === "DISP" ? "MAP" : "DISP"),
  };

  return <DrawContext.Provider value={contextValue}>{children}</DrawContext.Provider>;
};

export const useDrawContext = () => {
  const contextValue = React.useContext(DrawContext);
  if (contextValue === null) {
    throw new Error("useDrawContext must be used within a DrawContextProvider");
  }
  return contextValue;
};
