import type {
  DefaultTextProperties,
  SaaId,
  SaaDisplaySetting,
  SaaActivation,
  SaaSchedule,
  Day,
  GeomapId,
  GeomapTextFeature,
  GeomapLineProperties,
} from "@poscon/shared-types";
import { getTimeUntilSaaScheduleActivation } from "@poscon/shared-types";
import { useRootSelector } from "~redux/hooks";
import React, { useMemo } from "react";
import * as turf from "@turf/turf";
import { featureCollection } from "@turf/turf";
import {
  GeomapGeometryContainer,
  GeomapTextContainer,
  geomapTextFontName,
} from "components/map/GeoMap";
import {
  colorNameMap,
  eramFontDimensionMap,
  saaMapSelector,
  sectorSaaDisplayMapSelector,
  useSAAMaps,
} from "@poscon/shared-frontend";
import { FeatureCollection, Geometry } from "geojson";

// 1 hour
const scheduledSaaPendingThreshold = 3600e3;

const fontDim = eramFontDimensionMap[geomapTextFontName];

type SaaGroup = {
  mapGroupId?: number;
  saaId: string;
  altitudeDisplaySetting: boolean;
  lineFeatures?: FeatureCollection<Geometry, GeomapLineProperties>;
  labelFeature: GeomapTextFeature;
  defaultTextProperties?: DefaultTextProperties;
};

type SaaContainerProps = {
  group: SaaGroup;
  displaySetting: SaaDisplaySetting;
  state: "COLD" | "PENDING" | "HOT";
  alpha: number;
  activations?: SaaActivation[];
};
const SaaContainer = ({ group, alpha, displaySetting, activations, state }: SaaContainerProps) => {
  const defaultTextProperties = group.defaultTextProperties;
  const tint =
    state === "HOT" ? colorNameMap.orange : state === "PENDING" ? colorNameMap.yellow : colorNameMap.white;

  const labelData = displaySetting.includes("LABEL") ? [group.labelFeature] : [];
  if (displaySetting.includes("LABEL") && state === "PENDING" && defaultTextProperties) {
    labelData.push(turf.point(group.labelFeature.geometry.coordinates, {
      textObjectId: `SAA_${group.saaId}_PENDING`,
      text: ["PEND"],
      yOffset: defaultTextProperties.yOffset + fontDim.height,
    }));
  }

  let altYOffset = (group.labelFeature.properties.text.length + (state === "PENDING" ? 1 : 0)) * fontDim.height;
  // TODO: add schedules

  return (
    <>
      {displaySetting.includes("BOUNDARY") && group.lineFeatures && (
        <GeomapGeometryContainer featureCollection={group.lineFeatures} alpha={alpha} tint={tint} />
      )}
      {defaultTextProperties && (
        <>
          {displaySetting.includes("LABEL") && (
            <GeomapTextContainer
              features={labelData}
              defaultTextProperties={defaultTextProperties}
              alpha={alpha}
              baseTint={tint}
            />
          )}
          {displaySetting.includes("ALT") &&
            activations?.map((activation) => {
              const floor = (activation.floor / 100).toFixed(0).padStart(3, "0");
              const ceiling = (activation.ceiling / 100).toFixed(0).padStart(3, "0");
              const textLines = [`${floor}B${ceiling}`];
              if (activation.type === "PENDING") {
                textLines.push("PEND");
              }
              const y = altYOffset;
              altYOffset += fontDim.height * textLines.length;
              return (
                <GeomapTextContainer
                  key={activation.id}
                  features={[turf.point(group.labelFeature.geometry.coordinates, {
                    textObjectId: `SAA_${group.saaId}_ACTIVATION_${activation.id}`,
                    text: [`${floor}B${ceiling}`],
                    yOffset: defaultTextProperties.yOffset + y,
                  })]}
                  defaultTextProperties={defaultTextProperties}
                  alpha={alpha}
                  baseTint={activation.type === "PENDING" ? colorNameMap.yellow : colorNameMap.orange}
                />
              );
            })}
        </>
      )}
    </>
  );
};

// TODO: get alpha from SAA FILTER MENU

type SaaMapProps = {
  geomapId: GeomapId;
};
export const SaaMap = ({ geomapId }: SaaMapProps) => {
  const saaMap = useRootSelector(saaMapSelector);
  const saaDisplayMap = useRootSelector(sectorSaaDisplayMapSelector);
  const saaGeomaps = useSAAMaps(geomapId);

  const mapGroups = useMemo(() => {
    const newGroups: Record<SaaId, SaaGroup> = {};
    for (const saa of saaGeomaps) {
      const { defaultLineProperties, defaultTextProperties } = saa.properties;
      const lineStyle = defaultLineProperties?.style;
      for (const saaFeature of saa.features) {
        const { saaLabel, saaId } = saaFeature.properties;
        if (lineStyle && saaLabel?.latitude && saaLabel?.longitude) {
          const labelFeature = turf.point([saaLabel.longitude, saaLabel.latitude], {
            textObjectId: `SAA_${saaFeature.properties.saaId}_LABEL`,
            text: [saaLabel.label],
          });
          newGroups[saaId] = {
            mapGroupId: saa.properties.mapGroupId,
            saaId,
            labelFeature,
            altitudeDisplaySetting: saaFeature.properties.saaAltitude.displaySetting,
            defaultTextProperties,
            lineFeatures: featureCollection([saaFeature]),
          };
        }
      }
    }
    return newGroups;
  }, [saaGeomaps]);

  return (
    <>
      {Object.entries(mapGroups).map(([saaId, group]) => {
        const saa = saaMap[saaId];
        const schedules = saa?.schedules.reduce<(SaaSchedule & { day: Day; timeUntilActivation: number })[]>(
          (acc, schedule) => {
            for (const _daySchedule of schedule.daySchedules) {
              const daySchedule = {
                day: schedule.day,
                ..._daySchedule,
              };
              const timeUntilActivation = getTimeUntilSaaScheduleActivation(daySchedule, Date.now());
              // less that 48 hours
              if (timeUntilActivation < 172800) {
                acc.push({ ...daySchedule, timeUntilActivation });
              }
            }
            return acc;
          },
          [],
        );
        const pending =
          saa?.activations?.some((a) => a.type === "PENDING") ||
          schedules?.some((s) => s.timeUntilActivation <= scheduledSaaPendingThreshold);

        const showHotDisplaySetting = !!(saa && !saa.forcedOff && (saa.activations?.length || pending));
        const displaySetting = saaDisplayMap[saaId]?.[showHotDisplaySetting ? "hot" : "cold"];
        const showSaa = (showHotDisplaySetting && saa?.filterView?.SaaForceActiveBoundary) ||
          (displaySetting && displaySetting !== "OFF");
        return showSaa ? (
          <SaaContainer
            key={saaId}
            group={group}
            state={showHotDisplaySetting ? "HOT" : pending ? "PENDING" : "COLD"}
            activations={saa?.activations}
            displaySetting={displaySetting ?? "BOUNDARY"}
            alpha={0.8}
          />
        ) : null;
      })}
    </>
  );
};
