import type { ColorSource, FederatedPointerEvent } from "pixi.js";
import { BitmapText, Container, Graphics } from "pixi.js";
import type { RootStore } from "~redux/store";
import type { EramFontSize } from "@poscon/shared-frontend";
import {
  colorNameMap,
  connectionSelector,
  cpdlcMiniMOView,
  cpdlcPidView,
  cpdlcTocHeldView,
  eramCpdlcFontNameMap,
  eramFontDimensionMap,
  eramFontNameMap,
  eramTextDimensionMap,
  errorTone,
  miniMODialogFilter,
  setSelectedHeldToc,
} from "@poscon/shared-frontend";
import type {
  AssignedAltitude,
  CompassDirection,
  ConflictPair,
  Coordinate,
  Datablock,
  DataBlockType,
  EramCoordination,
  EramLocalCoordination,
  EramTrack,
  FieldEOverrideType,
  FlightplanId,
  LeaderLength,
  Nullable,
  PointoutStatus,
  QuicklookedSectorTrack,
  TargetSymbol,
  TrackId,
  Tuple,
} from "@poscon/shared-types";
import { includes } from "@poscon/shared-types";
import {
  CDA_ELIG_SYMBOL,
  CDA_NOT_ELIG_SYMBOL,
  DOWN_ARROW,
  EramFlightplan,
  EramSectorTrack,
  formatBeaconCode,
  getBlinkPriority,
  getMagVar,
  hasExactly,
  HSF_INDICATOR,
  LOST_MODE_C_TICK_THRESHOLD,
  PID_EMERGENCY,
  PID_OPEN,
  stringToParsedTokenArray,
  stringToTokenArray,
  TOC_ABNORMAL_TRANSFER,
  TOC_COMPLETE_SYMBOL,
  TOC_HELD_ABNORMAL,
  TOC_HELD_MULTIPLE,
  TOC_HELD_SINGLE,
  TOC_TRANSFERRING,
  unsafeEntries,
  UP_ARROW,
  UPLINK_ABNORMAL,
  UPLINK_IN_PROGRESS,
  UPLINK_TIMEOUT,
  VCI_SYMBOL,
} from "@poscon/shared-types";
import type { BrightButtonId } from "types/eramButton";
import { situationDisplayStore } from "~/situationDisplayStore";
import { dispatchMapClickEvent } from "~/customEvents";
import { TBE, TBP } from "~/eramConstants";
import { eramHubConnection } from "~/eramHubConnection";
import { processEramMessage } from "~redux/thunks/processEramMessage";
import type { EramAselMenu } from "types/eramView";
import type { EramState } from "~redux/slices/eramStateSlice";
import {
  altitudeLimitsSelector,
  setViewPosition,
  toggleButtonStateSelector,
  trackCrrGroupSelector,
} from "~redux/slices/eramStateSlice";
import { positionSymbolMap, targetSymbolMap } from "~/mapSymbols";
import * as turf from "@turf/turf";
import { destination } from "@turf/turf";
import {
  cpdlcSessionsSelector,
  flightplanSelector,
  quicklookedTracksSelector,
  sectorTrackSelector,
  stcaPairsSelector,
  suppressedConflictPairsSelector,
  trackCoordinationDataSelector,
} from "~redux/slices/aircraftSlice";
import { startListening } from "~redux/listenerMiddleware";
import type { ListenerEntry } from "types/listenerEntry";
import { drawColorMap } from "~/utils/drawColorMap";
import type { CpdlcDialogId } from "@poscon/eram-cpdlc";
import {
  abnormalCpdlcDialogStates,
  emergencyMsgTypes,
  EramCpdlcSession,
} from "@poscon/eram-cpdlc";
import {
  Line4DisplayOverride,
  mapScaleSelector,
  rangeCenterOverrideSelector,
} from "~redux/slices/eramTempStateSlice";
import {
  aselSelector,
  line4DisplayOverrideSelector,
  setAsel,
} from "~redux/slices/eramTempStateSlice";
import * as Sentry from "@sentry/react";

let store: RootStore;

export const injectStore = (s: RootStore) => {
  store = s;
  const state = store.getState();
  trackManager.updateBrightness(state.eram.brightness);
  trackManager.updateFont(state.eram.font);
  trackManager.redrawAllTracks();
};

const listeners: ListenerEntry[] = [
  {
    predicate: (action, state, prevState) =>
      state.eram.brightness !== prevState.eram.brightness,
    effect: (action, { getState }) => {
      trackManager.updateBrightness(getState().eram.brightness);
    },
  },
  {
    predicate: (action, state, prevState) =>
      state.eram.font !== prevState.eram.font,
    effect: (action, { getState }) => {
      trackManager.updateFont(getState().eram.font);
    },
  },
  {
    predicate: (action, state, prevState) =>
      connectionSelector(state) !== connectionSelector(prevState),
    effect: () => {
      trackManager.redrawAllTracks();
    },
  },
  {
    predicate: (action, state, prevState) =>
      state.eram.history !== prevState.eram.history,
    effect: (action, { getState }) => {
      trackManager.historyLength = getState().eram.history;
      trackManager.redrawAllTracks();
    },
  },
  {
    predicate: (action, state, prevState) =>
      state.eram.vectorLength !== prevState.eram.vectorLength,
    effect: (action, { getState }) => {
      trackManager.vectorLength = getState().eram.vectorLength;
      trackManager.redrawNonLdbTracks();
    },
  },
  {
    predicate: (action, state, prevState) => state.tracks !== prevState.tracks,
    effect: (action, { getState }) => {
      const state = getState();
      const newTracks = state.tracks.tracks;
      for (const trackId of trackManager.tracks.keys()) {
        if (!newTracks[trackId]) {
          trackManager.removeTrack(trackId);
        }
      }
      for (const track of Object.values(newTracks)) {
        const sectorTrack = sectorTrackSelector(state, track.id);
        const quicklookedTrack = quicklookedTracksSelector(state, track.id);
        const coordinationData = trackCoordinationDataSelector(state, track.id);
        const fp = flightplanSelector(state, track.fpId);
        trackManager.addOrUpdateTrack(
          track,
          sectorTrack,
          fp,
          coordinationData,
          quicklookedTrack,
        );
      }
    },
  },
  {
    predicate: (action, state, prevState) =>
      mapScaleSelector(state) !== mapScaleSelector(prevState) ||
      rangeCenterOverrideSelector(state) !==
      rangeCenterOverrideSelector(prevState) ||
      toggleButtonStateSelector(state) !==
      toggleButtonStateSelector(prevState) ||
      altitudeLimitsSelector(state) !== altitudeLimitsSelector(prevState),
    effect: () => {
      trackManager.redrawAllTracks();
    },
  },
  {
    predicate: (action, state, prevState) =>
      aselSelector(state) !== aselSelector(prevState) ||
      state.eram.crrGroups !== prevState.eram.crrGroups,
    effect: () => {
      trackManager.redrawNonLdbTracks();
    },
  },
  {
    predicate: (action, state, prevState) =>
      stcaPairsSelector(state) !== stcaPairsSelector(prevState) ||
      suppressedConflictPairsSelector(state) !==
      suppressedConflictPairsSelector(prevState),
    effect: (action, { getState }) => {
      const state = getState();
      const stcaPairs = stcaPairsSelector(state);
      const suppressedConflictPairs = suppressedConflictPairsSelector(state);
      trackManager.updateStcaTracks(stcaPairs, suppressedConflictPairs);
    },
  },
  {
    predicate: (action, state, prevState) =>
      cpdlcSessionsSelector(state) !== cpdlcSessionsSelector(prevState),
    effect: (action, { getState }) => {
      const state = getState();
      const cpdlcSessions = cpdlcSessionsSelector(state);
      trackManager.updateCpdlcSessions(cpdlcSessions);
    },
  },
  {
    predicate: (action, state, prevState) =>
      line4DisplayOverrideSelector(state) !==
      line4DisplayOverrideSelector(prevState),
    effect: (action, { getState }) => {
      const state = getState();
      trackManager.line4DisplayOverride = line4DisplayOverrideSelector(state);
      trackManager.redrawNonLdbTracks();
    },
  },
];

listeners.forEach((listener) => startListening(listener));

export const rotationMap = {
  SW: 135,
  S: 90,
  SE: 45,
  W: 180,
  E: 0,
  NW: -135,
  N: -90,
  NE: -45,
} as const;

const rdbDirectionMap = {
  W: "N",
  NW: "N",
  N: "SE",
  NE: "SW",
  E: "SW",
  SE: "NE",
  S: "SW",
  SW: "NW",
} as const;

const baseTint = colorNameMap.yellow;
const pairedTargetSymbols: TargetSymbol[] = [
  "correlatedBeacon",
  "correlatedPrimary",
  "reducedSeparation",
];

function computeTint(
  baseTint: Uint8Array,
  alpha: number,
  backgroundBright: number,
) {
  return baseTint.map(
    (c, i) =>
      Math.round(c * (alpha / 100)) +
      (1 - alpha / 100) * colorNameMap.bgBlue[i]! * (backgroundBright / 100),
  );
}

function getLeaderlineAnchorPoint(
  pos: CompassDirection,
  length: LeaderLength,
): Coordinate {
  const angle = rotationMap[pos];

  const x = 70 * length * Math.cos((angle * Math.PI) / 180);
  const y = 70 * length * Math.sin((angle * Math.PI) / 180);
  return [x, y];
}

function getBaseDbOffset(
  pos: CompassDirection,
  fontSize: EramFontSize,
  xOffset = 0,
): Coordinate {
  const { width, height } = eramTextDimensionMap[fontSize];
  const x = (["W", "NW", "SW"].includes(pos) ? -(width * 7 + 3) : 4) + xOffset;
  let y = -height * 2.2 - 2;
  if (["S", "SE", "SW"].includes(pos)) {
    y += height * 1.4;
  }
  return [x, y];
}

type CpdlcDbFieldStatus = "ABNORMAL_CLOSURE" | "TIMEOUT" | "UPLINK_IN_PROGRESS";

type CpdlcDbFields = {
  genericUplink: Nullable<CpdlcDbFieldStatus>;
  altUplink: Nullable<CpdlcDbFieldStatus>;
  speedUplink: Nullable<CpdlcDbFieldStatus>;
  headingUplink: Nullable<CpdlcDbFieldStatus>;
  routeUplink: Nullable<CpdlcDbFieldStatus>;
  holdUplink: Nullable<CpdlcDbFieldStatus>;
};

const cpdlcFieldColorOverrideList: (CpdlcDbFieldStatus | null)[] = [
  "ABNORMAL_CLOSURE",
  "TIMEOUT",
];

const blinkFieldETypes: (FieldEOverrideType | null)[] = [
  "handoffPending",
  "coast",
];

const conflictBrightAlpha = 100;
const conflictDimAlpha = 25;

function createFdbNodes() {
  const container = new Container();
  const textContainer = new Container();
  const fenceAndLdr = new Graphics();
  const quickVector = new Graphics();
  const fieldA = new BitmapText("", { fontName: "eramText1" });
  const fieldB = new BitmapText("", { fontName: "eramText1" });
  const fieldB4 = new BitmapText("", { fontName: "eramText1" });
  const fieldC = new BitmapText("", { fontName: "eramText1" });
  const fieldD = new BitmapText("", { fontName: "eramText1" });
  const fieldE = new BitmapText("", { fontName: "eramText1" });
  const fieldF = new BitmapText("", { fontName: "eramText1" });
  const fieldF1 = new BitmapText("", { fontName: "eramText1" });
  const fieldF2 = new BitmapText("", { fontName: "eramText1" });
  const hsfIndicator = new BitmapText(HSF_INDICATOR, { fontName: "eramText1" });
  const vri = new BitmapText("", { fontName: "eramText1" });
  // voice indicator
  const vci = new BitmapText("", { fontName: "eramCpdlc1" });
  // comms
  const tci = new BitmapText("", { fontName: "eramText1" });
  const satCommIndicator = new BitmapText("*", { fontName: "eramText1" });
  // cpdlc indicators
  const fieldAPrefix = new BitmapText("", { fontName: "eramCpdlc1" });
  const fieldAPid = new BitmapText("", { fontName: "eramCpdlc1" });
  const fieldASuffix = new BitmapText("", { fontName: "eramCpdlc1" });
  const pointout = new BitmapText("P", { fontName: "eramText1" });

  pointout.name = "POINTOUT";
  pointout.eventMode = "static";
  fieldAPid.name = "FIELD_A_PID";
  fieldAPid.eventMode = "static";
  fieldAPrefix.name = "FIELD_A_PREFIX";
  fieldAPrefix.eventMode = "static";
  fieldASuffix.name = "FIELD_A_SUFFIX";
  fieldASuffix.eventMode = "static";
  fieldA.name = "FIELD_A";
  fieldA.eventMode = "static";
  fieldB.name = "FIELD_B";
  fieldB.eventMode = "static";
  fieldB4.name = "FIELD_B4";
  fieldB4.eventMode = "static";
  fieldC.name = "FIELD_C";
  fieldC.eventMode = "static";
  fieldD.name = "FIELD_D";
  fieldD.eventMode = "static";
  fieldE.name = "FIELD_E";
  fieldE.eventMode = "static";
  fieldF.name = "FIELD_F";
  fieldF.eventMode = "static";
  fieldF1.name = "FIELD_F1";
  fieldF1.eventMode = "static";
  fieldF2.name = "FIELD_F2";
  fieldF2.eventMode = "static";
  vri.name = "VRI";
  vci.name = "VCI";
  vci.eventMode = "static";
  tci.name = "TCI";
  tci.eventMode = "static";
  hsfIndicator.name = "HSF_INDICATOR";
  hsfIndicator.eventMode = "static";
  container.eventMode = "static";
  container.addChild(textContainer);
  textContainer.addChild(
    fieldA,
    fieldB,
    fieldB4,
    fieldC,
    fieldD,
    fieldE,
    fieldF,
    fieldF1,
    fieldF2,
    hsfIndicator,
  );
  container.addChild(vri, vci, tci, pointout, fenceAndLdr);

  container.sortableChildren = true;
  fenceAndLdr.zIndex = -1;
  const fieldEOverrideValue = null as string | null;

  return {
    container,
    textContainer,
    fenceAndLdr,
    quickVector,
    pointout,
    fieldAPid,
    fieldAPrefix,
    fieldASuffix,
    fieldA,
    fieldB,
    fieldB4,
    fieldC,
    fieldD,
    fieldE,
    fieldF,
    fieldF1,
    fieldF2,
    hsfIndicator,
    satCommIndicator,
    vri,
    vci,
    tci,
    fieldEOverrideValue,
    hoverDwell: false,
  };
}

type Fdb = ReturnType<typeof createFdbNodes>;

function createLdbNodes() {
  const container = new Container();
  const textContainer = new Container();
  const graphics = new Graphics();
  const fieldA = new BitmapText("", { fontName: "eramText1" });
  const fieldB = new BitmapText("", { fontName: "eramText1" });
  const fieldB4 = new BitmapText("", { fontName: "eramText1" });
  const fieldC = new BitmapText("", { fontName: "eramText1" });
  const vri = new BitmapText("", { fontName: "eramText1" });

  container.addChild(textContainer);
  textContainer.addChild(fieldA, fieldB, fieldB4, fieldC);

  fieldA.name = "FIELD_A";
  fieldA.eventMode = "static";
  fieldB.name = "FIELD_B";
  fieldB.eventMode = "static";
  fieldB4.name = "FIELD_B4";
  fieldB4.eventMode = "static";
  fieldC.name = "FIELD_C";
  fieldC.eventMode = "static";
  vri.name = "VRI";

  return {
    container,
    graphics,
    fieldA,
    fieldB,
    fieldB4,
    fieldC,
    vri,
  };
}

type Ldb = ReturnType<typeof createLdbNodes>;

function createRdbNodes() {
  const container = new Container();
  const crr = new BitmapText("", { fontName: "eramText1" });

  container.addChild(crr);

  container.name = "RDB";
  crr.name = "CRR";

  return {
    container,
    crr,
  };
}

type Rdb = ReturnType<typeof createRdbNodes>;

function destroyDb(db: Record<string, any>) {
  Object.values(db).forEach((node) => {
    if (
      node && typeof node === "object" && "destroy" in node && !node.destroyed
    ) {
      node.destroy(true);
    }
  });
}

type QuicklookItem = {
  sectorTrack: QuicklookedSectorTrack;
  fdb: Fdb;
};

class TrackNode {
  ldbHoverDwell = false;

  stcaPairs: ConflictPair[] = [];

  get isStca() {
    const pairsAreCoasting = this.stcaPairs.every((pair) => {
      const otherTrackNode = trackManager.tracks.get(
        pair[0] === this.track.id ? pair[1] : pair[0],
      );
      return otherTrackNode?.track.coasting;
    });
    return (
      this.stcaPairs.length > 0 &&
      !this.track.coasting &&
      !pairsAreCoasting &&
      (!this.sectorTrack.dbType.endsWith("LDB") ||
        !!this.quicklookItem ||
        this.stcaPairs.some((pair) => {
          const otherTrackNode = trackManager.tracks.get(
            pair[0] === this.track.id ? pair[1] : pair[0],
          );
          return (
            otherTrackNode &&
            (!otherTrackNode.sectorTrack.dbType.endsWith("LDB") ||
              otherTrackNode.quicklookItem) &&
            !otherTrackNode.track.coasting
          );
        }))
    );
  }

  getTint(baseTint: Uint8Array) {
    return this.isStca
      ? computeTint(
        baseTint,
        trackManager.dimCdb ? conflictDimAlpha : conflictBrightAlpha,
        0,
      )
      : baseTint;
  }

  get quicklookDbOverlapping() {
    const quicklookedSectorTrack = this.quicklookItem?.sectorTrack;
    if (!quicklookedSectorTrack || this.dbType !== "FDB") {
      return false;
    }
    const dbPosition = this.sectorTrack.dbPosition;
    const qDbPosition = quicklookedSectorTrack.quicklookDbPositionOverride ??
      quicklookedSectorTrack.dbPosition;
    if (
      this.sectorTrack.ldrLength === 0 || quicklookedSectorTrack.ldrLength === 0
    ) {
      const qLdrLength = quicklookedSectorTrack.quicklookLdrLengthOverride ??
        quicklookedSectorTrack.ldrLength;
      const ldrLength = this.sectorTrack.ldrLength;
      return (
        !(ldrLength + qLdrLength > 0 &&
          (/^[NS]$/.test(dbPosition) || /^[NS]$/.test(qDbPosition))) &&
        dbPosition.at(-1) === qDbPosition.at(-1)
      );
    }
    return dbPosition === qDbPosition;
  }

  conflictDim = false;

  rootContainer = new Container();

  mainContainer = new Container();

  _fdb: Fdb | null = null;

  get fdb() {
    if (!this._fdb) {
      this._fdb = createFdbNodes();
      this._fdb.container.onmousedown = (event) => this.onDbMouseDown(event);
      this._fdb.container.onmouseenter = (event) => this.onDbMouseEnter(event);
      this._fdb.container.onmouseleave = (event) => this.onDbMouseLeave(event);
    }
    return this._fdb!;
  }

  _rdb: Rdb | null = null;

  get rdb() {
    if (!this._rdb) {
      this._rdb = createRdbNodes();
    }
    return this._rdb!;
  }

  ldb = createLdbNodes();

  quicklookItem?: QuicklookItem;

  targetSymbol = new BitmapText("", { fontName: "eramTargetSymbols1" });

  positionSymbol = new BitmapText("", { fontName: "eramPositionSymbols1" });

  histories = Array.from(
    { length: 5 },
    () => new BitmapText("", { fontName: "eramTargetSymbols1" }),
  ) as Tuple<
    BitmapText,
    5
  >;

  graphics = new Graphics();

  track: EramTrack;

  get fieldEOverride() {
    const v = {
      type: this.track.fieldEOverrideType,
      value: this.track.fieldEOverrideValue,
    };
    const { fp, track } = this;
    const beaconMismatch = fp?.squawk && track.currentSquawk !== 0 &&
      formatBeaconCode(track.currentSquawk) !== fp.squawk;
    if (
      beaconMismatch &&
      (!this.track.fieldEOverrideType ||
        getBlinkPriority(this.track.fieldEOverrideType) >
        getBlinkPriority("beacon"))
    ) {
      v.type = "beacon";
      v.value = formatBeaconCode(track.currentSquawk);
    }
    return v;
  }

  sectorTrack;

  dbType: DataBlockType = "LDB";

  coordinationData: EramCoordination & EramLocalCoordination = {};

  fp: Nullable<EramFlightplan> = null;

  previousAssignedAlt: Nullable<AssignedAltitude> = null;

  cpdlcSession: EramCpdlcSession | null = null;

  get dwelling() {
    return !!this.fp &&
      (this.sectorTrack.isDwellLocked || this.ldbHoverDwell || this._fdb?.hoverDwell ||
        this.quicklookDbOverlapping);
  }

  get quicklookDwelling() {
    return (
      !!this.quicklookItem &&
      (this.quicklookItem.sectorTrack.isDwellLocked ||
        this.quicklookItem.fdb.hoverDwell)
    );
  }

  get conflictTint() {
    if (!this.isStca) {
      return null;
    }
    return trackManager.dimCdb
      ? trackManager.conflictDimTint
      : trackManager.conflictBrightTint;
  }

  get pureFdbTint() {
    return this.dwelling ? trackManager.fdbDwellTint : trackManager.fdbTint;
  }

  get fdbTint() {
    return this.conflictTint ?? this.pureFdbTint;
  }

  get quicklookFdbTint() {
    return this.conflictTint ??
      (this.quicklookDwelling
        ? trackManager.fdbDwellTint
        : trackManager.fdbTint);
  }

  get line4Tint() {
    return this.conflictTint ??
      (this.dwelling ? trackManager.line4DwellTint : trackManager.line4Tint);
  }

  get ldbTint() {
    return this.conflictTint ??
      (this.dwelling ? trackManager.ldbDwellTint : trackManager.ldbTint);
  }

  onDbMouseDown(event: FederatedPointerEvent, quicklookItem?: QuicklookItem) {
    const fpId = this.track.fpId;
    const sectorTrack = quicklookItem?.sectorTrack ?? this.sectorTrack;
    const fdb = quicklookItem?.fdb ?? this._fdb;
    if (fpId) {
      const target = event.target;
      if ("name" in target && "text" in target) {
        const flid = this.fp?.cid ?? this.fp?.callsign ?? fdb?.fieldA.text;
        event.stopImmediatePropagation();
        if (sectorTrack.dbType.endsWith("LDB")) {
          this.ldbHoverDwell = false;
          switch (target.name) {
            case "FIELD_A":
              if (event.button === TBP && !quicklookItem) {
                eramHubConnection.emit(
                  "setDwellLocked",
                  this.track.id,
                  !this.sectorTrack.isDwellLocked,
                );
              }
              if (event.button === TBE) {
                const sdCoordinate = situationDisplayStore.getSdCoordinates([
                  event.clientX,
                  event.clientY,
                ]);
                const geoCoordinate = situationDisplayStore
                  .getLonLatFromSdCoord(sdCoordinate);
                dispatchMapClickEvent({
                  button: event.button,
                  targetTrackId: this.track.id,
                  targetFpId: fpId,
                  command: stringToTokenArray(`QF ${flid}`),
                  sdCoordinate,
                  geoCoordinate,
                });
              }
              break;
            case "FIELD_B":
            case "FIELD_C":
              eramHubConnection.emit("toggleLdb", this.track.id);
              break;
          }
        } else if (fdb) {
          const field = target.name;
          if (field === "FIELD_A" && event.button === TBP && !quicklookItem) {
            eramHubConnection.emit(
              "setDwellLocked",
              this.track.id,
              !this.sectorTrack.isDwellLocked,
            );
            if (this.sectorTrack.isDwellLocked) {
              fdb.hoverDwell = false;
            }
            return;
          }
          fdb.hoverDwell = false;
          switch (field) {
            case "FIELD_A_PID":
              this.openMenu(fpId, cpdlcPidView, fdb.container);
              break;
            case "FIELD_A_PREFIX":
              if (
                eramHubConnection.sectorId &&
                (this.cpdlcSession?.eligibleSectorId !==
                  eramHubConnection.sectorId ||
                  Object.values(this.cpdlcSession.dialogMap).some((d) => {
                    return d.eligiblePosition?.sectorId ===
                      eramHubConnection.sectorId && miniMODialogFilter(d);
                  }) ||
                  (this.cpdlcSession?.eligibleSectorId ===
                    eramHubConnection.sectorId &&
                    this.cpdlcSession.heldTOCs.length > 0))
              ) {
                this.openMenu(fpId, cpdlcMiniMOView, fdb.container);
              }
              break;
            case "FIELD_A_SUFFIX":
              if (
                eramHubConnection.sectorId &&
                this.fp &&
                this.cpdlcSession &&
                this.cpdlcSession.eligibleSectorId ===
                eramHubConnection.sectorId &&
                this.cpdlcSession.heldTOCs.length > 0
              ) {
                const heldTOCs = this.cpdlcSession.heldTOCs;
                if (hasExactly(heldTOCs, 1) && event.button === TBE) {
                  const toc = heldTOCs[0];
                  if (toc.nextSectorFrequency) {
                    store.dispatch(
                      processEramMessage(
                        stringToParsedTokenArray(
                          `UH ${toc.nextSectorIdShort} ${toc.nextSectorFrequency} ${flid}`,
                        ),
                      ),
                    );
                  } else {
                    errorTone.tryPlay();
                  }
                } else {
                  const { width: fontWidth, height: fontHeight } =
                    eramTextDimensionMap[trackManager.fdbFontSize];
                  const absMenuPos = fdb.container.getGlobalPosition();
                  const pos = {
                    x: absMenuPos.x + 9 * fontWidth,
                    y: absMenuPos.y - 4 * fontHeight,
                  };
                  store.dispatch(
                    setViewPosition({ view: cpdlcTocHeldView, pos }),
                  );
                  store.dispatch(setSelectedHeldToc(this.fp.id));
                }
              }
              break;
            case "FIELD_A":
              if (event.button === TBE) {
                const sdCoordinate = situationDisplayStore.getSdCoordinates([
                  event.clientX,
                  event.clientY,
                ]);
                const geoCoordinate = situationDisplayStore
                  .getLonLatFromSdCoord(sdCoordinate);
                dispatchMapClickEvent({
                  button: event.button,
                  targetTrackId: this.track.id,
                  targetFpId: fpId,
                  command: stringToTokenArray(`QF ${flid}`),
                  sdCoordinate,
                  geoCoordinate,
                });
              }
              break;
            case "FIELD_B":
            case "FIELD_C":
              this.openMenu(fpId, "ALTITUDE_MENU", fdb.container);
              break;
            case "FIELD_D":
              if (event.button === TBP) {
                this.openMenu(fpId, "HEADING_MENU", fdb.container);
              }
              if (event.button === TBE) {
                this.openMenu(fpId, "ROUTE_MENU", fdb.container);
              }
              break;
            case "FIELD_E":
              this.openMenu(fpId, "SPEED_MENU", fdb.container);
              break;
            case "FIELD_F":
              this.openMenu(
                fpId,
                !sectorTrack.line4Suppressed &&
                  this.coordinationData.freeformText
                  ? "FREETEXT_MENU"
                  : "ROUTE_MENU",
                fdb.container,
              );
              break;
            case "FIELD_F1":
              this.openMenu(fpId, "HEADING_MENU", fdb.container);
              break;
            case "FIELD_F2":
              this.openMenu(fpId, "SPEED_MENU", fdb.container);
              break;
            case "HSF_INDICATOR":
              const newValue = !(quicklookItem
                ? (quicklookItem.sectorTrack.quicklookLine4Suppressed ??
                  quicklookItem.sectorTrack.line4Suppressed)
                : this.sectorTrack.line4Suppressed);
              eramHubConnection.emit(
                "setLine4Override",
                this.track.id,
                newValue,
                !!quicklookItem,
              );
              break;
            case "VCI":
              if (this.dbType === "FDB" && !quicklookItem) {
                store.dispatch(
                  processEramMessage(stringToParsedTokenArray(`// ${flid}`)),
                );
              }
              break;
            case "POINTOUT":
              if (sectorTrack.pointouts.length > 0) {
                fdb.hoverDwell = false;
                this.openMenu(fpId, "PO_MENU", fdb.container);
              }
              break;
          }
        }
      }
    }
  }

  onDbMouseEnter(_: FederatedPointerEvent, quicklookItem?: QuicklookItem) {
    this.ldbHoverDwell = true;
    const fdb = quicklookItem?.fdb ?? this._fdb;
    if (fdb) {
      fdb.hoverDwell = true;
    }
    this.draw();
  }

  onDbMouseLeave(_: FederatedPointerEvent, quicklookItem?: QuicklookItem) {
    this.ldbHoverDwell = false;
    const fdb = quicklookItem?.fdb ?? this._fdb;
    if (fdb) {
      fdb.hoverDwell = false;
    }
    this.draw();
  }

  constructor(track: EramTrack, container: Container) {
    this.track = track;
    this.sectorTrack = new EramSectorTrack(track.id);

    this.rootContainer.addChild(this.mainContainer);
    this.histories.forEach((node) => {
      node.eventMode = "none";
      node.zIndex = 0;
    });
    this.mainContainer.zIndex = 1;

    this.targetSymbol.eventMode = "static";
    this.positionSymbol.eventMode = "static";

    this.rootContainer.sortableChildren = true;
    this.mainContainer.sortableChildren = true;

    this.ldb.container.onmousedown = (event) => this.onDbMouseDown(event);
    this.ldb.container.onmouseenter = (event) => this.onDbMouseEnter(event);
    this.ldb.container.onmouseleave = (event) => this.onDbMouseLeave(event);

    const pickListener = (event: FederatedPointerEvent) => {
      event.stopImmediatePropagation();
      const sdCoordinate = situationDisplayStore.getSdCoordinates([
        event.clientX,
        event.clientY,
      ]);
      const geoCoordinate = situationDisplayStore.getLonLatFromSdCoord(
        sdCoordinate,
      );
      dispatchMapClickEvent({
        button: event.button,
        targetTrackId: this.track.id,
        targetFpId: this.track.fpId ?? undefined,
        sdCoordinate,
        geoCoordinate,
      });
    };
    this.targetSymbol.onmousedown = pickListener;
    this.positionSymbol.onmousedown = pickListener;
    this.targetSymbol.eventMode = "static";
    this.positionSymbol.eventMode = "static";

    container.addChild(this.rootContainer);
  }

  openMenu(fpId: FlightplanId, menu: EramAselMenu, container: Container) {
    const { width: fontWidth, height: fontHeight } =
      eramTextDimensionMap[trackManager.fdbFontSize];
    const absMenuPos = container.getGlobalPosition();
    const pos = {
      x: absMenuPos.x + 9 * fontWidth,
      y: absMenuPos.y - 4 * fontHeight,
    };
    store.dispatch(setViewPosition({ view: menu, pos }));
    store.dispatch(setAsel({ fpId, menu }));
  }

  getFieldBAndC(
    track: EramTrack,
    coordinationData: EramCoordination & EramLocalCoordination,
    assignedAlt: Nullable<AssignedAltitude>,
  ) {
    let fieldBText = "";
    let fieldCText = "";
    let b4Value = "";

    const currentAlt = track.modeCAltitude
      ? Math.round(track.modeCAltitude / 100)
      : null; // in 100s of ft
    const isStandby = track.transponderModes.length === 0 ||
      track.transponderModes.includes("Standby");

    if (coordinationData?.localInterimAltitude) {
      fieldBText = coordinationData.localInterimAltitude.toString().padStart(
        2,
        "0",
      );
      b4Value = "L";
      fieldCText = currentAlt?.toString().padStart(2, "0") ?? "";
    } else if (coordinationData?.interimAltitude) {
      fieldBText = coordinationData.interimAltitude.altitude.toString()
        .padStart(2, "0");
      b4Value = coordinationData.interimAltitude.type;
      fieldCText = currentAlt?.toString().padStart(2, "0") ?? "";
    } else if (assignedAlt) {
      // no interim alt
      if (assignedAlt.vfr || assignedAlt.vfrPlus) {
        fieldBText = "VFR";
        b4Value = "/";
      } else if (assignedAlt.vfrOnTop || assignedAlt.vfrOnTopPlus) {
        fieldBText = "OTP";
        b4Value = "/";
      } else if (assignedAlt.above) {
        fieldBText = "ABV";
        b4Value = "/";
      } else if (assignedAlt.block) {
        if (!currentAlt) {
          b4Value = "N";
          fieldBText = assignedAlt.block.min.toString().padStart(2, "0");
          fieldCText = assignedAlt.block.max.toString().padStart(2, "0");
        } else if (currentAlt < assignedAlt.block.min - 2) {
          b4Value = !track.reachedAssignedAlt ? UP_ARROW : "-";
          fieldBText = assignedAlt.block.max.toString().padStart(2, "0");
          fieldCText = currentAlt.toString().padStart(2, "0");
        } else if (currentAlt > assignedAlt.block.max + 2) {
          b4Value = !track.reachedAssignedAlt ? DOWN_ARROW : "+";
          fieldBText = assignedAlt.block.min.toString().padStart(2, "0");
          fieldCText = currentAlt.toString().padStart(2, "0");
        } else {
          b4Value = "B";
          fieldBText = assignedAlt.block.min.toString().padStart(2, "0");
          fieldCText = assignedAlt.block.max.toString().padStart(2, "0");
        }
      } else if (assignedAlt.simple || assignedAlt.altFixAlt) {
        const _alt = (assignedAlt.simple ?? assignedAlt.altFixAlt?.pre)!;
        fieldBText = _alt.toString().padStart(2, "0");
        const altDelta = _alt - (currentAlt ?? 0);
        fieldCText = _alt.toString().padStart(2, "0");
        if (Math.abs(altDelta) <= 2) {
          b4Value = assignedAlt.altFixAlt ? "F" : "C";
        } else if (!track.reachedAssignedAlt) {
          // up/down arrow in the font
          b4Value = altDelta > 0 ? UP_ARROW : DOWN_ARROW;
        } else {
          b4Value = altDelta > 0 ? "-" : "+";
        }
      }
    } else {
      b4Value = "V";
    }
    if (isStandby) {
      b4Value = "N";
    } else if (
      track.coasting || track.lostModeCTicks >= LOST_MODE_C_TICK_THRESHOLD
    ) {
      b4Value = "X";
      fieldCText = "XXX";
    } else {
      if (
        !/^[ABCF]$/.test(b4Value) && currentAlt && currentAlt < 180 &&
        !track.modeCCorrected
      ) {
        b4Value = "X";
      }
      if (!assignedAlt?.block && !/^[TLP]$/.test(b4Value)) {
        // do not show current alt if aircraft is at assigned alt
        fieldCText = /^[CAF]$/.test(b4Value)
          ? ""
          : (currentAlt ?? "XXX").toString().padStart(2, "0");
      }
    }
    const fieldB4Text = b4Value;
    return { fieldBText, fieldB4Text, fieldCText };
  }

  get ldbData() {
    const toggles = trackManager.toggles;
    const track = this.track;
    const fp = this.fp;
    const coordinationData = this.coordinationData;
    const bcastFlid = toggles.BCAST_FLID;
    const target = track.target;
    const currentAlt = track.modeCAltitude
      ? Math.round(track.modeCAltitude / 100)
      : null; // in 100s of ft

    const isStandby = track.transponderModes.length === 0 ||
      track.transponderModes.includes("Standby");

    let fieldAText = "";
    let fieldBText = "";
    let fieldB4Text = "";
    let fieldCText = "";
    if (fp) {
      fieldAText = fp.callsign;
      const assignedAlt = fp.assignedAltitude;

      // field B text
      if (!isStandby && currentAlt) {
        fieldBText = currentAlt.toString().padStart(2, "0");
      }
      if (this.dbType === "ELDB") {
        const { fieldBText: fieldB, fieldB4Text: b4, fieldCText: fieldC } = this
          .getFieldBAndC(track, coordinationData, assignedAlt);
        fieldBText = fieldB;
        fieldB4Text = b4;
        fieldCText = fieldC;
      }
    } else if (track.transponderModes.includes("ModeC") && currentAlt) {
      if (track.currentSquawk !== 0o1200) {
        // show ADSB callsign or beacon code
        fieldAText = bcastFlid && target?.adsbCallsign
          ? target.adsbCallsign
          : formatBeaconCode(track.currentSquawk);
      }
      fieldBText = currentAlt.toString();
    }
    return {
      fieldAText,
      fieldBText,
      fieldB4Text,
      fieldCText,
    };
  }

  get rdbData() {
    const crrGroup = trackCrrGroupSelector(store.getState(), this.track.id);
    const crrValue = crrGroup
      ? turf.distance(this.track.position, crrGroup.coordinate, {
        units: "nauticalmiles",
      })
      : null;
    return crrValue && crrGroup ? { crrValue, color: crrGroup.color } : null;
  }

  getCpdlcDialogStatus(dialogId?: CpdlcDialogId | null) {
    const session = this.cpdlcSession;
    const dialog = session && dialogId ? session.dialogMap[dialogId] : null;
    if (dialog) {
      const initialMessage = dialog.messages[0]!;
      if (
        abnormalCpdlcDialogStates.includes(dialog.eramState) &&
        !dialog.acknowledgedAt
      ) {
        return "ABNORMAL_CLOSURE";
      }
      if (
        initialMessage.timeout &&
        !(dialog.cancelledAt || initialMessage.status === "CLOSED")
      ) {
        return "TIMEOUT";
      }
      return initialMessage.status === "CLOSED" || dialog.cancelledAt
        ? null
        : "UPLINK_IN_PROGRESS";
    }
    return null;
  }

  get cpdlcFields(): CpdlcDbFields {
    const fieldDialogIdMap = this.sectorTrack.cpdlcFieldDialogIdMap;
    return {
      genericUplink: this.getCpdlcDialogStatus(fieldDialogIdMap.genericUplink),
      altUplink: this.getCpdlcDialogStatus(fieldDialogIdMap.altUplink),
      speedUplink: this.getCpdlcDialogStatus(fieldDialogIdMap.speedUplink),
      headingUplink: this.getCpdlcDialogStatus(fieldDialogIdMap.headingUplink),
      routeUplink: this.getCpdlcDialogStatus(fieldDialogIdMap.routeUplink),
      holdUplink: this.getCpdlcDialogStatus(fieldDialogIdMap.holdUplink),
    };
  }

  getFdbData(fp: EramFlightplan, quicklookItem?: QuicklookItem): Datablock {
    const asel = aselSelector(store.getState());
    const {
      TYPE,
      DEST,
      VRI: showVri,
      CODE: showCode,
      SPEED: showSpeed,
      DELAY_FORMAT: showDelayFormat,
      SAT_COMM,
    } = trackManager.toggles;
    const track = this.track;
    const fdb = quicklookItem?.fdb ?? this.fdb;
    const sectorTrack =
      (quicklookItem?.sectorTrack ??
        this.sectorTrack) as QuicklookedSectorTrack;
    const coordinationData = this.coordinationData;

    const { fpId, target } = track;
    const { dbType, pointouts } = sectorTrack;
    const {
      localInterimAltitude,
      interimAltitude,
      heading,
      speed,
      freeformText,
    } = coordinationData ?? {};
    const rdbData = this.rdbData;

    const { type: fieldEOverrideType, value: fieldEOverrideValue } =
      this.fieldEOverride;

    const timeshare = dbType === "FDB" && fieldEOverrideType !== null;

    if (timeshare) {
      fdb.fieldEOverrideValue = trackManager.timeSharePhase < 4
        ? fieldEOverrideValue
        : null;
    } else {
      fdb.fieldEOverrideValue = null;
    }
    const currentAlt = track.modeCAltitude
      ? Math.round(track.modeCAltitude / 100)
      : null;
    const assignedAlt = fp?.assignedAltitude;

    const sectorTrackOwner = quicklookItem?.sectorTrack?.quicklookSectorId ??
      eramHubConnection.sectorId;
    let pointoutStatus: PointoutStatus = null;
    const transferStatus = track.owner !== sectorTrackOwner ? "UNOWNED" : null;

    if (pointouts.length > 0) {
      if (pointouts.filter((p) => !p.acknowledged).length > 0) {
        pointoutStatus = pointouts.every((p) => p.from === sectorTrackOwner)
          ? "INITIATING_UNACK"
          : "RECEIVING_UNACK";
      } else if (pointouts.every((p) => p.acknowledged)) {
        pointoutStatus = pointouts.some((p) => p.from === sectorTrackOwner)
          ? "INITIATING_ACK"
          : null;
      }
    }

    const drawDbArgs: Datablock = {
      dbPos: sectorTrack.quicklookDbPositionOverride ?? sectorTrack.dbPosition,
      dbType,
      dwell: sectorTrack.isDwellLocked,
      fieldAPid: "",
      fieldAPrefix: "",
      fieldASuffix: "",
      fieldAText: "",
      fieldBText: "",
      fieldB4Text: "",
      fieldCText: "",
      fieldDText: "",
      fieldEText: "",
      fieldFText: "",
      fieldF1Text: "",
      fieldF2Text: "",
      showNonRvsmBox: !EramFlightplan.isRvsmEquipped(fp) &&
        !!assignedAlt?.simple && assignedAlt.simple > 280,
      showSatCommIndicator: EramFlightplan.isSatCommEquipped(fp) && SAT_COMM,
      ldrLength: sectorTrack.quicklookLdrLengthOverride ??
        sectorTrack.ldrLength,
      transferStatus,
      pointoutStatus,
      communicationStatus: sectorTrack.communicationStatus,
      vriText: "",
      highlightFieldA: asel?.fpId === track.fpId &&
        ["ROUTE_MENU", "HOLD_MENU"].includes(asel?.menu ?? ""),
      crrText: rdbData?.crrValue?.toFixed(1) ?? "",
      crrColor: rdbData?.color,
      staText: "",
      dctText: "",
    };

    if (this.cpdlcSession) {
      const pidDialogs = EramCpdlcSession.getSectorPidDialogs(
        this.cpdlcSession,
        eramHubConnection.sectorId,
      );
      if (
        eramHubConnection.sectorId &&
        this.cpdlcSession.eligibleSectorId === eramHubConnection.sectorId &&
        pidDialogs.length > 0
      ) {
        drawDbArgs.fieldAPid = pidDialogs.some((d) =>
          emergencyMsgTypes.includes(d.messages[0]!.elements[0]!.type)
        )
          ? PID_EMERGENCY
          : PID_OPEN;
      }
      switch (this.cpdlcFields.genericUplink) {
        case "ABNORMAL_CLOSURE":
          drawDbArgs.fieldAPrefix += UPLINK_ABNORMAL;
          break;
        case "UPLINK_IN_PROGRESS":
          drawDbArgs.fieldAPrefix += UPLINK_IN_PROGRESS;
          break;
        case "TIMEOUT":
          drawDbArgs.fieldAPrefix += UPLINK_TIMEOUT;
          break;
      }
      const tocDialog = EramCpdlcSession.getOpenTocDialogs(this.cpdlcSession)
        ?.at(-1);
      if (
        eramHubConnection.sectorId &&
        tocDialog &&
        tocDialog.messages[0]?.nextSectorId === eramHubConnection.sectorId
      ) {
        drawDbArgs.fieldAPrefix += TOC_TRANSFERRING;
      }
      if (
        drawDbArgs.fieldAPrefix.length === 0 &&
        drawDbArgs.fieldAPid.length === 0
      ) {
        drawDbArgs.fieldAPrefix =
          eramHubConnection.sectorId &&
            this.cpdlcSession.eligibleSectorId === eramHubConnection.sectorId
            ? CDA_ELIG_SYMBOL
            : CDA_NOT_ELIG_SYMBOL;
      }
    }

    const currentBeaconCode = formatBeaconCode(track.currentSquawk);
    const verticalRate = target?.verticalSpeed
      ? Math.round(target.verticalSpeed / 100)
      : 0;
    const exceptionalVertRate = Math.abs(verticalRate) > 50;

    drawDbArgs.highlightFieldA = asel?.fpId === fpId &&
      ["ROUTE_MENU", "HOLD_MENU"].includes(asel?.menu ?? "");
    drawDbArgs.fieldAText = fp.callsign;

    // determine field B text

    drawDbArgs.fieldBText = localInterimAltitude
      ? localInterimAltitude.toString()
      : interimAltitude
        ? interimAltitude.altitude.toString()
        : (assignedAlt?.simple?.toString() ??
          assignedAlt?.altFixAlt?.pre.toString() ?? "");

    const { fieldBText: fieldB, fieldB4Text: b4, fieldCText: fieldC } = this
      .getFieldBAndC(track, coordinationData, assignedAlt);
    drawDbArgs.fieldBText = fieldB;
    drawDbArgs.fieldB4Text = b4;
    drawDbArgs.fieldCText = fieldC;

    // vertical rate indicator if modeC is received
    if (
      !track.coasting && track.transponderModes.includes("ModeC") &&
      Math.abs(verticalRate) > 0
    ) {
      drawDbArgs.vriText = exceptionalVertRate ? "X" : "";
      if (showVri && !quicklookItem) {
        drawDbArgs.vriText += `${verticalRate < 0 ? "-" : "+"}${Math.abs(verticalRate).toFixed(0)
          }`;
      }
    }

    // field D and E
    const fieldDText = fp.cid ?? "";
    let fieldEText = "";

    const blankFieldE = blinkFieldETypes.includes(fieldEOverrideType) &&
      trackManager.timeSharePhase % 2;

    if (!blankFieldE) {
      if (track.coasting) {
        fieldEText = "CST";
      } else if (fdb.fieldEOverrideValue) {
        fieldEText = fdb.fieldEOverrideValue;
        if (fieldEOverrideType === "handoffAccepted") {
          fieldEText = `O${fieldEText}`;
        } else if (fieldEOverrideType === "handoffPending") {
          fieldEText = `H${fieldEText}`;
        } else if (fieldEOverrideType === "forcedTrackControl") {
          fieldEText = `K${fieldEText}`;
        }
      } else if (target) {
        let defaultFieldEOverride: "CODE" | "SPEED" | "DELAY" | null = null;
        if (showCode) {
          defaultFieldEOverride = "CODE";
        }
        if (showSpeed) {
          defaultFieldEOverride = "SPEED";
        }
        if (showDelayFormat) {
          defaultFieldEOverride = "DELAY";
        }
        switch (defaultFieldEOverride) {
          case "CODE":
            fieldEText = currentBeaconCode;
            break;
          case "SPEED":
            fieldEText = target.groundSpeed
              ? Math.round(target.groundSpeed).toFixed(0)
              : "";
            break;
          case "DELAY":
            fieldEText = "";
            break;
          default:
            fieldEText = target.groundSpeed
              ? Math.round(target.groundSpeed).toFixed(0)
              : "";
        }
        // TODO: add destination field indicator as 4th character of fieldDText
      }
    }

    drawDbArgs.fieldDText = fieldDText;
    drawDbArgs.fieldEText = fieldEText.padStart(4, " ");

    const line4Default = TYPE ? "TYPE" : DEST ? "DEST" : null;
    const hsfOverride = trackManager.line4DisplayOverride === "HSF";
    const line4Suppressed = sectorTrack.quicklookLine4Suppressed ??
      sectorTrack.line4Suppressed;
    // field F
    if (includes(["TYPE", "DEST"], trackManager.line4DisplayOverride)) {
      drawDbArgs.fieldFText = trackManager.line4DisplayOverride === "DEST"
        ? fp.destination
        : `${fp.aircraftType}/${fp.equipmentQualifier}`;
    } else if (
      line4Suppressed === hsfOverride && (freeformText || heading || speed)
    ) {
      if (freeformText) {
        drawDbArgs.fieldFText = freeformText ?? "";
      } else {
        drawDbArgs.fieldFText = "";
        let _heading = heading ?? "";
        if (_heading) {
          if (/^\d{3}$/.test(_heading)) {
            const numHeading = parseInt(_heading, 10);
            if (numHeading > 0 && numHeading <= 360) {
              _heading = `H${heading}`;
            }
          }
        }
        drawDbArgs.fieldF1Text = _heading;
        let _speed = speed ?? "";
        if (/^\d{3}$/.test(_speed)) {
          _speed = `S${_speed}`;
        }
        drawDbArgs.fieldF2Text = _speed;
      }
    } else if (line4Default !== null) {
      drawDbArgs.fieldFText = line4Default === "DEST"
        ? fp.destination
        : `${fp.aircraftType}/${fp.equipmentQualifier}`;
    }

    return drawDbArgs;
  }

  drawLdr(
    graphics: Graphics,
    anchor: Coordinate,
    dbOffset: Coordinate,
    dbPos: CompassDirection,
    fontSize: EramFontSize,
    tint: ColorSource,
    {
      shortenBeforeLine1 = false,
      shortenAfterLine1 = false,
      shortenBeforeLine3 = false,
      shortenAfterLine3 = false,
    } = {},
  ) {
    const { width: fontWidth, height: fontHeight } =
      eramTextDimensionMap[fontSize];
    graphics.lineStyle(2, tint);
    const leaderlineStart = [-dbOffset[0], -dbOffset[1]] satisfies Coordinate;
    if (shortenBeforeLine3) {
      if (dbPos === "E") {
        leaderlineStart[0] -= fontWidth;
      }
      if (dbPos === "N") {
        leaderlineStart[1] += fontHeight - 1;
      }
    }
    if (shortenBeforeLine1 && dbPos === "S") {
      leaderlineStart[1] -= fontHeight + 1;
    }
    if (
      (shortenAfterLine1 && dbPos === "SW") ||
      (shortenAfterLine3 && dbPos === "NW") ||
      (shortenBeforeLine1 && dbPos === "SE") ||
      (shortenBeforeLine3 && dbPos === "NE")
    ) {
      leaderlineStart[0] -= (fontWidth * Math.sqrt(2) + 2) *
        Math.cos((rotationMap[dbPos] * Math.PI) / 180);
      leaderlineStart[1] -= (fontWidth * Math.sqrt(2) + 2) *
        Math.sin((rotationMap[dbPos] * Math.PI) / 180);
    }
    graphics
      .moveTo(leaderlineStart[0], leaderlineStart[1])
      .lineTo(
        8 * Math.cos((rotationMap[dbPos] * Math.PI) / 180) - anchor[0] -
        dbOffset[0],
        8 * Math.sin((rotationMap[dbPos] * Math.PI) / 180) - anchor[1] -
        dbOffset[1],
      );
  }

  getFdbFieldPosition(fdb: Fdb, field: keyof CpdlcDbFields) {
    switch (field) {
      case "altUplink":
        {
          const isBlockAlt = !this.coordinationData.interimAltitude && (!!this.previousAssignedAlt?.block || !!this.fp?.assignedAltitude?.block);
          return {
            position: { x: fdb.fieldB.position.x, y: fdb.fieldB.position.y },
            width: eramTextDimensionMap[trackManager.fdbFontSize].width * (isBlockAlt ? 7 : 3),
            height: fdb.fieldB.height,
          };
        }
      case "routeUplink":
        return {
          position: { x: fdb.fieldD.position.x, y: fdb.fieldD.position.y },
          width: fdb.fieldD.width,
          height: fdb.fieldD.height,
        };
      case "headingUplink":
        return fdb.textContainer.children.includes(fdb.fieldF1)
          ? {
            position: { x: fdb.fieldF1.position.x, y: fdb.fieldF1.position.y },
            width: fdb.fieldF1.width,
            height: fdb.fieldF1.height,
          }
          : null;
      case "speedUplink":
        return fdb.textContainer.children.includes(fdb.fieldF2)
          ? {
            position: { x: fdb.fieldF2.position.x, y: fdb.fieldF2.position.y },
            width: fdb.fieldF2.width,
            height: fdb.fieldF2.height,
          }
          : null;
      default:
        return null;
    }
  }

  drawFdbFenceAndLdr(
    dbData: Datablock,
    graphics: Graphics,
    quicklookItem?: QuicklookItem,
  ) {
    const dwelling = quicklookItem ? this.quicklookDwelling : this.dwelling;
    const fdb = quicklookItem?.fdb ?? this.fdb;
    const { PORTAL_FENCE } = trackManager.toggles;
    graphics.clear();
    const { width: fontWidth, height: fontHeight } =
      eramTextDimensionMap[trackManager.fdbFontSize];
    const showFence = PORTAL_FENCE &&
      (dbData.communicationStatus !== null ||
        dbData.transferStatus !== null ||
        dbData.pointoutStatus !== null ||
        dbData.fieldAPrefix.length > 0);

    const tint = quicklookItem ? this.quicklookFdbTint : this.fdbTint;

    // draw leader line
    if (dbData.ldrLength > 0) {
      const xOffset = dbData.dbPos === "SW"
        ? (7 - fdb.fieldA.text.length) * fontWidth
        : 0;
      const leaderlineAnchorPoint = getLeaderlineAnchorPoint(
        dbData.dbPos,
        dbData.ldrLength,
      );
      const dbOffset = getBaseDbOffset(
        dbData.dbPos,
        trackManager.fdbFontSize,
        xOffset,
      );
      this.drawLdr(
        graphics,
        leaderlineAnchorPoint,
        dbOffset,
        dbData.dbPos,
        trackManager.fdbFontSize,
        tint,
        {
          shortenBeforeLine1: fdb.fieldAPrefix.text.length > 0,
          shortenAfterLine1:
            (dbData.dbPos === "SW" && dbData.showSatCommIndicator) ||
            fdb.fieldASuffix.text.length > 0,
          shortenBeforeLine3: dbData.transferStatus !== null,
          shortenAfterLine3: fdb.textContainer.children.includes(
            fdb.hsfIndicator,
          ),
        },
      );
    }
    if (dbData.showNonRvsmBox) {
      const x = fontWidth * dbData.fieldBText.length + 1;
      const y = fontHeight + 2;
      graphics.lineStyle(1, this.getTint(colorNameMap.coral));
      graphics.drawRect(x, y, fontWidth - 1, fontHeight);
    }
    if (showFence) {
      graphics.lineStyle(1, this.getTint(trackManager.fenceTint));
      graphics
        .moveTo(-1, fontHeight * 3 + 3)
        .lineTo(-1, -1)
        .lineTo(fontWidth * 3, -1);
    }
    // if (dwelling && uhdDatablock) {
    if (dwelling) {
      graphics.lineStyle(1, tint);
      graphics.drawRect(
        -1,
        -1,
        Math.max(
          fontWidth * 7 + 3,
          fdb.textContainer.getLocalBounds().right + 2,
        ),
        fdb.textContainer.getLocalBounds().height + 3,
      );
    }
    if (dbData.highlightFieldA) {
      graphics.lineStyle(0);
      graphics
        .beginFill(tint)
        .drawRect(0, 0, dbData.fieldAText.length * fontWidth, fontHeight)
        .endFill();
    }
    if (!quicklookItem) {
      const cpdlcFields = this.cpdlcFields;
      unsafeEntries(cpdlcFields).forEach(([field, status]) => {
        const item = this.getFdbFieldPosition(fdb, field);
        if (item) {
          const { position, width, height } = item;
          switch (status) {
            case "UPLINK_IN_PROGRESS":
              graphics.lineStyle(
                2,
                this.getTint(
                  computeTint(
                    colorNameMap.green,
                    trackManager.uplinkAlpha * 100,
                    trackManager.backgroundAlpha * 100,
                  ),
                ),
              );
              graphics.moveTo(position.x, position.y + height).lineTo(
                position.x + width,
                position.y + height,
              );
              break;
            case "ABNORMAL_CLOSURE":
              graphics.lineStyle(
                1,
                this.getTint(
                  computeTint(
                    colorNameMap.uplinkTimeout,
                    trackManager.uplinkTimeoutAlpha * 100,
                    trackManager.backgroundAlpha * 100,
                  ),
                ),
              );
              graphics.drawRect(position.x, position.y, width + 1, height);
              break;
            case "TIMEOUT":
              const color = this.getTint(
                computeTint(
                  colorNameMap.white,
                  trackManager.uplinkTimeoutAlpha * 100,
                  trackManager.backgroundAlpha * 100,
                ),
              );
              graphics.lineStyle(1, color);
              graphics
                .moveTo(position.x + Math.round(height / 3), position.y)
                .lineTo(position.x, position.y)
                .lineTo(position.x, position.y + height)
                .lineTo(
                  position.x + Math.round(height / 3),
                  position.y + height,
                )
                .endFill();
              graphics.lineStyle(1, color);
              graphics
                .moveTo(position.x + width - Math.round(height / 3), position.y)
                .lineTo(position.x + width + 1, position.y)
                .lineTo(position.x + width + 1, position.y + height)
                .lineTo(
                  position.x + width - Math.round(height / 3),
                  position.y + height,
                )
                .endFill();
              break;
            default:
              break;
          }
        }
      });
    }
  }

  velocityVector = new Graphics();

  drawHaloAndVelocityVector() {
    const graphics = this.velocityVector;
    if (!this.mainContainer.children.includes(graphics)) {
      this.mainContainer.addChild(graphics);
    }
    const track = this.track;
    const sectorTrack = this.sectorTrack;
    const tint = this.fdbTint;
    graphics.clear();
    if (sectorTrack.haloRadius !== null) {
      graphics.lineStyle(2, tint);
      const radius = situationDisplayStore.nmToPx(sectorTrack.haloRadius);
      switch (sectorTrack.haloRadius) {
        case 5:
          graphics.drawCircle(0, 0, radius);
          break;
        case 3:
          graphics
            .arc(0, 0, radius, (Math.PI * 2) / 36, (Math.PI * 16) / 36)
            .endFill()
            .arc(0, 0, radius, (Math.PI * 20) / 36, (Math.PI * 34) / 36)
            .endFill()
            .arc(0, 0, radius, (Math.PI * 38) / 36, (Math.PI * 52) / 36)
            .endFill()
            .arc(0, 0, radius, (Math.PI * 56) / 36, (Math.PI * 70) / 36)
            .endFill();
          break;
      }
    }
    const vectorLength = Math.max(
      trackManager.vectorLength,
      this.isStca ? 1 : 0,
    );
    if (track.target && vectorLength) {
      graphics.lineStyle(2, tint);
      const vectorLineStart = track.position;
      const vectorLineEnd = destination(
        vectorLineStart,
        (2 ** (vectorLength - 1) * track.target.groundSpeed) / 60,
        track.target.tracking,
        {
          units: "nauticalmiles",
        },
      ).geometry.coordinates as Coordinate;
      const start = situationDisplayStore.getSdCoordFromLonLat(
        vectorLineStart,
      )!;
      const end = situationDisplayStore.getSdCoordFromLonLat(vectorLineEnd)!;
      const x = end[0] - start[0];
      const y = end[1] - start[1];
      const abs = Math.max(
        Math.round(
          Math.sqrt((end[0] - start[0]) ** 2 + (end[1] - start[1]) ** 2),
        ),
        4,
      );
      // draw dashed line if vectorline goes outside SD
      if (!situationDisplayStore.rect.contains(end[0], end[1])) {
        // 15 seconds
        const stepSize = abs / (trackManager.vectorLength * 4);
        for (let r = 0; r < abs - 2 * stepSize; r += 2 * stepSize) {
          graphics
            .moveTo(
              ((5 + r + stepSize) * x) / abs,
              ((5 + r + stepSize) * y) / abs,
            )
            .lineTo(
              ((5 + r + stepSize * 2) * x) / abs,
              ((5 + r + stepSize * 2) * y) / abs,
            );
        }
      } else {
        graphics.moveTo((5 * x) / abs, (5 * y) / abs).lineTo(x, y);
      }
    }
  }

  drawQuickVector() {
    const track = this.track;
    const sectorTrack = this.sectorTrack;
    const graphics = this.fdb.quickVector;
    graphics.clear();
    if (trackManager.vectorLength > 0 && track.target && sectorTrack.qvParams) {
      if (!this.mainContainer.children.includes(graphics)) {
        this.mainContainer.addChild(graphics);
      }
      const heading = typeof sectorTrack.qvParams.heading !== "undefined"
        ? sectorTrack.qvParams.heading + getMagVar(...track.target.position)
        : track.target.tracking;
      graphics.angle = heading;
      const speed = sectorTrack.qvParams.speed ?? track.target.groundSpeed;
      const qvStart = track.target.position;
      const qvEnd = destination(
        qvStart,
        (2 ** (trackManager.vectorLength - 1) * speed) / 60,
        heading,
        {
          units: "nauticalmiles",
        },
      ).geometry.coordinates as Coordinate;
      const start = situationDisplayStore.getSdCoordFromLonLat(qvStart)!;
      const end = situationDisplayStore.getSdCoordFromLonLat(qvEnd)!;
      const length = Math.max(
        Math.round(
          Math.sqrt((end[0] - start[0]) ** 2 + (end[1] - start[1]) ** 2),
        ),
        8,
      );
      graphics.lineStyle(2, this.getTint(colorNameMap.mediumGreen));
      graphics
        .moveTo(-8, -6)
        .lineTo(8, -6)
        .lineTo(8, -(length - 8))
        .lineTo(0, -length)
        .lineTo(-8, -(length - 8))
        .lineTo(-8, -6);
    }
  }

  removeFdb() {
    if (this._fdb) {
      this.mainContainer.removeChild(this._fdb.container, this._fdb.quickVector);
      destroyDb(this._fdb);
      this._fdb = null;
    }
    this.removeRdb();
  }

  removeRdb() {
    if (this._rdb) {
      this.mainContainer.removeChild(this._rdb.container);
      destroyDb(this._rdb);
      this._rdb = null;
    }
  }

  drawLdb() {
    this.rootContainer.zIndex = 1;
    this.addLdb();
    const sectorTrack = this.sectorTrack;
    const { width: fontWidth, height: fontHeight } =
      eramTextDimensionMap[trackManager.ldbFontSize];
    const isWest = ["W", "NW", "SW"].includes(sectorTrack.dbPosition);
    let x: number;
    let y: number;
    if (isWest) {
      x = -fontWidth * 8;
      y = -fontHeight * 0.5;
    } else {
      x = fontWidth;
      y = -fontHeight * 0.5;
    }
    const dbData = this.ldbData;
    const fieldAOffset = isWest
      ? fontWidth * (7 - dbData.fieldAText.length)
      : 0;
    const fieldBOffset = isWest && this.dbType === "LDB"
      ? fontWidth * (7 - dbData.fieldBText.length)
      : 0;

    this.ldb.container.position.set(Math.round(x), Math.round(y));
    this.ldb.fieldA.text = dbData.fieldAText;
    this.ldb.fieldA.position.set(fieldAOffset, 0);
    this.ldb.fieldB.text = dbData.fieldBText;
    this.ldb.fieldB.position.set(fieldBOffset, fontHeight + 2);
    if (this.dbType === "ELDB") {
      this.ldb.fieldB4.text = dbData.fieldB4Text;
      this.ldb.fieldB4.position.set(
        fontWidth * dbData.fieldBText.length,
        fontHeight + 2,
      );
      this.ldb.fieldC.text = dbData.fieldCText;
      this.ldb.fieldC.position.set(
        fontWidth * (dbData.fieldBText.length + 1),
        fontHeight + 2,
      );
    } else {
      this.ldb.fieldB4.text = "";
      this.ldb.fieldC.text = "";
    }
    if (this.dwelling) {
      if (!this.ldb.container.children.includes(this.ldb.graphics)) {
        this.ldb.container.addChild(this.ldb.graphics);
      }
      const left = Math.min(fieldAOffset, fieldBOffset);
      const right = Math.max(
        fieldAOffset + dbData.fieldAText.length * fontWidth,
        fieldBOffset +
        (dbData.fieldBText.length + dbData.fieldB4Text.length +
          dbData.fieldCText.length) * fontWidth,
      );
      this.ldb.graphics.clear();
      this.ldb.graphics.lineStyle(1, trackManager.ldbDwellTint);
      this.ldb.graphics.drawRect(
        -1 + left,
        -1,
        right - left + 3,
        fontHeight * 2 + 4,
      );
    } else {
      this.ldb.container.removeChild(this.ldb.graphics);
    }
  }

  addLdb() {
    this.removeFdb();
    if (!this.mainContainer.children.includes(this.ldb.container)) {
      this.mainContainer.addChild(this.ldb.container);
    }
  }

  drawCdb() {
    const toggles = trackManager.toggles;
    this.rootContainer.zIndex = 2;
    this.addLdb();
    if (!this.track.target) {
      return;
    }
    const { width: fontWidth, height: fontHeight } =
      eramTextDimensionMap[trackManager.ldbFontSize];
    const leaderlineAnchorPoint = getLeaderlineAnchorPoint("NE", 1);
    const dbOffset = getBaseDbOffset("NE", trackManager.ldbFontSize);
    const x = dbOffset[0] + leaderlineAnchorPoint[0];
    const y = dbOffset[1] + leaderlineAnchorPoint[1];
    this.ldb.container.position.set(Math.round(x), Math.round(y));
    // TODO: find out how to fill field A and B in CDB
    this.ldb.fieldA.text = this.track.adsbCallsign ??
      `TFC${formatBeaconCode(this.track.currentSquawk)}`;
    this.ldb.fieldB.text = "000";
    this.ldb.fieldB.position.set(0, fontHeight);
    this.ldb.fieldB4.text = "";
    this.ldb.fieldC.text = Math.round(
      (this.track.modeCAltitude ?? this.track.target.altitudeBaro) / 100,
    )
      .toString()
      .padStart(3, "0");
    this.ldb.fieldC.position.set(
      fontWidth * (this.ldb.fieldB.text.length + 1),
      fontHeight,
    );

    const showVri = toggles.VRI;
    if (showVri && this.track.target.verticalSpeed) {
      const verticalRate = this.track.target.verticalSpeed / 100;
      this.ldb.vri.position.set(
        fontWidth *
        (this.ldb.fieldB.text.length + this.ldb.fieldC.text.length + 2) + 3,
        fontHeight,
      );
      this.ldb.vri.text = `${verticalRate < 0 ? "-" : "+"}${Math.abs(verticalRate).toFixed(0)
        }`;
      if (!this.ldb.container.children.includes(this.ldb.vri)) {
        this.ldb.container.addChild(this.ldb.vri);
      }
    } else {
      this.ldb.container.removeChild(this.ldb.vri);
    }

    this.ldb.graphics.clear();
    this.drawLdr(
      this.ldb.graphics,
      leaderlineAnchorPoint,
      dbOffset,
      "NE",
      trackManager.ldbFontSize,
      this.ldbTint,
    );
    this.drawHaloAndVelocityVector();

    if (!this.ldb.container.children.includes(this.ldb.graphics)) {
      this.ldb.container.addChild(this.ldb.graphics);
    }
    if (this.dwelling) {
      this.ldb.graphics.lineStyle(1, this.ldbTint);
      this.ldb.graphics.drawRect(-1, -1, fontWidth * 7 + 3, fontHeight * 2 + 4);
    }
  }

  drawFdb(quicklookItem?: QuicklookItem) {
    const toggles = trackManager.toggles;
    this.rootContainer.zIndex = 3;
    const fp = this.fp;
    const fdb = quicklookItem?.fdb ?? this.fdb;
    if (quicklookItem && this.quicklookDbOverlapping) {
      this.mainContainer.removeChild(fdb.container);
      return;
    }
    if (fp) {
      this.mainContainer.removeChild(this.ldb.container);
      if (!this.mainContainer.children.includes(fdb.container)) {
        this.mainContainer.addChild(fdb.container);
      }
      const coordinationData = this.coordinationData;
      const dbData = this.getFdbData(fp, quicklookItem);
      const { width: fontWidth, height: fontHeight } =
        eramTextDimensionMap[trackManager.fdbFontSize];
      const cpdlcFontName = eramCpdlcFontNameMap[trackManager.fdbFontSize];
      const { width: cpdlcFontWidth } = eramFontDimensionMap[cpdlcFontName];
      let x: number;
      let y: number;
      let rdbX = 5;
      let rdbY = 3;
      if (dbData.ldrLength > 0) {
        const xOffset = dbData.dbPos === "SW"
          ? (7 - fdb.fieldA.text.length) * fontWidth
          : 0;
        const leaderlineAnchorPoint = getLeaderlineAnchorPoint(
          dbData.dbPos,
          dbData.ldrLength,
        );
        const dbOffset = getBaseDbOffset(
          dbData.dbPos,
          trackManager.fdbFontSize,
          xOffset,
        );
        x = dbOffset[0] + leaderlineAnchorPoint[0];
        y = dbOffset[1] + leaderlineAnchorPoint[1];
      } else if (["W", "NW", "SW"].includes(dbData.dbPos)) {
        x = -fontWidth * 8;
        y = -fontHeight * 2.2;
      } else {
        x = fontWidth * 2;
        y = -fontHeight * 1.5;
      }
      fdb.container.position.set(Math.round(x), Math.round(y));
      const crrText = dbData.crrText;
      if (crrText && dbData.crrColor) {
        this.rdb.crr.text = crrText;
        this.rdb.crr.tint = drawColorMap[dbData.crrColor];
        const rdbFontName = eramFontNameMap[trackManager.rdbFontSize];
        const rdbFontDimension = eramFontDimensionMap[rdbFontName];

        const _dbPos = dbData.ldrLength > 0
          ? dbData.dbPos
          : ["W", "NW", "SW"].includes(dbData.dbPos)
            ? "W"
            : "E";

        const rdbDir = dbData.ldrLength > 0
          ? rdbDirectionMap[dbData.dbPos]
          : _dbPos === "E"
            ? "W"
            : "E";
        switch (rdbDir) {
          // CRR N
          case "N":
            rdbX = -Math.floor(rdbFontDimension.width / 2);
            rdbY = -rdbFontDimension.height - 5;
            break;
          // CRR SE
          case "SE":
            rdbX = 5;
            rdbY = 3;
            break;
          // CRR SW
          case "SW":
            rdbX = -5 - rdbFontDimension.width * crrText.length;
            rdbY = 3;
            break;
          case "NW":
            rdbX = -5 - rdbFontDimension.width * crrText.length;
            rdbY = -rdbFontDimension.height - 5;
            break;
          case "NE":
            rdbX = 5;
            rdbY = -3 - fontHeight;
            break;
        }
        this.rdb.container.position.set(rdbX, rdbY);
        if (!this.mainContainer.children.includes(this.rdb.container)) {
          this.mainContainer.addChild(this.rdb.container);
        }
      } else {
        this.removeRdb();
      }
      const tint = fdb.fieldA.tint;
      fdb.fieldA.text = dbData.fieldAText;
      fdb.fieldA.tint = dbData.highlightFieldA ? 0 : tint;
      fdb.fieldB.text = dbData.fieldBText;
      fdb.fieldB.position.set(0, fontHeight + 2);
      fdb.fieldB4.text = dbData.fieldB4Text;
      fdb.fieldB4.position.set(
        fontWidth * dbData.fieldBText.length,
        fontHeight + 2,
      );
      fdb.fieldC.text = dbData.fieldCText;
      fdb.fieldC.position.set(
        fontWidth * (dbData.fieldBText.length + 1),
        fontHeight + 2,
      );
      fdb.fieldD.text = dbData.fieldDText;
      fdb.fieldD.position.set(0, (fontHeight + 2) * 2);
      fdb.fieldE.text = dbData.fieldEText;
      fdb.fieldE.position.set(fontWidth * 3, (fontHeight + 2) * 2);

      if (dbData.pointoutStatus !== null) {
        if (!fdb.container.children.includes(fdb.pointout)) {
          fdb.container.addChild(fdb.pointout);
        }
        fdb.pointout.position.set(fontWidth * 2, -(fontHeight + 5));
        fdb.pointout.tint = dbData.pointoutStatus.endsWith("UNACK")
          ? tint
          : this.getTint(colorNameMap.lightGrey);
        fdb.pointout.text = dbData.pointoutStatus.endsWith("UNACK") ? "P" : "A";
      } else {
        fdb.container.removeChild(fdb.pointout);
      }
      if (dbData.fieldAPid.length > 0) {
        fdb.fieldAPid.position.set(-(cpdlcFontWidth + 3), 0);
        fdb.fieldAPid.text = dbData.fieldAPid;
        if (!fdb.textContainer.children.includes(fdb.fieldAPid)) {
          fdb.textContainer.addChild(fdb.fieldAPid);
        }
      } else {
        fdb.textContainer.removeChild(fdb.fieldAPid);
      }

      fdb.fieldAPrefix.text = dbData.fieldAPrefix;
      if (dbData.fieldAPrefix.length > 0) {
        fdb.fieldAPrefix.position.set(
          -(cpdlcFontWidth *
            (dbData.fieldAPrefix.length + dbData.fieldAPid.length) + 3),
          0,
        );
        if (!fdb.textContainer.children.includes(fdb.fieldAPrefix)) {
          fdb.textContainer.addChild(fdb.fieldAPrefix);
        }
        const timeoutTint = computeTint(
          colorNameMap.white,
          trackManager.uplinkTimeoutAlpha * 100,
          trackManager.backgroundAlpha * 100,
        );
        if (
          cpdlcFieldColorOverrideList.includes(this.cpdlcFields.routeUplink)
        ) {
          fdb.fieldD.tint = timeoutTint;
        }
        if (cpdlcFieldColorOverrideList.includes(this.cpdlcFields.altUplink)) {
          fdb.fieldB.tint = timeoutTint;
        }
        if (
          cpdlcFieldColorOverrideList.includes(this.cpdlcFields.speedUplink)
        ) {
          fdb.fieldF2.tint = timeoutTint;
        }
      } else {
        fdb.textContainer.removeChild(fdb.fieldAPrefix);
      }
      const openTocDialog = this.cpdlcSession
        ? EramCpdlcSession.getOpenTocDialogs(this.cpdlcSession)?.at(-1)
        : undefined;
      if (
        eramHubConnection.sectorId &&
        this.cpdlcSession?.eligibleSectorId === eramHubConnection.sectorId &&
        (this.cpdlcSession.heldTOCs.length > 0 || openTocDialog)
      ) {
        const tocMsg = openTocDialog?.messages[0];
        fdb.fieldASuffix.position.set(fontWidth * (dbData.fieldAText.length + (dbData.showSatCommIndicator ? 1 : 0)), 0);
        if (this.cpdlcSession.heldTOCs.length > 0) {
          // TOC HELD abnormal, see TI6110.100 section 7.2.2.4
          if (this.cpdlcSession.heldTOCs.some((v) => !v.nextSectorFrequency)) {
            fdb.fieldASuffix.text = TOC_HELD_ABNORMAL;
          } else {
            fdb.fieldASuffix.text = this.cpdlcSession.heldTOCs.length === 1
              ? TOC_HELD_SINGLE
              : TOC_HELD_MULTIPLE;
          }
        } else if (
          openTocDialog &&
          tocMsg &&
          tocMsg.nextSectorId !== eramHubConnection.sectorId &&
          this.cpdlcSession.eligibleSectorId === eramHubConnection.sectorId
        ) {
          fdb.fieldASuffix.text =
            abnormalCpdlcDialogStates.includes(openTocDialog.eramState)
              ? TOC_ABNORMAL_TRANSFER
              : TOC_TRANSFERRING;
        }
        if (!fdb.textContainer.children.includes(fdb.fieldASuffix)) {
          fdb.textContainer.addChild(fdb.fieldASuffix);
        }
      } else {
        fdb.textContainer.removeChild(fdb.fieldASuffix);
      }

      if (dbData.showSatCommIndicator) {
        fdb.satCommIndicator.position.set(
          dbData.fieldAText.length * fontWidth,
          0,
        );
        if (!fdb.textContainer.children.includes(fdb.satCommIndicator)) {
          fdb.textContainer.addChild(fdb.satCommIndicator);
        }
      } else {
        fdb.textContainer.removeChild(fdb.satCommIndicator);
      }

      if (dbData.fieldFText.length > 0) {
        fdb.fieldF.text = dbData.fieldFText;
        fdb.fieldF.position.set(0, (fontHeight + 2) * 3);
        if (!fdb.textContainer.children.includes(fdb.fieldF)) {
          fdb.textContainer.addChild(fdb.fieldF);
        }
      } else {
        fdb.textContainer.removeChild(fdb.fieldF);
      }
      if (dbData.fieldF1Text.length > 0) {
        fdb.fieldF1.text = dbData.fieldF1Text;
        fdb.fieldF1.position.set(0, (fontHeight + 2) * 3);
        if (!fdb.textContainer.children.includes(fdb.fieldF1)) {
          fdb.textContainer.addChild(fdb.fieldF1);
        }
      } else {
        fdb.textContainer.removeChild(fdb.fieldF1);
      }
      if (dbData.fieldF2Text.length > 0) {
        fdb.fieldF2.text = dbData.fieldF2Text;
        fdb.fieldF2.position.set(fontWidth * 4, (fontHeight + 2) * 3);
        if (!fdb.textContainer.children.includes(fdb.fieldF2)) {
          fdb.textContainer.addChild(fdb.fieldF2);
        }
      } else {
        fdb.textContainer.removeChild(fdb.fieldF2);
      }

      fdb.vri.position.set(fontWidth * 7 + 3, fontHeight + 2);
      fdb.vri.text = dbData.vriText;
      fdb.vci.position.set(-(cpdlcFontWidth + 2), fontHeight + 2);
      fdb.vci.text = dbData.communicationStatus === "ON_FREQ"
        ? VCI_SYMBOL
        : dbData.communicationStatus === "TOC"
          ? TOC_COMPLETE_SYMBOL
          : " ";
      fdb.tci.text = dbData.transferStatus === "AUTO_HO_INHIBIT"
        ? "^"
        : dbData.transferStatus === "UNOWNED"
          ? "R"
          : "";
      fdb.tci.position.set(-(fontWidth + 3), (fontHeight + 2) * 2);
      this.drawFdbFenceAndLdr(dbData, fdb.fenceAndLdr, quicklookItem);
      this.drawHaloAndVelocityVector();
      this.drawQuickVector();
      if (
        coordinationData &&
        (coordinationData.speed || coordinationData.heading ||
          coordinationData.freeformText) &&
        (toggles.TYPE || toggles.DEST)
      ) {
        fdb.hsfIndicator.position.set(fontWidth * 7, (fontHeight + 2) * 2);
        if (!fdb.textContainer.children.includes(fdb.hsfIndicator)) {
          fdb.textContainer.addChild(fdb.hsfIndicator);
        }
      } else {
        fdb.textContainer.removeChild(fdb.hsfIndicator);
      }
    }
  }

  renderTargetSymbol(targetSymbol: TargetSymbol) {
    this.targetSymbol.text = targetSymbolMap[targetSymbol];
    if (!this.mainContainer.children.includes(this.targetSymbol)) {
      this.mainContainer.addChild(this.targetSymbol);
    }
  }

  renderPositionSymbol() {
    const track = this.track;
    if (
      this.dbType === "CDB" ||
      ((this.dbType !== "LDB" || this.quicklookItem) &&
        (!track.target || track.coasting || track.frozen ||
          pairedTargetSymbols.includes(track.targetSymbol)))
    ) {
      if (track.frozen) {
        this.positionSymbol.text = positionSymbolMap.frozenDatablock;
      } else if (track.coasting || !track.target) {
        this.positionSymbol.text = positionSymbolMap.coastTrack;
      } else {
        this.positionSymbol.text =
          positionSymbolMap[track.isFlatTrack ? "flatTrack" : "freeTrack"];
      }
      if (!this.mainContainer.children.includes(this.positionSymbol)) {
        this.mainContainer.addChild(this.positionSymbol);
      }
    } else if (this.mainContainer.children.includes(this.positionSymbol)) {
      this.mainContainer.removeChild(this.positionSymbol);
    }
  }

  renderHistory(targetHistories: EramTrack["histories"]) {
    this.histories.forEach((node, i) => {
      const item = targetHistories[i];
      if (i < trackManager.historyLength && item) {
        const [x, y] = situationDisplayStore.getSdCoordFromLonLat(
          item.position,
        )!;
        node.position.set(Math.round(x), Math.round(y));
        node.text = targetSymbolMap[item.targetSymbol];
        if (!this.rootContainer.children.includes(node)) {
          this.rootContainer.addChild(node);
        }
      } else if (this.rootContainer.children.includes(node)) {
        this.rootContainer.removeChild(node);
      }
    });
  }

  update(
    track: EramTrack,
    sectorTrack: EramSectorTrack,
    fp: EramFlightplan | null,
    coordinationData: EramCoordination & EramLocalCoordination,
    quicklookedTrack?: QuicklookedSectorTrack,
  ) {
    this.track = track;
    this.sectorTrack = sectorTrack;
    if (
      fp?.assignedAltitude?.block?.min !== this.fp?.assignedAltitude?.block?.min ||
      fp?.assignedAltitude?.block?.max !== this.fp?.assignedAltitude?.block?.max ||
      fp?.assignedAltitude?.simple !== this.fp?.assignedAltitude?.simple ||
      fp?.assignedAltitude?.altFixAlt !== this.fp?.assignedAltitude?.altFixAlt
    ) {
      this.previousAssignedAlt = this.fp?.assignedAltitude;
    }
    this.fp = fp;
    this.coordinationData = coordinationData;
    let dbType = sectorTrack.dbType;
    if (this.isStca && !quicklookedTrack) {
      dbType = fp ? "FDB" : "CDB";
    } else if (dbType === "FDB" && !fp) {
      dbType = "LDB";
    }
    const oldDbType = this.dbType;
    this.dbType = dbType;
    if (!this.quicklookItem && quicklookedTrack) {
      const fdb = createFdbNodes();
      this.updateFdbFontAndBright(fdb);
      this.quicklookItem = { sectorTrack: quicklookedTrack, fdb };
      fdb.container.onmousedown = (event) =>
        this.onDbMouseDown(event, this.quicklookItem);
      fdb.container.onmouseenter = (event) =>
        this.onDbMouseEnter(event, this.quicklookItem);
      fdb.container.onmouseleave = (event) =>
        this.onDbMouseLeave(event, this.quicklookItem);
    }
    if (this.quicklookItem && quicklookedTrack) {
      this.quicklookItem.sectorTrack = quicklookedTrack;
    }
    if (!quicklookedTrack && this.quicklookItem) {
      this.mainContainer.removeChild(this.quicklookItem.fdb.container);
      destroyDb(this.quicklookItem.fdb);
      delete this.quicklookItem;
    }
    this.draw();
  }

  private _draw() {
    const track = this.track;

    const { toggles, altitudeLimits } = trackManager;

    if (!toggles.GHOST && track.target?.ghosted) {
      trackManager.tracksContainer?.removeChild(this.rootContainer);
      return;
    }

    const [x, y] = situationDisplayStore.getSdCoordFromLonLat(track.position);
    this.mainContainer.position.set(Math.round(x), Math.round(y));
    if (!situationDisplayStore.paddedRect.contains(x, y)) {
      trackManager.tracksContainer?.removeChild(this.rootContainer);
      return;
    }
    const dbType = this.dbType;

    let showTarget = false;
    let showLdb = false;

    // TODO: check if works correctly
    if (dbType.endsWith("LDB") && !this.quicklookItem) {
      const altitude = track.modeCAltitude
        ? Math.round(track.modeCAltitude / 100)
        : null;
      if (!track.targetSymbol.startsWith("uncorrelated") && toggles.ALL_LDBS) {
        showTarget = true;
        showLdb = true;
      } else if (altitude) {
        const targetLimits = (altitudeLimits.targets ?? altitudeLimits.limits)!;
        const { min, max } = targetLimits;
        if (altitude > min && altitude < max) {
          showTarget = true;
        } else {
          showTarget = false;
        }
        const ldbLimits = (altitudeLimits.ldbs ?? altitudeLimits.limits)!;
        if (ldbLimits) {
          const { min, max } = ldbLimits;
          if (altitude > min && altitude < max) {
            showLdb = true;
          }
        }
      }
      if (toggles.NON_MODE_C && track.targetSymbol.endsWith("Primary")) {
        showTarget = true;
      }
      if (track.targetSymbol === "uncorrelatedPrimary" && toggles.ALL_PRIM) {
        showTarget = true;
      }
      // TODO: need beacon code list to function correctly to implement this
      // if (targetSymbol.startsWith("correlated") && (radarFilters.ALL_LDBS || radarFilters.PR_LDB)) {
      //   showLdb = true;
      // }
      // if (targetSymbol.startsWith("uncorrelated") && (radarFilters.ALL_LDBS || radarFilters.UNP_LDB)) {
      //   showLdb = true;
      // }
    }
    const showTargetSymbol = !!track.target && !track.coasting &&
      (showTarget || !dbType.endsWith("LDB") || this.quicklookItem);
    if (
      !this.quicklookItem && dbType.endsWith("LDB") &&
      !(showTargetSymbol && showLdb)
    ) {
      trackManager.tracksContainer?.removeChild(this.rootContainer);
      return;
    }
    if (this.quicklookItem) {
      this.drawFdb(this.quicklookItem);
    }
    if (showTargetSymbol) {
      this.renderTargetSymbol(track.targetSymbol);
      this.renderHistory(track.histories);
    } else {
      this.mainContainer.removeChild(this.targetSymbol);
      this.renderHistory([]);
    }
    this.renderPositionSymbol();
    switch (dbType) {
      case "FDB":
        this.drawFdb();
        break;
      case "CDB":
        this.drawCdb();
        break;
      case "LDB":
      case "ELDB":
        if (showTargetSymbol && showLdb) {
          this.drawLdb();
        }
        break;
    }

    if (dbType !== "FDB" || !this.fp) {
      this.removeFdb();
      if (dbType !== "CDB") {
        this.mainContainer.removeChild(this.velocityVector);
      }
    }

    this.updateFontAndBright();
    if (!trackManager.tracksContainer?.children.includes(this.rootContainer)) {
      trackManager.tracksContainer?.addChild(this.rootContainer);
    }
  }

  draw() {
    try {
      this._draw();
    } catch (e) {
      Sentry.captureException(e);
      const errorMsg = e instanceof Error
        ? e.message + "\n" + e.stack
        : JSON.stringify(e);
      console.error(
        `error drawing track ${this.track.id}, removing...\n${errorMsg}`,
      );
      trackManager.removeTrack(this.track.id);
    }
  }

  private updateFdbFontAndBright(fdb: Fdb, isQuicklook = false) {
    const cpdlcFontName = eramCpdlcFontNameMap[trackManager.fdbFontSize];
    const fontName = eramFontNameMap[trackManager.fdbFontSize];
    const line4FontName = eramFontNameMap[trackManager.line4FontSize];
    const line4Tint = this.conflictTint ??
      ((isQuicklook ? this.quicklookDwelling : this.dwelling)
        ? trackManager.line4DwellTint
        : trackManager.line4Tint);
    const fdbTint = isQuicklook ? this.quicklookFdbTint : this.fdbTint;

    fdb.fieldA.fontName = fontName;
    fdb.fieldB.fontName = fontName;
    fdb.fieldB4.fontName = fontName;
    fdb.fieldC.fontName = fontName;
    fdb.fieldD.fontName = fontName;
    fdb.fieldE.fontName = fontName;
    fdb.fieldF.fontName = line4FontName;
    fdb.fieldF1.fontName = line4FontName;
    fdb.fieldF2.fontName = line4FontName;
    fdb.vri.fontName = fontName;
    fdb.vci.fontName = eramCpdlcFontNameMap[trackManager.fdbFontSize];
    fdb.tci.fontName = fontName;
    fdb.hsfIndicator.fontName = fontName;
    fdb.fieldA.tint = fdbTint;
    fdb.fieldB.tint = fdbTint;
    fdb.fieldB4.tint = fdbTint;
    fdb.fieldC.tint = fdbTint;
    fdb.fieldD.tint = fdbTint;
    fdb.fieldE.tint = fdbTint;
    fdb.fieldF.tint = line4Tint;
    fdb.fieldF1.tint = line4Tint;
    fdb.fieldF2.tint = line4Tint;
    fdb.vri.tint = line4Tint;
    fdb.vci.tint = this.getTint(trackManager.vciTint);
    fdb.tci.tint = fdbTint;
    fdb.hsfIndicator.tint = fdbTint;
    fdb.pointout.fontName = fontName;
    fdb.satCommIndicator.fontName = fontName;
    fdb.satCommIndicator.tint = this.getTint(trackManager.satCommTint);
    fdb.fieldAPid.fontName = cpdlcFontName;
    fdb.fieldAPid.tint = this.getTint(trackManager.pidTint);
    fdb.fieldAPrefix.fontName = cpdlcFontName;
    fdb.fieldAPrefix.tint = this.getTint(trackManager.cdaTint);
    fdb.fieldASuffix.fontName = cpdlcFontName;
    fdb.fieldASuffix.tint = this.getTint(trackManager.tocTint);
  }

  updateFontAndBright() {
    const fdbTint = this.fdbTint;
    this.targetSymbol.tint = this.fp
      ? trackManager.prTgtTint
      : trackManager.unpTgtTint;
    this.positionSymbol.tint = fdbTint;
    this.histories.forEach((node) => {
      node.tint = this.fp ? trackManager.prHstTint : trackManager.unpHstTint;
    });
    const dbType = this.dbType;
    if (
      (["LDB", "ELDB", "CDB"].includes(dbType) ||
        (dbType === "FDB" && !this.fp)) && !this.quicklookItem
    ) {
      const fontName = eramFontNameMap[trackManager.ldbFontSize];
      this.ldb.fieldA.fontName = fontName;
      this.ldb.fieldB.fontName = fontName;
      this.ldb.fieldB4.fontName = fontName;
      this.ldb.fieldC.fontName = fontName;
      this.ldb.vri.fontName = fontName;
      const tint = this.ldbTint;
      this.ldb.fieldA.tint = tint;
      this.ldb.fieldB.tint = tint;
      this.ldb.fieldB4.tint = tint;
      this.ldb.fieldC.tint = tint;
      this.ldb.vri.tint = tint;
      if (dbType.endsWith("LDB")) {
        this.drawLdb();
      }
      if (dbType === "CDB") {
        this.drawCdb();
      }
    } else {
      if (this._rdb) {
        this._rdb.crr.fontName = eramFontNameMap[trackManager.rdbFontSize];
      }
      this.updateFdbFontAndBright(this.fdb);
      if (this.dbType === "FDB" && this.fp) {
        this.drawFdb();
      }
      if (this.quicklookItem) {
        this.updateFdbFontAndBright(this.quicklookItem.fdb, true);
        this.drawFdb(this.quicklookItem);
      }
    }
  }

  destroy() {
    if (this.quicklookItem) {
      destroyDb(this.quicklookItem.fdb);
    }
    this.removeFdb();
    destroyDb(this.ldb);
    destroyDb(this);
  }
}

class TrackManager {
  tracksContainer?: Container;

  tracks = new Map<string, TrackNode>();

  historyLength = 0;

  vectorLength = 0;

  fdbFontSize: EramFontSize = 1;

  line4FontSize: EramFontSize = 1;

  rdbFontSize: EramFontSize = 1;

  ldbFontSize: EramFontSize = 1;

  backgroundAlpha = 0;

  fdbTint = baseTint;

  sldbTint = baseTint;

  ldbTint = baseTint;

  fdbDwellTint = baseTint;

  ldbDwellTint = baseTint;

  sldbDwellTint = baseTint;

  line4Tint = baseTint;

  line4DwellTint = baseTint;

  fenceTint = baseTint;

  vciTint = baseTint;

  portalTint = baseTint;

  portalDwellTint = baseTint;

  prTgtTint = baseTint;

  unpTgtTint = baseTint;

  prHstTint = baseTint;

  unpHstTint = baseTint;

  conflictBrightTint = computeTint(baseTint, conflictBrightAlpha, 0);

  conflictDimTint = computeTint(baseTint, conflictDimAlpha, 0);

  satCommTint = computeTint(colorNameMap.white, 80, 0);

  cdaTint = computeTint(colorNameMap.white, 80, 0);

  tocTint = computeTint(colorNameMap.white, 80, 0);

  pidTint = computeTint(colorNameMap.white, 80, 0);

  uplinkTimeoutAlpha = 1;

  uplinkAlpha = 1;

  rdbAlpha = 1;

  get altitudeLimits() {
    return altitudeLimitsSelector(store.getState());
  }

  get toggles() {
    return toggleButtonStateSelector(store.getState());
  }

  line4DisplayOverride: Line4DisplayOverride | null = null;

  dimCdb = false;

  timeSharePhase = 0;

  constructor() {
    situationDisplayStore.subscribe(() => {
      this.redrawAllTracks();
    });
    setInterval(() => {
      this.dimCdb = !this.dimCdb;
      this.timeSharePhase = (this.timeSharePhase + 1) % 8;
      for (const node of this.tracks.values()) {
        if (
          node.isStca ||
          (node.fieldEOverride.type !== null &&
            (node.dbType === "FDB" || node.quicklookItem))
        ) {
          node.draw();
        }
      }
    }, 700);
  }

  updateBrightness(data: Record<BrightButtonId, number>) {
    const {
      FDB_BRIGHT,
      LDB_BRIGHT,
      SLDB_BRIGHT,
      LINE_4_BRIGHT,
      FENCE_BRIGHT,
      ON_FREQ_BRIGHT,
      DWELL_BRIGHT,
      PORTAL_BRIGHT,
      PR_TGT_BRIGHT,
      UNP_TGT_BRIGHT,
      PR_HST_BRIGHT,
      UNP_HST_BRIGHT,
      BCKGRD_BRIGHT,
      SATCOMM_BRIGHT,
      NDA_CDA_BRIGHT,
      TOC_IC_BRIGHT,
      PID_BRIGHT,
      TIMEOUT_BRIGHT,
      UPLINK_BRIGHT,
    } = data;
    const fdbDwellAlpha = Math.min(FDB_BRIGHT + DWELL_BRIGHT, 100);
    const line4DwellAlpha = Math.max(LINE_4_BRIGHT + fdbDwellAlpha, 0);

    this.backgroundAlpha = BCKGRD_BRIGHT / 100;
    this.fdbTint = computeTint(baseTint, FDB_BRIGHT, BCKGRD_BRIGHT);
    this.ldbTint = computeTint(baseTint, LDB_BRIGHT, BCKGRD_BRIGHT);
    this.sldbTint = computeTint(baseTint, SLDB_BRIGHT, BCKGRD_BRIGHT);
    this.line4Tint = computeTint(
      baseTint,
      Math.max(FDB_BRIGHT + LINE_4_BRIGHT, 0),
      BCKGRD_BRIGHT,
    );
    this.vciTint = computeTint(baseTint, ON_FREQ_BRIGHT, BCKGRD_BRIGHT);
    this.fdbDwellTint = computeTint(baseTint, fdbDwellAlpha, BCKGRD_BRIGHT);
    this.ldbDwellTint = computeTint(
      baseTint,
      Math.min(LDB_BRIGHT + DWELL_BRIGHT, 100),
      BCKGRD_BRIGHT,
    );
    this.line4DwellTint = computeTint(baseTint, line4DwellAlpha, BCKGRD_BRIGHT);
    this.sldbDwellTint = computeTint(
      baseTint,
      Math.min(SLDB_BRIGHT + DWELL_BRIGHT, 100),
      BCKGRD_BRIGHT,
    );
    this.portalTint = computeTint(baseTint, PORTAL_BRIGHT, BCKGRD_BRIGHT);
    this.portalDwellTint = computeTint(
      baseTint,
      Math.min(PORTAL_BRIGHT + DWELL_BRIGHT, 100),
      BCKGRD_BRIGHT,
    );
    this.fenceTint = computeTint(
      colorNameMap.white,
      FENCE_BRIGHT,
      BCKGRD_BRIGHT,
    );
    this.prTgtTint = computeTint(baseTint, PR_TGT_BRIGHT, BCKGRD_BRIGHT);
    this.unpTgtTint = computeTint(baseTint, UNP_TGT_BRIGHT, BCKGRD_BRIGHT);
    this.prHstTint = computeTint(baseTint, PR_HST_BRIGHT, BCKGRD_BRIGHT);
    this.unpHstTint = computeTint(baseTint, UNP_HST_BRIGHT, BCKGRD_BRIGHT);
    this.satCommTint = computeTint(
      colorNameMap.white,
      SATCOMM_BRIGHT,
      BCKGRD_BRIGHT,
    );
    this.cdaTint = computeTint(
      colorNameMap.white,
      NDA_CDA_BRIGHT,
      BCKGRD_BRIGHT,
    );
    this.tocTint = computeTint(
      colorNameMap.white,
      TOC_IC_BRIGHT,
      BCKGRD_BRIGHT,
    );
    this.pidTint = computeTint(colorNameMap.white, PID_BRIGHT, BCKGRD_BRIGHT);
    this.uplinkTimeoutAlpha = TIMEOUT_BRIGHT / 100;
    this.uplinkAlpha = UPLINK_BRIGHT / 100;
    this.rdbAlpha = FDB_BRIGHT / 100;
    this.conflictBrightTint = computeTint(
      baseTint,
      conflictBrightAlpha,
      BCKGRD_BRIGHT,
    );
    this.conflictDimTint = computeTint(
      baseTint,
      conflictDimAlpha,
      BCKGRD_BRIGHT,
    );

    for (const track of this.tracks.values()) {
      track.updateFontAndBright();
    }
  }

  updateFont(data: EramState["font"]) {
    const { FDB_FONT, LDB_FONT, LINE_4_FONT, RDB_FONT } = data;
    this.fdbFontSize = FDB_FONT;
    this.ldbFontSize = LDB_FONT;
    this.line4FontSize = Math.max(FDB_FONT + LINE_4_FONT, 1) as EramFontSize;
    this.rdbFontSize = RDB_FONT;

    for (const track of this.tracks.values()) {
      track.updateFontAndBright();
    }
  }

  addOrUpdateTrack(
    track: EramTrack,
    sectorTrack: EramSectorTrack,
    fp: EramFlightplan | null,
    coordinationData: EramCoordination & EramLocalCoordination,
    quicklookedTrack?: QuicklookedSectorTrack,
  ) {
    if (!this.tracksContainer) {
      return;
    }
    if (!this.tracks.has(track.id)) {
      this.tracks.set(track.id, new TrackNode(track, this.tracksContainer));
    }
    const node = this.tracks.get(track.id)!;
    node.update(track, sectorTrack, fp, coordinationData, quicklookedTrack);
  }

  removeTrack(trackId: TrackId) {
    const node = this.tracks.get(trackId);
    if (node) {
      this.tracks.delete(trackId);
      node.destroy();
    }
  }

  redrawNonLdbTracks() {
    for (const node of this.tracks.values()) {
      if (!node.dbType.endsWith("LDB") || node.quicklookItem) {
        node.draw();
      }
    }
  }

  updateStcaTracks(stcaPairs: ConflictPair[], suppressedPairs: ConflictPair[]) {
    for (const node of this.tracks.values()) {
      node.stcaPairs = stcaPairs.filter(
        (pair) =>
          pair.includes(node.track.id) &&
          !suppressedPairs.some((p) =>
            p.includes(pair[0]) && p.includes(pair[1])
          ),
      );
    }
    this.redrawAllTracks();
  }

  updateCpdlcSessions(cpdlcSessions: Record<FlightplanId, EramCpdlcSession>) {
    for (const node of this.tracks.values()) {
      node.cpdlcSession = node.track.fpId
        ? (cpdlcSessions[node.track.fpId] ?? null)
        : null;
      node.draw();
    }
  }

  redrawAllTracks() {
    for (const node of this.tracks.values()) {
      node.draw();
    }
  }
}

export const trackManager = new TrackManager();
