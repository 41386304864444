import { aircraftStateSelectors } from "@poscon/shared-frontend";
import { createSelector } from "@reduxjs/toolkit";
import type { RootState } from "~redux/store";
import type { FlightplanId } from "@poscon/shared-types";

export const {
  flightplansSelector,
  flightplanSelector,
  tracksSelector,
  trackSelector,
  sectorTracksSelector,
  sectorTrackSelector,
  coordinationDataSelector,
  trackCoordinationDataSelector,
  cpdlcSessionsSelector,
  cpdlcSessionSelector,
  trackFromFpIdSelector,
  flightplanFromCallsignSelector,
  hasCpdlcSessionSelector,
  stcaPairsSelector,
  stcaTracksSelector,
  quicklookedTracksSelector,
  suppressedConflictPairsSelector,
} = aircraftStateSelectors;

export const selectFlightplansById = createSelector(
  [flightplansSelector, (state: RootState, idList: FlightplanId[]) => idList],
  (flightplans, idList) => {
    return idList.map((id) => flightplans[id]!).filter((fp) => idList.includes(fp.id));
  },
);
