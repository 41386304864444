import React, { useEffect, useRef, useState } from "react";
import { useDragging } from "hooks/useDragging";
import { useRootSelector } from "~redux/hooks";
import { ViewMenu } from "components/utils/ViewMenu";
import {
  backgroundOption,
  borderOption,
  brightOption,
  fontOption,
  viewOptionSelector,
} from "~redux/slices/viewOptionSlice";
import { TBE, TBP } from "~/eramConstants";
import { viewPositionSelector } from "~redux/slices/eramStateSlice";
import type { ClockFontSize } from "@poscon/shared-frontend";
import { useReplayContext } from "@poscon/shared-frontend";
import {
  useViewOptionSelected,
  colorNameMap,
  computeColor,
  eramFontDimensionMap,
  eramFontNameMap,
  usePixiMouseEventListener,
} from "@poscon/shared-frontend";
import type { Graphics as PixiGraphics } from "pixi.js";
import { BitmapText, Container, Graphics } from "@pixi/react";
import { useBrightContext } from "contexts/brightnessContext";
import { layerZIndexMap } from "~/layerZIndexMap";
import { InteractiveContainer } from "components/utils/InteractiveContainer";
import { useWindowSize } from "usehooks-ts";

const view = "TIME";

export const initialTimeOptions = {
  background: true,
  bright: 100,
  font: 7,
  border: true,
};

export const timeOptionMap = {
  background: backgroundOption(view),
  border: borderOption(view),
  font: fontOption(view, 1, 8),
  bright: brightOption(view),
};

export const Time = () => {
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const pos = useRootSelector((state) => viewPositionSelector(state, view));
  const replayState = useReplayContext();
  const [date, setDate] = useState(new Date());
  const { selected: showOptions, openViewOption } = useViewOptionSelected(view);
  const viewOptions = useRootSelector((state) => viewOptionSelector(state, view));
  const ref = useRef<PixiGraphics>(null);
  const { borderTint } = useBrightContext();

  const fontName = eramFontNameMap[viewOptions.font as ClockFontSize];
  const fontSize = viewOptions.font as ClockFontSize;
  const { width: fontWidth, height: fontHeight } = eramFontDimensionMap[fontName];
  const [width, height] = [fontWidth * 7 + fontSize * 4, fontHeight + fontSize * 4];

  const { startDrag } = useDragging(ref, view);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDate(new Date());
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  usePixiMouseEventListener((event) => {
    switch (event.button) {
      case TBE:
        event.preventDefault();
        openViewOption();
        break;
      case TBP:
        void startDrag(event);
        break;
    }
  }, ref);

  const tint = computeColor(colorNameMap.white, viewOptions.bright / 100);
  const { x, y } = {
    x: Math.min(pos.x, windowWidth - width),
    y: Math.min(pos.y, windowHeight - height - 1),
  };

  const dateToRender = replayState.replayMode ? new Date(replayState.replayTime) : date;

  return (
    <>
      <InteractiveContainer
        x={x}
        y={y}
        zIndex={layerZIndexMap[viewOptions.background ? "timeOverTracks" : "timeUnderTracks"]}
      >
        <Graphics
          draw={(graphics) => {
            graphics.clear();
            graphics
              .lineStyle(viewOptions.border ? 1 : 0, borderTint)
              .beginFill(0)
              .drawRect(0, 0, width, height)
              .endFill();
          }}
          eventMode="static"
          ref={ref}
        />
        <BitmapText
          text={`${
            dateToRender.getUTCHours().toString().padStart(2, "0") +
            dateToRender.getUTCMinutes().toString().padStart(2, "0")
          } ${dateToRender.getUTCSeconds().toString().padStart(2, "0")}`}
          fontName={fontName}
          tint={tint}
          style={{
            fontName,
            tint,
          }}
          eventMode="none"
          x={Math.floor(width / 2 - fontWidth * 3.5)}
          y={Math.floor(height / 2 - fontHeight / 2)}
        />
      </InteractiveContainer>
      <Container x={pos.x} y={pos.y} zIndex={layerZIndexMap.viewMenu} sortableChildren>
        {showOptions && <ViewMenu view={view} parentWidth={width} options={timeOptionMap} />}
      </Container>
    </>
  );
};
