import React, { useRef } from "react";
import { viewPositionSelector } from "~redux/slices/eramStateSlice";
import type { FlightplanId } from "@poscon/shared-types";
import { PoMenu as BasePoMenu } from "@poscon/shared-frontend";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import type { Container as PixiContainer } from "pixi.js";
import { useDragging } from "hooks/useDragging";
import { flightplanSelector, sectorTrackSelector, trackFromFpIdSelector } from "~redux/slices/aircraftSlice";
import { layerZIndexMap } from "~/layerZIndexMap";
import { processEramMessage } from "~redux/thunks/processEramMessage";
import { setAsel } from "~/redux/slices/eramTempStateSlice";

const view = "PO_MENU";

export const PoMenu = ({ fpId }: { fpId: FlightplanId }) => {
  const dispatch = useRootDispatch();
  const ref = useRef<PixiContainer>(null);
  const pos = useRootSelector((state) => viewPositionSelector(state, view));
  const { startDrag } = useDragging(ref, view);
  const fp = useRootSelector((state) => flightplanSelector(state, fpId));
  const track = useRootSelector((state) => trackFromFpIdSelector(state, fpId));
  const sectorTrack = useRootSelector((state) => (track ? sectorTrackSelector(state, track.id) : null));

  return fp && sectorTrack ? (
    <BasePoMenu
      ref={ref}
      fp={fp}
      sectorTrack={sectorTrack}
      pos={pos}
      startDrag={startDrag}
      closeMenu={() => dispatch(setAsel(null))}
      dispatchCommand={(command) => dispatch(processEramMessage(command))}
      zIndex={layerZIndexMap.prompts}
    />
  ) : null;
};
