import React from "react";
import { View } from "components/utils/View";
import { viewOptionSelector } from "~redux/slices/viewOptionSlice";
import { CpdlcMsgOut as BaseCpdlcMsgOut, cpdlcMsgOutView } from "@poscon/shared-frontend";
import { useRootSelector } from "~redux/hooks";
import { ViewOptionContextProvider } from "contexts/viewOptionContext";

export const CpdlcMsgOut = () => {
  const viewOptions = useRootSelector((state) => viewOptionSelector(state, cpdlcMsgOutView));

  return (
    <ViewOptionContextProvider options={viewOptions}>
      <BaseCpdlcMsgOut ViewComponent={View} />
    </ViewOptionContextProvider>
  );
};
