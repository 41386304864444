import type { RootThunkAction } from "~redux/store";
import { setEramState } from "~redux/slices/eramStateSlice";
import type { EramPrefset } from "~/EramPrefset";
import { setViewOptionState } from "~redux/slices/viewOptionSlice";
import { PhysicalPosition, PhysicalSize } from "@tauri-apps/api/window";
import { WebviewWindow } from "@tauri-apps/api/webviewWindow";
import { setMapScaleToRange, setRangeCenterOverrideToLonLat } from "./mapThunks";

export const applyPrefsetThunk = (prefset: EramPrefset): RootThunkAction => {
  return async (dispatch) => {
    dispatch(setEramState(prefset.eram));
    if (prefset.center) {
      dispatch(setRangeCenterOverrideToLonLat(prefset.center));
    }
    if (prefset.range) {
      dispatch(setMapScaleToRange(prefset.range));
    }
    dispatch(setViewOptionState(prefset.viewOptions));
    if (window.__TAURI__) {
      const outerSize = prefset.tauri.outerSize;
      const size = new PhysicalSize(outerSize.width, outerSize.height);
      await WebviewWindow.getCurrent().setSize(size);
      const outerPosition = prefset.tauri.outerPosition;
      const position = new PhysicalPosition(outerPosition.x, outerPosition.y);
      await WebviewWindow.getCurrent().setPosition(position);
      await WebviewWindow.getCurrent().setFullscreen(prefset.tauri.fullscreen);
      if (prefset.tauri.maximized) {
        await WebviewWindow.getCurrent().maximize();
      } else {
        await WebviewWindow.getCurrent().unmaximize();
      }
    }
  };
};
