import React from "react";
import { View } from "components/utils/View";
import {
  backgroundOption,
  borderOption,
  brightOption,
  fontOption,
  viewOptionSelector,
} from "~redux/slices/viewOptionSlice";
import { ViewOptionContextProvider } from "contexts/viewOptionContext";
import { useRootSelector } from "~redux/hooks";

const view = "CONFLCT_ALERT";

const optionMap = {
  background: backgroundOption(view),
  border: borderOption(view),
  font: fontOption(view),
  bright: brightOption(view),
};

export const ConflictAlert = () => {
  const viewOptions = useRootSelector((state) => viewOptionSelector(state, view));
  return (
    <ViewOptionContextProvider options={viewOptions}>
      <View width={30} height={0} view={view} optionMap={optionMap} />
    </ViewOptionContextProvider>
  );
};
