import React, { useEffect, useRef } from "react";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { deltaRange, setMapScaleToRange } from "~redux/thunks/mapThunks";
import { View } from "components/utils/View";
import { TBE, TBP } from "~/eramConstants";
import type { FederatedPointerEvent, Graphics as PixiGraphics } from "pixi.js";
import { EramInput, eramTextDimensionMap, useInputProps } from "@poscon/shared-frontend";
import { Graphics } from "@pixi/react";
import { useBrightContext } from "contexts/brightnessContext";
import { useSituationDisplay } from "contexts/sdContext";
import { brightOption, viewOptionSelector } from "~redux/slices/viewOptionSlice";
import { ViewOptionContextProvider } from "contexts/viewOptionContext";

const view = "RANGE_MENU";

const optionMap = {
  bright: brightOption(view),
};

const MIN_RANGE = 2;
const MAX_RANGE = 500;

const fontDimensions = eramTextDimensionMap[2];

const width = 40 * fontDimensions.width;
const sliderWidth = fontDimensions.width * 3 + 2;

export const RangeMenu = () => {
  const dispatch = useRootDispatch();
  const viewOptions = useRootSelector((state) => viewOptionSelector(state, view));
  const range = useSituationDisplay().range;
  const prevValue = useRef(range);
  const ref = useRef<PixiGraphics>(null);
  const { borderTint } = useBrightContext();
  const { setInputValue, ...inputProps } = useInputProps(
    `${view}/range`,
    range.toString(),
    (s) => {
      if (s.match(/^\d{1,3}$/)) {
        const newRange = parseInt(s, 10);
        dispatch(setMapScaleToRange(newRange));
        inputProps.setInputFocus(false);
      }
    },
    { resetOnMouseDown: true, maxLength: 3, focusOnMount: false },
  );

  useEffect(() => {
    const _range = range;
    setInputValue(range.toString());
    return () => {
      prevValue.current = _range;
    };
  }, [range, setInputValue]);

  const headerButtons = [
    {
      id: "rangeMenuZoomButton",
      text: "-/+",
      bgColor: 0x00ad00,
      onmousedown: (event: FederatedPointerEvent) => {
        if ([TBP, TBE].includes(event.button)) {
          dispatch(deltaRange(event.button === TBP ? 25 : -25));
        }
      },
    },
    {
      text: "RETURN",
      bgColor: 0x000000,
      onmousedown: () => dispatch(setMapScaleToRange(prevValue.current)),
    },
    // { text: "", bgColor: 0x000000 },
    {
      text: "100",
      bgColor: 0x000000,
      onmousedown: () => dispatch(setMapScaleToRange(100)),
    },
    {
      text: "1000",
      bgColor: 0x000000,
      onmousedown: () => dispatch(setMapScaleToRange(1000)),
    },
    { text: "      ", bgColor: 0x000000 },
  ];

  const sliderPos = Math.max(0, Math.min((range - MIN_RANGE) / (MAX_RANGE - MIN_RANGE), 1));
  const sliderX = sliderPos * (width - sliderWidth);

  const onSliderMouseDown = (event: FederatedPointerEvent) => {
    if (ref.current && event.button === TBP) {
      const { x } = event.getLocalPosition(ref.current);
      const newRange = Math.max(
        MIN_RANGE,
        Math.min(
          Math.round(((x - sliderWidth / 2) / (width - sliderWidth)) * (MAX_RANGE - MIN_RANGE)) + MIN_RANGE,
          MAX_RANGE,
        ),
      );
      dispatch(setMapScaleToRange(newRange));
    }
  };

  const height = 2 * (fontDimensions.height + 2);

  return (
    <ViewOptionContextProvider options={viewOptions}>
      <View
        view={view}
        width={40}
        height={height}
        headerButtons={headerButtons}
        optionMap={optionMap}
        borderColor={0xadadad}
      >
        <Graphics
          zIndex={0}
          draw={(graphics) => {
            graphics.clear();
            graphics.beginFill(0x515151);
            graphics.drawRect(0, 0, 40 * fontDimensions.width - 1, fontDimensions.height + 2);
            graphics.endFill();
          }}
        />
        <EramInput
          {...inputProps}
          x={sliderX}
          y={2}
          fontSize={2}
          width={fontDimensions.width * 4}
          tint={0xc0c0c0}
        />
        <Graphics
          ref={ref}
          zIndex={0}
          y={fontDimensions.height + 2}
          eventMode="static"
          draw={(graphics) => {
            graphics.clear();
            graphics.lineStyle(1, borderTint);
            graphics.beginFill(0x616161);
            graphics.drawRect(0, 0, 40 * fontDimensions.width, fontDimensions.height + 2);
            graphics.endFill();
          }}
          onmousedown={onSliderMouseDown}
        />
        <Graphics
          zIndex={1}
          eventMode="none"
          draw={(graphics) => {
            graphics.clear();
            graphics.lineStyle(1, borderTint);
            graphics.beginFill(0x919191);
            graphics.drawRect(0, 0, sliderWidth, fontDimensions.height + 2);
            graphics.endFill();
          }}
          x={sliderX}
          y={fontDimensions.height + 2}
        />
      </View>
    </ViewOptionContextProvider>
  );
};
