import type { ComponentType } from "react";
import React, { useEffect, useLayoutEffect, useRef } from "react";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import type { DrawItem, DrawItemType } from "~redux/slices/eramStateSlice";
import { drawItemsSelector, removeDrawItem, updateDrawItem } from "~redux/slices/eramStateSlice";
import { situationDisplayStore } from "~/situationDisplayStore";
import type { Coordinate } from "@poscon/shared-types";
import { TBE, TBP } from "~/eramConstants";
import { useDrawContext } from "contexts/drawContext";
import { LogicalPosition } from "@tauri-apps/api/window";
import type { BitmapText as PixiBitmapText, FederatedPointerEvent, Graphics as PixiGraphics } from "pixi.js";
import { Point, Rectangle } from "pixi.js";
import {
  eramFontNameMap,
  EramInput,
  eramTextDimensionMap,
  useStableCallback,
  useInputProps,
  usePixiMouseEventListener,
  useUiIsLocked,
} from "@poscon/shared-frontend";
import { BitmapText, Container, Graphics } from "@pixi/react";
import { useSituationDisplay, useGetSdCoordFromLonLat } from "contexts/sdContext";
import { getDrawColor } from "~/utils/drawColorMap";
import { clipCursorToSituationDisplay } from "~/utils/cursor";
import { WebviewWindow } from "@tauri-apps/api/webviewWindow";

const { width: fontWidth, height: fontHeight } = eramTextDimensionMap[2];

type DrawItemAnchorProps = DrawItem;
const DrawItemAnchor = (item: DrawItemAnchorProps) => {
  const dispatch = useRootDispatch();
  const { drawMode, setDrawMode, drawColor, drawColorBright, drawFontSize } = useDrawContext();
  const ref = useRef<PixiGraphics>(null);

  usePixiMouseEventListener((event) => {
    if (drawMode === "DELETE") {
      dispatch(removeDrawItem(item.id));
      setDrawMode("OPEN");
    }
    if (drawMode === "OPEN" && !item.element.editMode) {
      event.stopImmediatePropagation();
      if (event.button === TBE) {
        dispatch(
          updateDrawItem({
            ...item,
            element: {
              ...item.element,
              color: drawColor,
              bright: drawColorBright,
            },
          }),
        );
        if (item.element.itemType === "TEXT") {
          dispatch(
            updateDrawItem({
              ...item,
              element: {
                ...item.element,
                color: drawColor,
                bright: drawColorBright,
                fontSize: drawFontSize,
              },
            }),
          );
        } else {
          dispatch(
            updateDrawItem({
              ...item,
              element: {
                ...item.element,
                color: drawColor,
                bright: drawColorBright,
              },
            }),
          );
        }
      }
      if (event.button === TBP && item.element.itemType !== "LINE") {
        setDrawMode("EDIT");
        if (item.element.itemType === "RECT") {
          dispatch(
            updateDrawItem({
              ...item,
              element: { ...item.element, editMode: "MOVE_CORNER" },
            } as DrawItem<"RECT">),
          );
        }
        if (item.element.itemType === "TEXT") {
          dispatch(
            updateDrawItem({
              ...item,
              element: { ...item.element, editMode: "MOVE" },
            } as DrawItem<"TEXT">),
          );
        }
        if (item.element.itemType === "CIRCLE") {
          dispatch(
            updateDrawItem({
              ...item,
              element: { ...item.element, editMode: "RESIZE" },
            } as DrawItem<"CIRCLE">),
          );
        }
      }
    }
  }, ref);

  const tint = getDrawColor(item.element.color, item.element.bright);

  const lineLength = fontHeight + 4;

  const x = -10 - fontHeight;
  const y = -10 - fontHeight;

  return (
    <Container x={x} y={y}>
      <Graphics
        eventMode="static"
        draw={(graphics) => {
          // draw outline
          graphics.clear();
          graphics.lineStyle(1, tint, 1, 0.5, true);
          graphics.drawRect(0, 0, lineLength, lineLength);
          graphics.hitArea = new Rectangle(0, 0, lineLength, lineLength);
        }}
        ref={ref}
      />
      <BitmapText
        text={item.anchor === "MAP" ? "M" : "D"}
        x={Math.floor(lineLength / 2 - fontWidth / 2)}
        y={Math.floor(3)}
        eventMode="none"
        tint={tint}
        style={{ fontName: eramFontNameMap[2], tint }}
      />
    </Container>
  );
};

const DrawCircleItem = (item: DrawItem<"CIRCLE">) => {
  const dispatch = useRootDispatch();
  const ref = useRef<PixiGraphics>(null);
  const textRef = useRef<PixiBitmapText>(null);
  const { drawMode, setDrawMode } = useDrawContext();
  const { sdRef, rect: sdRect, unclipCursorFromElement } = useSituationDisplay();
  const previewRadiusRef = useRef<number | null>(null);
  const getPixelCoordFromLonLat = useGetSdCoordFromLonLat();
  const uiIsLocked = useUiIsLocked();
  const colorRef = useRef(getDrawColor(item.element.color, item.element.bright));
  colorRef.current = getDrawColor(item.element.color, item.element.bright);
  const tint = getDrawColor(item.element.color, item.element.bright);

  const draw = useStableCallback((graphics: PixiGraphics, radius: number, previewCoord?: Coordinate) => {
    graphics.clear();
    graphics.lineStyle(1, colorRef.current, 1, 0.5, true);
    graphics.drawCircle(0, 0, radius);

    const previewRadius = previewRadiusRef.current;
    if (previewCoord && previewRadius) {
      graphics.lineStyle(1, 0xf0f0f0, 1, 0.5, true);
      const [px, py] = previewCoord;
      graphics.drawCircle(px, py, previewRadius);
      const text = textRef.current;
      if (text && item.anchor === "MAP") {
        text.text = (previewRadius * situationDisplayStore.pixelLen).toFixed(1);
        text.x = Math.round(px + radius - text.width / 2);
        text.y = Math.round(py + radius - text.height / 2);
      }
    }
  });

  const radius =
    item.anchor === "MAP"
      ? situationDisplayStore.nmToPx(item.element.radius)
      : (item.element.radius - 0.5) * 2 * Math.PI;

  useLayoutEffect(() => {
    const sdGraphics = sdRef.current;
    const abortController = new AbortController();
    const signal = abortController.signal;
    if (sdGraphics && ref.current && !uiIsLocked) {
      const repositionCursor = async (center = true) => {
        if (window.__TAURI__ && ref.current) {
          await clipCursorToSituationDisplay();
          const rect = ref.current.getBounds();
          const centerCoord = item.anchor === "MAP" ? getPixelCoordFromLonLat(item.origin) : item.origin;
          await WebviewWindow.getCurrent().setCursorPosition(
            new LogicalPosition(
              center ? centerCoord[0] : rect.right,
              center ? centerCoord[1] : rect.top + rect.height / 2,
            ),
          );
        }
      };
      if (item.element.editMode === "RESIZE") {
        void repositionCursor(false);
        const mouseMoveListener = (event: FederatedPointerEvent) => {
          if (ref.current) {
            const rect = ref.current.getBounds();
            const eventCoord = [event.clientX, event.clientY] satisfies Coordinate;
            previewRadiusRef.current = Math.sqrt(
              (rect.left + rect.width / 2 - eventCoord[0]) ** 2 +
                (rect.top + rect.height / 2 - eventCoord[1]) ** 2,
            );
            draw(ref.current, radius, [0, 0]);
          }
        };
        sdGraphics.addEventListener("mousemove", mouseMoveListener, { signal });
        const stopListener = (event: FederatedPointerEvent) => {
          event.stopImmediatePropagation();
          dispatch(
            updateDrawItem({
              ...item,
              element: {
                ...item.element,
                editMode: "MOVE_CENTER",
              },
            }),
          );
          unclipCursorFromElement();
          abortController.abort();
        };
        sdGraphics.addEventListener("mousedown", stopListener, {
          once: true,
          capture: true,
          signal,
        });
        return () => {
          abortController.abort();
        };
      }
      if (item.element.editMode === "MOVE_CENTER" && previewRadiusRef.current !== null) {
        void repositionCursor();
        const newRadius =
          item.anchor === "MAP"
            ? previewRadiusRef.current! * situationDisplayStore.pixelLen
            : previewRadiusRef.current! / (2 * Math.PI) + 0.5;
        draw(ref.current, radius, [0, 0]);
        const mouseMoveListener = (event: FederatedPointerEvent) => {
          if (ref.current) {
            const point = new Point(event.clientX, event.clientY);
            ref.current.transform.worldTransform.applyInverse(point, point);
            draw(ref.current, radius, [point.x, point.y]);
          }
        };
        sdGraphics.addEventListener("mousemove", mouseMoveListener, { signal });
        const stopListener = (event: FederatedPointerEvent) => {
          setDrawMode("OPEN");
          unclipCursorFromElement();
          event.stopImmediatePropagation();
          const newOrigin: Coordinate =
            item.anchor === "MAP"
              ? situationDisplayStore.getLonLatFromSdCoord([event.clientX, event.clientY])
              : [event.clientX, event.clientY];
          dispatch(
            updateDrawItem({
              ...item,
              origin: newOrigin,
              element: {
                ...item.element,
                radius: newRadius,
                editMode: undefined,
              },
            }),
          );
          previewRadiusRef.current = null;
          sdGraphics.removeEventListener("mousemove", mouseMoveListener);
        };
        sdGraphics.addEventListener("mousedown", stopListener, {
          once: true,
          capture: true,
          signal,
        });
        return () => {
          abortController.abort();
        };
      }
    }
    return undefined;
  }, [
    item.origin,
    dispatch,
    item,
    sdRef,
    setDrawMode,
    sdRect,
    radius,
    unclipCursorFromElement,
    getPixelCoordFromLonLat,
    uiIsLocked,
    draw,
  ]);

  const [_x, _y] = item.anchor === "MAP" ? getPixelCoordFromLonLat(item.origin) : item.origin;
  const x = Math.round(_x - radius);
  const y = Math.round(_y - radius);

  return (
    <Container x={x} y={y} name={`DRAW_CIRCLE_${item.id}`} eventMode="static">
      <Graphics ref={ref} x={radius} y={radius} draw={(graphics) => draw(graphics, radius)} />
      {item.anchor === "MAP" && item.element.editMode === "RESIZE" && (
        <BitmapText
          ref={textRef}
          eventMode="none"
          tint={tint}
          style={{
            fontName: eramFontNameMap[1],
            tint,
          }}
        />
      )}
      {drawMode !== "CLOSED" && <DrawItemAnchor {...item} />}
    </Container>
  );
};

const getRectDim = (topLeft: Coordinate, bottomRight: Coordinate, anchor: "MAP" | "DISP") => {
  const _coords = [
    topLeft,
    [topLeft[0], bottomRight[1]],
    bottomRight,
    [bottomRight[0], topLeft[1]],
    topLeft,
  ] as const;
  const coords =
    anchor === "MAP" ? _coords.map((c) => situationDisplayStore.getSdCoordFromLonLat(c as Coordinate)) : _coords;

  const width = coords[2][0] - coords[0][0];
  const height = coords[2][1] - coords[0][1];

  return { width, height };
};

const DrawRectItem = (item: DrawItem<"RECT">) => {
  const dispatch = useRootDispatch();
  const ref = useRef<PixiGraphics>(null);
  const coordinatesRef = useRef<{
    topLeft: Coordinate;
    bottomRight: Coordinate;
    previewOffset: Coordinate | null;
    previewBottomRight: Coordinate | null;
  }>({
    topLeft: item.origin,
    bottomRight: item.element.bottomRight ?? item.origin,
    previewOffset: null,
    previewBottomRight: null,
  });
  const getPixelCoordFromLonLat = useGetSdCoordFromLonLat();
  const { drawMode, setDrawMode } = useDrawContext();
  const { sdRef, rect: sdRect, unclipCursorFromElement } = useSituationDisplay();
  const uiIsLocked = useUiIsLocked();
  const colorRef = useRef(getDrawColor(item.element.color, item.element.bright));
  colorRef.current = getDrawColor(item.element.color, item.element.bright);

  const draw = useStableCallback((graphics: PixiGraphics) => {
    const { topLeft, bottomRight } = coordinatesRef.current;
    const _coords = [
      topLeft,
      [topLeft[0], bottomRight[1]],
      bottomRight,
      [bottomRight[0], topLeft[1]],
      topLeft,
    ] as const;
    const coords =
      item.anchor === "MAP"
        ? _coords.map((c) => situationDisplayStore.getSdCoordFromLonLat(c as Coordinate))
        : _coords;

    const width = coords[2][0] - coords[0][0];
    const height = coords[2][1] - coords[0][1];

    graphics.clear();
    graphics.lineStyle(1, colorRef.current, 1, 0.5, true);
    graphics.drawRect(0, 0, width, height);
    const { previewOffset, previewBottomRight } = coordinatesRef.current;
    if (previewOffset && previewBottomRight) {
      const _coords = [
        topLeft,
        [topLeft[0], previewBottomRight[1]],
        previewBottomRight,
        [previewBottomRight[0], topLeft[1]],
        topLeft,
      ] as const;
      const coords =
        item.anchor === "MAP"
          ? _coords.map((c) => situationDisplayStore.getSdCoordFromLonLat(c as Coordinate))
          : _coords;
      const previewWidth = coords[2][0] - coords[0][0];
      const previewHeight = coords[2][1] - coords[0][1];
      graphics.moveTo(0, 0);
      graphics.lineStyle(1, 0xf0f0f0, 1, 0.5, true);
      graphics.drawRect(previewOffset[0], previewOffset[1], previewWidth, previewHeight);
    }
  });

  useEffect(() => {
    if (!item.element.editMode && ref.current) {
      coordinatesRef.current.topLeft = item.origin;
      coordinatesRef.current.bottomRight = item.element.bottomRight ?? item.origin;
      draw(ref.current);
    }
  }, [draw, item.element.bottomRight, item.element.editMode, item.origin]);

  useLayoutEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const sdGraphics = sdRef.current;
    if (sdGraphics && ref.current && !uiIsLocked) {
      const repositionMouse = async (corner: "topLeft" | "bottomRight") => {
        if (window.__TAURI__ && ref.current) {
          await clipCursorToSituationDisplay();
          const rect = ref.current.getBounds();
          const coord: Coordinate = corner === "topLeft" ? [rect.left, rect.top] : [rect.right, rect.bottom];
          await WebviewWindow.getCurrent().setCursorPosition(new LogicalPosition(coord[0], coord[1]));
        }
      };
      if (item.element.editMode === "MOVE_CORNER") {
        setDrawMode("EDIT");
        if (window.__TAURI__) {
          void clipCursorToSituationDisplay();
        }
        void repositionMouse("bottomRight");
        coordinatesRef.current.previewOffset = [0, 0];
        coordinatesRef.current.previewBottomRight = coordinatesRef.current.bottomRight;
        const mouseMoveListener = (event: FederatedPointerEvent) => {
          if (ref.current) {
            const coord = [event.clientX, event.clientY] satisfies Coordinate;
            coordinatesRef.current.previewOffset = [0, 0];
            coordinatesRef.current.previewBottomRight =
              item.anchor === "MAP" ? situationDisplayStore.getLonLatFromSdCoord(coord) : coord;
            draw(ref.current);
          }
        };
        sdGraphics.addEventListener("mousemove", mouseMoveListener, { signal });
        const stopListener = (event: FederatedPointerEvent) => {
          setDrawMode("OPEN");
          void unclipCursorFromElement();
          if (ref.current) {
            event.stopImmediatePropagation();
            const coord = [event.clientX, event.clientY] satisfies Coordinate;
            coordinatesRef.current.previewBottomRight =
              item.anchor === "MAP" ? situationDisplayStore.getLonLatFromSdCoord(coord) : coord;
            const newBottomRight = coordinatesRef.current.previewBottomRight;
            draw(ref.current);
            if (!item.element.bottomRight) {
              coordinatesRef.current.bottomRight = newBottomRight;
              coordinatesRef.current.previewOffset = null;
              coordinatesRef.current.previewBottomRight = null;
            }
            draw(ref.current);
            dispatch(
              updateDrawItem({
                ...item,
                element: {
                  ...item.element,
                  bottomRight: item.element.bottomRight ?? newBottomRight,
                  editMode: item.element.bottomRight ? "MOVE_ORIGIN" : undefined,
                },
              }),
            );
          }
          abortController.abort();
        };
        sdGraphics.addEventListener("mousedown", stopListener, {
          once: true,
          capture: true,
          signal,
        });
        return () => {
          abortController.abort();
        };
      }
      if (item.element.editMode === "MOVE_ORIGIN") {
        setDrawMode("EDIT");
        if (window.__TAURI__) {
          void clipCursorToSituationDisplay();
        }
        const mouseMoveListener = (event: FederatedPointerEvent) => {
          if (ref.current) {
            const point = new Point(event.clientX, event.clientY);
            ref.current.transform.worldTransform.applyInverse(point, point);
            coordinatesRef.current.previewOffset = [point.x, point.y];
            draw(ref.current);
          }
        };
        repositionMouse("topLeft").then(() => {
          if (!abortController.signal.aborted) {
            sdGraphics.addEventListener("mousemove", mouseMoveListener, { signal });
          }
        });
        const stopListener = (event: FederatedPointerEvent) => {
          void unclipCursorFromElement();
          const coord = [event.clientX, event.clientY] satisfies Coordinate;
          const { width, height } = getRectDim(
            coordinatesRef.current.topLeft,
            coordinatesRef.current.previewBottomRight!,
            item.anchor,
          );
          const newOrigin = item.anchor === "MAP" ? situationDisplayStore.getLonLatFromSdCoord(coord) : coord;
          const bottomRight = [coord[0] + width, coord[1] + height] satisfies Coordinate;
          coordinatesRef.current.bottomRight =
            item.anchor === "MAP" ? situationDisplayStore.getLonLatFromSdCoord(bottomRight) : bottomRight;
          coordinatesRef.current.topLeft = newOrigin;
          coordinatesRef.current.previewOffset = null;
          coordinatesRef.current.previewBottomRight = null;
          dispatch(
            updateDrawItem({
              ...item,
              origin: newOrigin,
              element: {
                ...item.element,
                editMode: undefined,
                bottomRight: coordinatesRef.current.bottomRight,
              },
            }),
          );
          setDrawMode("OPEN");
          sdGraphics.removeEventListener("mousemove", mouseMoveListener);
        };
        sdGraphics.addEventListener("mousedown", stopListener, {
          once: true,
          capture: true,
          signal,
        });
        return () => {
          abortController.abort();
          void unclipCursorFromElement();
        };
      }
    }
    return () => {
      abortController.abort();
    };
  }, [dispatch, draw, item, sdRect, sdRef, setDrawMode, uiIsLocked, unclipCursorFromElement]);

  const origin =
    item.anchor === "MAP"
      ? getPixelCoordFromLonLat(coordinatesRef.current.topLeft)
      : coordinatesRef.current.topLeft;
  const x = Math.round(origin[0]);
  const y = Math.round(origin[1]);

  return (
    <Container x={x} y={y} eventMode="static">
      <Graphics ref={ref} draw={(graphics) => draw(graphics)} />
      {drawMode !== "CLOSED" && <DrawItemAnchor {...item} />}
    </Container>
  );
};

// TODO: support changing lines after TBP on anchor
const DrawLineItem = (item: DrawItem<"LINE">) => {
  const dispatch = useRootDispatch();
  const ref = useRef<PixiGraphics>(null);
  const coordsRef = useRef<Coordinate[]>([...item.element.coordinates]);
  const { drawMode, setDrawMode } = useDrawContext();
  const { sdRef } = useSituationDisplay();
  const getPixelCoordFromLonLat = useGetSdCoordFromLonLat();
  const uiIsLocked = useUiIsLocked();
  const colorRef = useRef(getDrawColor(item.element.color, item.element.bright));
  colorRef.current = getDrawColor(item.element.color, item.element.bright);

  const draw = useStableCallback((graphics: PixiGraphics, nextPoint?: Coordinate) => {
    const coords = coordsRef.current;

    const lineCoords = nextPoint
      ? [...coords, nextPoint]
      : coords.length === 1
        ? [coords[0]!, coords[0]!]
        : coords;
    const linePixelCoords =
      item.anchor === "MAP" ? lineCoords.map((c) => situationDisplayStore.getSdCoordFromLonLat(c)) : lineCoords;

    graphics.clear();
    graphics.lineStyle(1, colorRef.current, 1, 0.5, true);
    graphics.moveTo(0, 0);
    linePixelCoords.forEach((coord) => {
      graphics.lineTo(coord[0] - linePixelCoords[0]![0], coord[1] - linePixelCoords[0]![1]);
    });
  });

  useEffect(() => {
    if (!item.element.editMode && ref.current) {
      coordsRef.current = item.element.coordinates;
      draw(ref.current);
    }
  }, [draw, item.element.coordinates, item.element.editMode]);

  useLayoutEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const sdGraphics = sdRef.current;
    if (sdGraphics && !uiIsLocked) {
      if (item.element.editMode === "ADD_POINT") {
        setDrawMode("EDIT");
        const mouseMoveListener = (event: MouseEvent) => {
          if (ref.current) {
            event.stopImmediatePropagation();
            const coord = [event.clientX, event.clientY] satisfies Coordinate;
            const nextPoint = item.anchor === "MAP" ? situationDisplayStore.getLonLatFromSdCoord(coord) : coord;
            draw(ref.current, nextPoint);
          }
        };
        sdGraphics.addEventListener("mousemove", mouseMoveListener, { signal });
        const clickListener = (event: FederatedPointerEvent) => {
          if (ref.current) {
            event.stopImmediatePropagation();
            const coord = [event.clientX, event.clientY] satisfies Coordinate;
            const nextPoint = item.anchor === "MAP" ? situationDisplayStore.getLonLatFromSdCoord(coord) : coord;
            coordsRef.current = [...coordsRef.current, nextPoint];
            dispatch(
              updateDrawItem({
                ...item,
                element: {
                  ...item.element,
                  coordinates: coordsRef.current,
                  editMode: event.button === TBE ? undefined : "ADD_POINT",
                },
              }),
            );
            draw(ref.current);
            if (event.button === TBE) {
              setDrawMode("OPEN");
            }
          }
          abortController.abort();
        };
        sdGraphics.addEventListener("mousedown", clickListener, {
          once: true,
          capture: true,
          signal,
        });
        return () => {
          abortController.abort();
        };
      }
    }
    return () => {
      abortController.abort();
    };
  }, [dispatch, draw, item, sdRef, setDrawMode, uiIsLocked]);

  const coord = coordsRef.current[0]!;
  const pixelCoord = item.anchor === "MAP" ? getPixelCoordFromLonLat(coord) : coord;
  const x = Math.round(pixelCoord[0]);
  const y = Math.round(pixelCoord[1]);

  return (
    <Container x={x} y={y} name={`DRAW_LINE_${item.id}`} eventMode="static">
      <Graphics ref={ref} draw={(graphics) => draw(graphics)} />
      {drawMode !== "CLOSED" && <DrawItemAnchor {...item} />}
    </Container>
  );
};

const DrawTextItem = (item: DrawItem<"TEXT">) => {
  const dispatch = useRootDispatch();
  const ref = useRef<PixiGraphics>(null);
  const { drawMode, setDrawMode } = useDrawContext();
  const { sdRef } = useSituationDisplay();
  const getPixelCoordFromLonLat = useGetSdCoordFromLonLat();
  const uiIsLocked = useUiIsLocked();
  const { setInputFocus, ...inputProps } = useInputProps(
    `draw/text/${item.id}`,
    item.element.text,
    (s) => {
      dispatch(
        updateDrawItem({
          ...item,
          element: {
            ...item.element,
            text: s,
            editMode: undefined,
          },
        }),
      );
      setDrawMode("OPEN");
    },
    {
      maxLength: 35,
    },
  );
  const colorRef = useRef(getDrawColor(item.element.color, item.element.bright));
  colorRef.current = getDrawColor(item.element.color, item.element.bright);

  const draw = useStableCallback((graphics: PixiGraphics, previewPos?: Coordinate) => {
    graphics.clear();

    const fontDim = eramTextDimensionMap[item.element.fontSize];
    if (item.element.editMode) {
      graphics.lineStyle(1, colorRef.current, 1, 0.5, true);
      graphics.drawRect(0, 0, fontDim.width * 12 + 4, fontDim.height * 3 + 4);
    }
    if (previewPos) {
      graphics.lineStyle(1, 0xf0f0f0, 1, 0.5, true);
      graphics.drawRect(previewPos[0], previewPos[1], fontDim.width * 12 + 4, (fontDim.height + 2) * 3 + 4);
    }
  });

  useEffect(() => {
    if (!uiIsLocked && item.element.editMode === "EDIT_TEXT") {
      if (!inputProps.isActive) {
        setInputFocus(true);
      }
    } else {
      setInputFocus(false);
      inputProps.setInputValue(item.element.text);
    }
  }, [
    inputProps,
    inputProps.isActive,
    item.element.bright,
    item.element.color,
    item.element.editMode,
    item.element.text,
    setInputFocus,
    uiIsLocked,
  ]);

  useLayoutEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const sdGraphics = sdRef.current;
    if (sdGraphics && !uiIsLocked) {
      if (item.element.editMode === "MOVE") {
        if (window.__TAURI__) {
          const pos =
            item.anchor === "MAP" ? situationDisplayStore.getSdCoordFromLonLat(item.origin) : item.origin;
          void WebviewWindow.getCurrent().setCursorPosition(new LogicalPosition(pos[0], pos[1]));
        }
        const mouseMoveListener = (event: MouseEvent) => {
          if (ref.current) {
            event.stopImmediatePropagation();
            const point = new Point(event.clientX, event.clientY);
            ref.current.transform.worldTransform.applyInverse(point, point);
            draw(ref.current, [point.x, point.y]);
          }
        };
        sdRef.current.addEventListener("mousemove", mouseMoveListener, { signal });
        const stopListener = (event: FederatedPointerEvent) => {
          event.stopImmediatePropagation();
          const coord = [event.clientX, event.clientY] satisfies Coordinate;
          const position = item.anchor === "MAP" ? situationDisplayStore.getLonLatFromSdCoord(coord) : coord;
          dispatch(
            updateDrawItem({
              ...item,
              origin: position,
              element: { ...item.element, editMode: "EDIT_TEXT" },
            }),
          );
          abortController.abort();
        };
        sdGraphics.addEventListener("mousedown", stopListener, {
          once: true,
          capture: true,
          signal,
        });
        return () => {
          abortController.abort();
        };
      }
    }
    return undefined;
  }, [dispatch, draw, item, sdRef, setDrawMode, uiIsLocked]);

  const tint = getDrawColor(item.element.color, item.element.bright);

  const coord = item.anchor === "MAP" ? getPixelCoordFromLonLat(item.origin) : item.origin;
  const x = Math.round(coord[0]);
  const y = Math.round(coord[1]);

  return (
    <Container x={x} y={y} name={`DRAW_TEXT_${item.id}`}>
      <Graphics ref={ref} draw={(graphics) => draw(graphics)} visible={!!item.element.editMode} />
      <EramInput
        width={12 * eramTextDimensionMap[item.element.fontSize].width}
        tint={tint}
        chunkSize={12}
        fontSize={item.element.fontSize}
        {...inputProps}
      />
      {drawMode !== "CLOSED" && <DrawItemAnchor {...item} />}
    </Container>
  );
};

const drawItemMap: {
  [K in DrawItemType]: ComponentType<DrawItem<K>>;
} = {
  CIRCLE: DrawCircleItem,
  LINE: DrawLineItem,
  RECT: DrawRectItem,
  TEXT: DrawTextItem,
};

export const EramDrawItems = () => {
  const items = useRootSelector(drawItemsSelector);
  const { drawMode } = useDrawContext();

  return (
    <Container name="DRAW_CONTAINER" zIndex={2} eventMode={drawMode !== "CLOSED" ? "static" : "none"}>
      {items.map((item) => {
        const Component = drawItemMap[item.element.itemType];

        // @ts-ignore
        return <Component key={item.id} {...item} />;
      })}
    </Container>
  );
};
