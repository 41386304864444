import React, { useRef } from "react";
import type { Container as PixiContainer } from "pixi.js";
import { useRootSelector } from "~redux/hooks";
import { routeLineMapSelector } from "~redux/slices/eramTempStateSlice";
import { useGetSdCoordFromLonLat } from "contexts/sdContext";
import { BitmapText, Container, Graphics } from "@pixi/react";
import { eramFontNameMap } from "@poscon/shared-frontend";

const tint = new Uint8Array([0xad, 0xad, 0x00]);

export const RouteLines = () => {
  const ref = useRef<PixiContainer>(null);
  const getPixelCoordFromLonLat = useGetSdCoordFromLonLat();
  const routeLineMap = useRootSelector(routeLineMapSelector);
  const fdbFontSize = useRootSelector((state) => state.eram.font.FDB_FONT);

  return (
    <Container name="ROUTE_LINES" eventMode="none" ref={ref} zIndex={6}>
      {Object.entries(routeLineMap).map(([id, line]) => {
        const points = line.coordinates;
        if (line.coordinates.length === 0) {
          return null;
        }
        const start = getPixelCoordFromLonLat(points[0]!);
        return (
          <React.Fragment key={id}>
            <Graphics
              draw={(graphics) => {
                graphics.clear();
                graphics.lineStyle(2, tint, 1);
                graphics.drawRect(start[0] - 4, start[1] - 2, 8, 4);
                graphics.moveTo(start[0], start[1]);
                points.slice(1).forEach((point) => {
                  const p = getPixelCoordFromLonLat(point);
                  graphics.lineTo(p[0], p[1]);
                });
                const end = points.at(-1)!;
                const p = getPixelCoordFromLonLat(end);
                if (line.fullRoute) {
                  graphics
                    .moveTo(p[0] - 8, p[1] - 10)
                    .lineTo(p[0] + 8, p[1] + 10)
                    .lineTo(p[0] - 8, p[1] + 10)
                    .lineTo(p[0] + 8, p[1] - 10);
                } else {
                  graphics
                    .beginFill(tint)
                    .drawRect(p[0] - 4, p[1] - 2, 8, 4)
                    .endFill();
                }
              }}
            />
            <BitmapText
              text={id.split("-").at(-1)!}
              x={start[0]}
              y={start[1]}
              style={{ fontName: eramFontNameMap[fdbFontSize], tint }}
            />
          </React.Fragment>
        );
      })}
    </Container>
  );
};
