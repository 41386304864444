import React, { useRef } from "react";
import { useTearOffContext } from "contexts/tearOffContext";
import { isPriorityButton } from "types/eramButton";
import type { ToolbarId } from "~redux/slices/eramStateSlice";
import type { Graphics as PixiGraphics } from "pixi.js";
import { Rectangle } from "pixi.js";
import { useButtonContext } from "contexts/buttonContext";
import { useFocused, usePixiMouseEventListener } from "@poscon/shared-frontend";
import { Graphics } from "@pixi/react";

type GhostButtonProps = {
  toolbarId: ToolbarId;
  row: number;
  col: number;
  active: boolean;
};
export const GhostButton = ({ toolbarId, row, col, active }: GhostButtonProps) => {
  const { tearingButton, tearingMacroLabel, onGhostButtonClick } = useTearOffContext();
  const { buttonWidth, buttonHeight } = useButtonContext();
  const ref = useRef<PixiGraphics>(null);
  const focused = useFocused(ref);

  const canBeActive = !!tearingButton && !isPriorityButton(tearingButton);

  usePixiMouseEventListener(
    (event) => {
      if (tearingButton && !isPriorityButton(tearingButton)) {
        event.stopImmediatePropagation();
        onGhostButtonClick(toolbarId, tearingButton, row, col, tearingMacroLabel ?? undefined);
      }
    },
    ref,
    true
  );

  const draw = (graphics: PixiGraphics) => {
    graphics.clear();
    if (canBeActive && active) {
      graphics.lineStyle(1, focused ? 0xffffff : 0x00ad00);
      graphics.drawRect(0, 0, buttonWidth, buttonHeight);
    }
  };

  return (
    <Graphics
      zIndex={-1}
      name={`${toolbarId}_GHOST_BUTTON_${row}_${col}`}
      eventMode="static"
      ref={ref}
      draw={draw}
      hitArea={canBeActive ? new Rectangle(-1, -1, buttonWidth + 2, buttonHeight + 2) : null}
      x={col * (buttonWidth + 1)}
      y={row * (buttonHeight + 1)}
    />
  );
};
