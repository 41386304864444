import React, { useEffect, useMemo, useSyncExternalStore } from "react";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import type { Graphics } from "pixi.js";
import type { EramView } from "types/eramView";
import { eramViews } from "types/eramView";
import type { WindowPosition } from "@poscon/shared-frontend";
import { useStableCallback } from "@poscon/shared-frontend";
import type { UnlistenFn } from "@tauri-apps/api/event";
import { listen, TauriEvent } from "@tauri-apps/api/event";
import { setToggleButtonValue } from "~redux/slices/eramStateSlice";
import { clipCursorToSituationDisplay } from "~/utils/cursor";
import { situationDisplayStore } from "~/situationDisplayStore";
import { WebviewWindow } from "@tauri-apps/api/webviewWindow";
import { errorTone } from "@poscon/shared-frontend";
import { mapScaleSelector } from "~/redux/slices/eramTempStateSlice";

function viewIsInsideAB(view: EramView, position: WindowPosition) {
  const viewRect = document.getElementById(view)?.getBoundingClientRect();
  const sdRect = situationDisplayStore.rect;
  if (sdRect && viewRect) {
    if (position.x < sdRect.x || position.x + viewRect.width > sdRect.x + sdRect.width) {
      return false;
    }
  }
  return true;
}

type SituationDisplayContextValue = {
  sdRef: React.RefObject<Graphics>;
  unclipCursorFromElement: () => void;
} & ReturnType<typeof situationDisplayStore.getState>;
export const SituationDisplayContext = React.createContext<SituationDisplayContextValue | null>(null);

type SituationDisplayContextProviderProps = {
  children: React.ReactNode;
  sdRef: React.RefObject<Graphics>;
};
export const SituationDisplayContextProvider = ({ children, sdRef }: SituationDisplayContextProviderProps) => {
  const dispatch = useRootDispatch();
  const abIsOpen = useRootSelector((state) => state.eram.toggleButtonState.AB_OPEN);
  const viewPositionMap = useRootSelector((state) => state.eram.viewPositionMap);
  const situationDisplayStoreValue = useSyncExternalStore(
    situationDisplayStore.subscribe,
    situationDisplayStore.getState,
  );

  const clipCursorToSd = useStableCallback(async () => {
    if (window.__TAURI__) {
      if (!abIsOpen) {
        if (!eramViews.every((view) => viewIsInsideAB(view, viewPositionMap[view]))) {
          dispatch(setToggleButtonValue({ buttonId: "AB_OPEN", newValue: true }));
          void WebviewWindow.getCurrent().setCursorGrab(false);
          void errorTone.tryPlay();
          return;
        }
        await clipCursorToSituationDisplay();
      } else {
        await WebviewWindow.getCurrent().setCursorGrab(false);
      }
    }
  });

  const unclipCursorFromElement = useStableCallback(() => {
    if (!abIsOpen) {
      void clipCursorToSd();
    } else if (window.__TAURI__) {
      void WebviewWindow.getCurrent().setCursorGrab(false);
    }
  });

  useEffect(() => {
    let unlisten: { fn?: UnlistenFn } = {};
    if (!abIsOpen) {
      const addFocusHandler = async () => {
        unlisten.fn = await listen(TauriEvent.WINDOW_FOCUS, clipCursorToSd);
      };
      void addFocusHandler();
    }
    void clipCursorToSd();
    return () => {
      unlisten.fn?.();
    };
  }, [abIsOpen, clipCursorToSd, dispatch]);

  const contextValue = useMemo(() => {
    return {
      sdRef,
      unclipCursorFromElement,
      ...situationDisplayStoreValue,
    };
  }, [sdRef, situationDisplayStoreValue, unclipCursorFromElement]);

  return <SituationDisplayContext.Provider value={contextValue}>{children}</SituationDisplayContext.Provider>;
};

export const useSituationDisplay = () => {
  const contextValue = React.useContext(SituationDisplayContext);
  if (contextValue === null) {
    throw new Error("useSituationDisplayContext must be used within a SituationDisplayContextProvider");
  }
  return contextValue;
};

export const useProjection = () => {
  return useSituationDisplay().projection;
};

export const useMapScale = () => {
  return useRootSelector(mapScaleSelector);
};

export const useGetSdCoordFromLonLat = () => {
  return useSituationDisplay().getSdCoordFromLonLat;
};

