import type { Action, Reducer, ThunkAction } from "@reduxjs/toolkit";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { reducer as eramStateReducer } from "~redux/slices/eramStateSlice";
import { reducer as eramTempStateReducer, eramTempStateSlice } from "~redux/slices/eramTempStateSlice";
import { reducer as viewOptionReducer, viewOptionSlice } from "~redux/slices/viewOptionSlice";
import { reducer as settingsReducer, settingsSlice } from "~redux/slices/settingsSlice";
import { injectStore } from "~/injectStore";
import { listenerMiddleware } from "~redux/listenerMiddleware";
import {
  aircraftReducer,
  messagingReducer,
  navdataApi,
  weatherReducer,
  connectionReducer,
  commonReducer,
  connectionSelector,
  isRestrictedUiAction,
  setReplayState,
  viewMenuFieldsReducer,
  viewMenuFieldsSlice,
  messagingSlice,
  commonSlice,
  connectionSlice,
  createRootReducer,
} from "@poscon/shared-frontend";
import { uiActionReducerNames } from "~/eramHubConnection";
import { whitelistedActions } from "~redux/slices/whitelistedActions";
import type { EramConnection } from "@poscon/shared-types";

const combinedReducer = combineReducers({
  [messagingSlice.name]: messagingReducer,
  tracks: aircraftReducer,
  eram: eramStateReducer,
  [eramTempStateSlice.name]: eramTempStateReducer,
  [viewOptionSlice.name]: viewOptionReducer,
  [settingsSlice.name]: settingsReducer,
  weather: weatherReducer,
  [connectionSlice.name]: connectionReducer,
  [commonSlice.name]: commonReducer,
  [viewMenuFieldsSlice.name]: viewMenuFieldsReducer,
  [navdataApi.reducerPath]: navdataApi.reducer,
});

const rootReducer: Reducer<ReturnType<typeof combinedReducer>> = createRootReducer((state, action) => {
  const connection = state ? connectionSelector(state) as EramConnection : undefined;
  if (setReplayState.match(action)) {
    return action.payload;
  }
  if (
    isRestrictedUiAction(action, uiActionReducerNames, [], whitelistedActions) &&
    !action.meta?.forwarded &&
    connection &&
    connection.sectorId &&
    connection.hasActiveRadarConnection &&
    !connection.isActive &&
    state
  ) {
    return state;
  }
  return combinedReducer(state, action);
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }).concat([
      listenerMiddleware.middleware,
      navdataApi.middleware,
    ]),
});

injectStore(store);

// Infer the `RootState` and `RootDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type RootDispatch = typeof store.dispatch;
export type RootThunkAction<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export type RootStore = typeof store;
