import React, { useState } from "react";
import { View } from "components/utils/View";
import {
  backgroundOption,
  borderOption,
  brightOption,
  fontOption,
  viewOptionSelector,
} from "~redux/slices/viewOptionSlice";
import { useRootSelector } from "~redux/hooks";
import { checkListSelector } from "~redux/slices/eramTempStateSlice";
import { BitmapText, Container, Graphics } from "@pixi/react";
import type { EramFontSize } from "@poscon/shared-frontend";
import { colorNameMap, computeColor, eramFontDimensionMap, eramFontNameMap } from "@poscon/shared-frontend";
import { ViewOptionContextProvider } from "~/contexts/viewOptionContext";
import { CheckListButtonId } from "~/types/eramButton";

const optionMapArr = Array.from({ length: 17 * 6 }, (_, i) => {
  const view = `CHKLST_${i}` as CheckListButtonId;
  return {
    background: backgroundOption(view),
    border: borderOption(view),
    font: fontOption(view),
    bright: brightOption(view),
  };
});

type ChecklistProps = {
  view: CheckListButtonId;
};
export const Checklist = ({ view }: ChecklistProps) => {
  const viewOptions = useRootSelector((state) => viewOptionSelector(state, view));
  const chkListIndex = parseInt(view.split("_").at(-1)!, 10);
  // TODO: use sectorConfig.checklists data from RPosConfig if available
  const checklist = useRootSelector((state) => checkListSelector(state, chkListIndex));
  const [hover, setHover] = useState<string | null>(null);

  const items = checklist?.chkListViewListItem ?? [];

  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const fontSize = viewOptions.font as EramFontSize;
  const fontName = eramFontNameMap[fontSize];
  const fontDimension = eramFontDimensionMap[fontName];

  const width = Math.max(29, ...items.map((s) => s ? s.length + 1 : 0));

  const toggleItem = (item: string) => {
    setSelectedItems((prev) => {
      if (prev.includes(item)) {
        return prev.filter((e) => e !== item);
      }
      return [...prev, item];
    });
  };

  const height = items.length * (fontDimension.height + 4) + 8;

  return (
    <ViewOptionContextProvider options={viewOptions}>
      <View
        width={width}
        height={height}
        view={view}
        optionMap={optionMapArr[chkListIndex - 1]!}
        title={checklist?.chkListViewTitle ?? ""}
        viewMenuTitle={checklist?.chkListViewMenuTitle ?? ""}
      >
        <Container x={6} y={4}>
          {items.map((item, index) => {
            const selected = item && selectedItems.includes(`${item}${index}`);
            const bgColor = computeColor(
              selected ? colorNameMap.grey : colorNameMap.black,
              viewOptions.bright / 100,
            );
            const tint = computeColor(selected ? colorNameMap.black : colorNameMap.grey, viewOptions.bright / 100);
            return (
              <Container key={`${item}${index}`} eventMode={item && /[\w\s]+/.test(item) ? "static" : "none"} sortableChildren y={(fontDimension.height + 4) * index}>
                <Graphics
                  zIndex={0}
                  draw={(graphics) => {
                    graphics.clear();
                    graphics.lineStyle(
                      1,
                      // eslint-disable-next-line no-nested-ternary
                      item && hover === `${item}${index}` ? 0xffffff : selected ? colorNameMap.grey : colorNameMap.black,
                    );
                    graphics
                      .beginFill(bgColor)
                      .drawRect(0, 0, (item?.length ?? 0) * fontDimension.width + 8, fontDimension.height + 2)
                      .endFill();
                  }}
                  eventMode="static"
                  onmousedown={() => item && toggleItem(`${item}${index}`)}
                  onmouseenter={() => item && setHover(`${item}${index}`)}
                  onmouseleave={() => item && setHover((prev) => (prev === `${item}${index}` ? null : prev))}
                />
                <BitmapText
                  zIndex={1}
                  text={(item ?? "").toUpperCase()}
                  eventMode="none"
                  x={4}
                  y={1}
                  tint={tint}
                  fontName={fontName}
                  style={{ fontName, tint }}
                />
              </Container>
            );
          })}
        </Container>
      </View>
    </ViewOptionContextProvider>
  );
};
