import React from "react";
import { View } from "components/utils/View";
import { viewOptionSelector } from "~redux/slices/viewOptionSlice";
import { CpdlcAdv as BaseCpdlcAdv, cpdlcAdvView } from "@poscon/shared-frontend";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { ViewOptionContextProvider } from "contexts/viewOptionContext";
import { setViewPosition, viewPositionSelector } from "~redux/slices/eramStateSlice";

const view = cpdlcAdvView;

export const CpdlcAdv = () => {
  const dispatch = useRootDispatch();
  const viewOptions = useRootSelector((state) => viewOptionSelector(state, view));
  const pos = useRootSelector((state) => viewPositionSelector(state, view));

  return (
    <ViewOptionContextProvider options={viewOptions}>
      <BaseCpdlcAdv
        ViewComponent={View}
        pos={pos}
        setViewPosition={(view, pos) => dispatch(setViewPosition({ view, pos }))}
      />
    </ViewOptionContextProvider>
  );
};
