import type { ColorSource, Graphics as PixiGraphics } from "pixi.js";
import {
  GeomapLineFeature,
  GeomapLineProperties,
  isGeomapLineFeature,
  isGeomapPointFeature,
  isGeomapSymbolFeature,
  isGeomapTextFeature,
  type Coordinate,
  type DefaultSymbolProperties,
  type DefaultTextProperties,
  type GeomapConfig,
  type GeomapFilterButtonPosition,
  type GeomapSymbolFeature,
  type GeomapTextFeature,
  type MapObjectType,
} from "@poscon/shared-types";
import type { BrightIndex } from "~redux/slices/eramStateSlice";
import { brightValuesSelector, toggleButtonValueSelector } from "~redux/slices/eramStateSlice";
import { useRootSelector } from "~redux/hooks";
import React, { useMemo } from "react";
import { along, distance, featureCollection, lineString, multiLineString, point } from "@turf/turf";
import { geoPath } from "d3-geo";
import { useMapScale, useProjection } from "contexts/sdContext";
import { geomapSymbolMap } from "~/mapSymbols";
import { BitmapText, Container, Graphics } from "@pixi/react";
import {
  colorNameMap,
  computeColor,
  eramFontNameMap,
  eramSymbolFontNameMap,
  useBCGs,
  useFilterMenuButton,
  useGeomaps,
} from "@poscon/shared-frontend";
import { defaultLineStyle } from "~/utils/defaultLineStyle";
import { Feature, FeatureCollection, Geometry, LineString, MultiLineString, Point } from "geojson";

export const geomapTextFontName = eramFontNameMap[1];

const roundedPoint = (point: Coordinate) => [Math.round(point[0]), Math.round(point[1])] as const;

export type GeomapGeometryContainerProps = {
  featureCollection: FeatureCollection<Geometry, GeomapLineProperties>;
  bcg?: number;
  alpha?: number;
  tint?: Uint8Array;
};
export const GeomapGeometryContainer = ({
  featureCollection,
  bcg,
  alpha,
  tint = colorNameMap.white,
}: GeomapGeometryContainerProps) => {
  const projection = useProjection();
  const brightnessValues = useRootSelector(brightValuesSelector);
  const bcgs = useBCGs();

  const draw = (graphics: PixiGraphics) => {
    graphics.clear();
    graphics.lineTextureStyle();

    const pathGen = geoPath(projection).context(graphics as any);
    for (const feature of featureCollection.features) {
      const actualBcg = feature.properties?.bcg ?? bcg;
      if (!alpha && !actualBcg) {
        continue;
      }
      const _alpha = alpha ??
        brightnessValues[
        `BCG_${bcgs.find((b) => b.bcgs.includes(actualBcg!))!.menuPosition as BrightIndex}`
        ] / 100;
      graphics.lineStyle({
        ...defaultLineStyle,
        color: computeColor(tint, _alpha),
      });
      graphics.zIndex = Math.max(_alpha * 100, graphics.zIndex);
      pathGen(feature.geometry);
    }
  };

  return <Graphics draw={draw} />;
};

export type GeomapTextContainerProps = {
  features: GeomapTextFeature[];
  defaultTextProperties?: DefaultTextProperties;
  bcg?: number;
  alpha?: number;
  baseTint?: ColorSource;
};
export const GeomapTextContainer = ({
  features,
  defaultTextProperties,
  bcg,
  alpha,
  baseTint = colorNameMap.white,
}: GeomapTextContainerProps) => {
  const mapScale = useMapScale();
  const projection = useProjection();
  const brightnessValues = useRootSelector(brightValuesSelector);
  const bcgs = useBCGs();

  return (
    <>
      {features.map(({ geometry, properties }, i) => {
        const coord = geometry.coordinates as Coordinate;
        const p = roundedPoint(projection(coord)!);

        const actualBcg = properties.bcg ?? bcg;
        if (!alpha && !actualBcg) {
          return null;
        }

        const _alpha = alpha ??
          brightnessValues[
          `BCG_${bcgs.find((b) => b.bcgs.includes(actualBcg!))!.menuPosition as BrightIndex}`
          ] / 100;
        const tint = computeColor(baseTint, _alpha);

        return <BitmapText
          key={`${properties.textObjectId}-${i}Text`}
          name={`${properties.textObjectId}-${i}Text`}
          x={p[0] + (properties.xOffset ?? defaultTextProperties?.xOffset ?? 0) / mapScale}
          y={p[1] + (properties.yOffset ?? defaultTextProperties?.yOffset ?? 0) / mapScale}
          text={properties.text.join("\n").toUpperCase()}
          tint={tint}
          style={{ tint, fontName: geomapTextFontName }}
          scale={1 / mapScale}
        />;
      })}
    </>
  );
};

type GeomapSymbolContainerProps = {
  features: GeomapSymbolFeature[];
  defaultSymbolProperties?: DefaultSymbolProperties;
  defaultTextProperties?: DefaultTextProperties;
  bcg?: number;
  alpha?: number;
};
const GeomapSymbolContainer = ({
  features,
  defaultSymbolProperties,
  defaultTextProperties,
  bcg,
  alpha,
}: GeomapSymbolContainerProps) => {
  const mapScale = useMapScale();
  const projection = useProjection();
  const brightnessValues = useRootSelector(brightValuesSelector);
  const bcgs = useBCGs();

  const fontName = eramFontNameMap[1];
  const symbolFontName = eramSymbolFontNameMap[1];

  return (
    <>
      {features.map(({ geometry, properties }, i) => {
        const coord = geometry.coordinates as Coordinate;
        const p = roundedPoint(projection(coord)!);
        const style = properties.style ?? defaultSymbolProperties?.style;

        const actualBcg = properties.bcg ?? bcg;
        if (!style || (!alpha && !actualBcg)) {
          return null;
        }

        const _alpha = alpha ??
          brightnessValues[
          `BCG_${bcgs.find((b) => b.bcgs.includes(actualBcg!))!.menuPosition as BrightIndex}`
          ] / 100;
        const tint = computeColor(colorNameMap.white, _alpha);
        return <Container key={`${properties.symbolId}-${i}Symbol`} x={p[0]} y={p[1]}>
          <BitmapText
            name={`${properties.symbolId}-${i}SymbolText`}
            text={geomapSymbolMap[style]}
            tint={tint}
            style={{ tint, fontName: symbolFontName }}
            scale={1 / mapScale}
          />
          {properties.text?.length && defaultTextProperties && (
            <BitmapText
              x={(properties.xOffset ?? defaultTextProperties.xOffset) / mapScale}
              y={(properties.yOffset ?? defaultTextProperties.yOffset) / mapScale}
              name={`${properties.symbolId}-${i}Symbol`}
              text={properties.text.join("\n").toUpperCase()}
              tint={tint}
              style={{ tint, fontName }}
              scale={1 / mapScale}
            />
          )}
        </Container>;
      })}
    </>
  );
};

export const geomapLineShortSpacing = 1;
export const geomapLineLongSpacing = 2;

type MapGroup = {
  mapGroupId?: number;
  mapObjectType?: MapObjectType;
  bcg?: number;
  defaultSymbolProperties?: DefaultSymbolProperties;
  defaultTextProperties?: DefaultTextProperties;
  lineFeatures?: FeatureCollection<MultiLineString | LineString, GeomapLineProperties>;
  textFeatures?: GeomapTextFeature[];
  symbolFeatures?: GeomapSymbolFeature[];
};

type MapGroupContainerProps = { group: MapGroup; };
const MapGroupContainer = ({ group }: MapGroupContainerProps) => {
  const { defaultTextProperties, defaultSymbolProperties } = group;
  return (
    <>
      {group.lineFeatures && <GeomapGeometryContainer featureCollection={group.lineFeatures} bcg={group.bcg} />}
      {group.textFeatures && <GeomapTextContainer features={group.textFeatures} defaultTextProperties={defaultTextProperties} bcg={group.bcg} />}
      {group.symbolFeatures && <GeomapSymbolContainer features={group.symbolFeatures} defaultSymbolProperties={defaultSymbolProperties} defaultTextProperties={defaultTextProperties} bcg={group.bcg} />}
    </>
  );
};

const emptyArray: never[] = [];

type GeomapProps = {
  filterGroup: GeomapFilterButtonPosition;
  geomapConfig: GeomapConfig;
};
export const GeoMap = ({ geomapConfig, filterGroup }: GeomapProps) => {
  const active = useRootSelector((state) => toggleButtonValueSelector(state, `MAP_${filterGroup}`));
  const filterMenuButton = useFilterMenuButton(filterGroup);
  const filterGroups = filterMenuButton?.filterGroups ?? emptyArray;
  const geomaps = useGeomaps(geomapConfig.geomapId, filterGroups);

  const mapGroups = useMemo(() => {
    const newGroups: MapGroup[] = [];
    for (const map of geomaps) {
      const { defaultLineProperties, defaultTextProperties, defaultSymbolProperties } = map.properties;
      const lineBcg = defaultLineProperties?.bcg;
      const lineFeatures: GeomapLineFeature[] = [];
      const textFeatures: GeomapTextFeature[] = [];
      const symbolFeatures: GeomapSymbolFeature[] = [];
      for (const f of map.features) {
        if (isGeomapPointFeature(f) && (f.properties.filterGroups ?? defaultLineProperties?.filterGroups ?? []).some((b) => filterGroups.includes(b))) {
          symbolFeatures.push(point(f.geometry.coordinates, {
            symbolId: "",
            style: "OtherWaypoints",
          }));
        }
        if (isGeomapLineFeature(f) && (f.properties.filterGroups ?? defaultLineProperties?.filterGroups ?? []).some((b) => filterGroups.includes(b))) {
          const lineStyle = defaultLineProperties?.style ?? f.properties.style ?? "Solid";
          if (lineStyle !== "Solid") {
            const spacing = lineStyle === "LongDashed" ? geomapLineLongSpacing : geomapLineShortSpacing;
            const start = f.geometry.coordinates[0] as Coordinate;
            const end = f.geometry.coordinates[1] as Coordinate;
            const dist = distance(start, end, {
              units: "nauticalmiles",
            });
            const lineStr = lineString([start, end]);
            const newLines: number[][][] = [];
            let acc = 0;
            let j = 0;
            while (acc < dist) {
              const start = along(lineStr, acc, {
                units: "nauticalmiles",
              });
              let end: Feature<Point>;
              if (j % 2 === 0 && lineStyle === "LongDashShortDash") {
                end = along(lineStr, acc + geomapLineLongSpacing, {
                  units: "nauticalmiles",
                });
                acc += geomapLineLongSpacing + spacing;
              } else {
                end = along(lineStr, acc + spacing, {
                  units: "nauticalmiles",
                });
                acc += spacing * 2;
              }
              newLines.push([start.geometry.coordinates, end.geometry.coordinates]);
              j += 1;
            }
            lineFeatures.push(multiLineString(newLines, f.properties));
          } else {
            lineFeatures.push(f);
          }
        }
        if (isGeomapTextFeature(f) && (f.properties.filterGroups ?? defaultTextProperties?.filterGroups ?? []).some((b) => filterGroups.includes(b))) {
          textFeatures.push(f);
        }
        if (isGeomapSymbolFeature(f) && (f.properties.filterGroups ?? defaultSymbolProperties?.filterGroups ?? []).some((b) => filterGroups.includes(b))) {
          symbolFeatures.push(f)
        }
      }
      if (lineFeatures.length > 0) {
        newGroups.push({
          mapGroupId: map.properties.mapGroupId,
          mapObjectType: map.properties.mapObjectType,
          bcg: lineBcg ?? 1,
          lineFeatures: featureCollection(lineFeatures),
        });
      }

      const symbolBcg = defaultSymbolProperties?.bcg;
      if (symbolFeatures.length > 0) {
        newGroups.push({
          mapGroupId: map.properties.mapGroupId,
          mapObjectType: map.properties.mapObjectType,
          bcg: symbolBcg,
          symbolFeatures,
          defaultSymbolProperties: defaultSymbolProperties,
          defaultTextProperties: defaultTextProperties,
        });
      }
      // TODO: what determines text brightness?
      if (textFeatures.length > 0) {
        newGroups.push({
          mapGroupId: map.properties.mapGroupId,
          mapObjectType: map.properties.mapObjectType,
          bcg: symbolBcg ?? lineBcg ?? 1,
          textFeatures,
          defaultTextProperties: defaultTextProperties,
        });
      }
    }
    return newGroups;
  }, [filterGroups, geomaps]);

  return active ? (
    <>
      {mapGroups.map((group, i) => {
        return <MapGroupContainer key={i} group={group} />;
      })}
    </>
  ) : null;
};
