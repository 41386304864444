import type { SymbolStyle } from "@poscon/shared-types";

export const geomapSymbolMap: Record<SymbolStyle, string> = {
  Airport: "\u040d",
  AirwayIntersections: "\u0409",
  EmergencyAirport: "\u0404",
  Heliport: "\u0402",
  IAF: "\u0405",
  NDB: "\u040c",
  Nuclear: "\u0403",
  Obstruction1: "\u0401",
  Obstruction2: "\u0401",
  OtherWaypoints: "\u040c",
  RNAV: "\u0408",
  RNAVOnlyWaypoint: "\u0407",
  Radar: "\u0405",
  SatelliteAirport: "\u040e",
  TACAN: "\u040f",
  DME: "\u040b",
  VOR: "\u040b",
} as const;

export const targetSymbolMap = {
  correlatedBeacon: "\u0814",
  correlatedPrimary: "\u0812",
  identingBeacon: "\u0816",
  modeCIntruder: "\u0817",
  reducedSeparation: "\u0815",
  uncorrelatedBeacon: "\u0813",
  uncorrelatedPrimary: "\u0810",
  vfrBeacon: "\u0818",
} as const;

export const positionSymbolMap = {
  flatTrack: "\u0800",
  coastTrack: "\u0802",
  freeTrack: "\u0801",
  frozenDatablock: "\u0803",
  hold: "\u0804",
} as const;
