import React, { useRef } from "react";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { toggleUhdDatablocks, uhdDatablocksSelector } from "~redux/slices/settingsSlice";
import { eramTextDimensionMap, MovableMenu } from "@poscon/shared-frontend";
import { setToggleButtonValue, viewPositionSelector } from "~redux/slices/eramStateSlice";
import type { Container as PixiContainer } from "pixi.js";
import { layerZIndexMap } from "~/layerZIndexMap";
import { useDragging } from "hooks/useDragging";

const view = "SETTINGS_MENU";

const { width: fontWidth, height: fontHeight } = eramTextDimensionMap[2];

type ToggleButtonProps = { label: string; value: boolean; onChange: (value: boolean) => void };

const ToggleButton = ({ label, value, onChange }: ToggleButtonProps) => {
  return null;
  // return (
  //   <div className={settingsStyles.row}>
  //     {label}
  //     <input
  //       type="checkbox"
  //       className={movableMenuStyles.toggleButton}
  //       checked={value}
  //       onmousedown={() => onChange(!value)}
  //     />
  //   </div>
  // );
};

export const SettingsMenu = () => {
  const ref = useRef<PixiContainer>(null);
  const dispatch = useRootDispatch();
  const uhdDbs = useRootSelector(uhdDatablocksSelector);
  const pos = useRootSelector((state) => viewPositionSelector(state, view));
  const { startDrag } = useDragging(ref, view);

  const height = (fontHeight + 2) * 4;

  return (
    <MovableMenu
      ref={ref}
      menuName="SETTINGS_MENU"
      title="SETTINGS MENU"
      onClose={() => dispatch(setToggleButtonValue({ buttonId: "SETTINGS_MENU", newValue: false }))}
      width={fontWidth * 16 + 2}
      height={height}
      zIndex={layerZIndexMap.dbMenus}
      pos={pos}
      startDrag={startDrag}
    >
      <ToggleButton label="UHD DATABLOCKS" value={uhdDbs} onChange={() => dispatch(toggleUhdDatablocks())} />
    </MovableMenu>
  );
};
