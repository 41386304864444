import React, { useEffect, useRef } from "react";
import type { EramButtonProps } from "components/buttons/EramButton";
import { EramBaseButton } from "components/buttons/EramButton";
import { stringToTokenArray } from "@poscon/shared-types";
import { ButtonMenuContainer } from "components/buttons/ButtonMenuContainer";
import {
  colorNameMap,
  computeColor,
  connectionSelector,
  dispatchInsertCommandEvent,
  eramFontNameMap,
  eramTextDimensionMap,
  useFocused,
  useUiIsLocked,
} from "@poscon/shared-frontend";
import { useBrightContext } from "contexts/brightnessContext";
import { BitmapText, Container, Graphics } from "@pixi/react";
import type { ColorSource, Graphics as PixiGraphics } from "pixi.js";
import { baseToggleButtonSelectedColor } from "components/buttons/EramToggleButton";
import { dispatchInsertPrefsetNameEvent } from "~/customEvents";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import {
  menuButtonPathsSelector,
  setToggleButtonValue,
} from "~redux/slices/eramStateSlice";
import { prefsetsSelector } from "~/redux/slices/eramTempStateSlice";
import { eramButtonActionMap } from "~redux/thunks/eramButtonActionMap";

const fontName = eramFontNameMap[1];
const fontDimension = eramTextDimensionMap[1];
const width = 11 * fontDimension.width + 2;

type PrefsetMenuRowProps = {
  text: string;
  onmousedown: () => void;
  y: number;
  fillColor?: ColorSource;
};
const PrefsetMenuRow = ({
  text,
  onmousedown,
  y,
  fillColor: _fillColor,
}: PrefsetMenuRowProps) => {
  const ref = useRef<PixiGraphics>(null);
  const focused = useFocused(ref);
  const { tint, fillColor, borderTint, buttonBright } = useBrightContext();

  return (
    <Container y={y} eventMode="static" zIndex={focused ? 1 : 0}>
      <Graphics
        ref={ref}
        draw={(graphics) => {
          graphics.clear();
          graphics.lineStyle(1, focused ? 0xf0f0f0 : borderTint);
          graphics.beginFill(
            _fillColor ? computeColor(_fillColor, buttonBright) : fillColor,
          );
          graphics.drawRect(
            0,
            0,
            11 * fontDimension.width,
            fontDimension.height + 2,
          );
        }}
        zIndex={0}
        eventMode="static"
        onmousedown={onmousedown}
      />
      <BitmapText
        text={text}
        fontName={fontName}
        style={{ fontName, tint }}
        x={2}
        y={2}
        zIndex={1}
        eventMode="none"
      />
    </Container>
  );
};

const PrefSetMenu = () => {
  const dispatch = useRootDispatch();
  const { fillColor } = useBrightContext();
  const prefsets = useRootSelector(prefsetsSelector);

  return (
    <ButtonMenuContainer
      buttonId="PREFSET"
      width={width}
      height={(2 + prefsets.length) * (fontDimension.height + 2) + 2}
      fillColor={fillColor}
    >
      <PrefsetMenuRow
        text="SAVE"
        onmousedown={() => {
          dispatchInsertCommandEvent(stringToTokenArray("US"));
        }}
        y={0}
        fillColor={colorNameMap.cyan}
      />
      {prefsets.map((prefsetName, index) => (
        <PrefsetMenuRow
          key={prefsetName}
          text={prefsetName}
          onmousedown={() => {
            dispatchInsertPrefsetNameEvent(prefsetName);
            dispatch(
              setToggleButtonValue({ buttonId: "PREFSET", newValue: false }),
            );
          }}
          y={(1 + index) * (fontDimension.height + 2)}
        />
      ))}
      <PrefsetMenuRow
        text="DELETE"
        onmousedown={() => {
          dispatchInsertCommandEvent(stringToTokenArray("UD"));
        }}
        y={(1 + prefsets.length) * (fontDimension.height + 2)}
        fillColor={colorNameMap.cyan}
      />
    </ButtonMenuContainer>
  );
};

export const EramPrefSetButton = (props: EramButtonProps) => {
  const uiIsLocked = useUiIsLocked();
  const dispatch = useRootDispatch();
  const selected = useRootSelector((state) =>
    eramButtonActionMap[props.buttonId]?.selected?.(state, props.path),
  );
  const buttonAction = eramButtonActionMap[props.buttonId]?.action;
  const onmousedown = () => {
    if (!uiIsLocked && buttonAction) {
      dispatch(buttonAction(props.path));
    }
  };
  const selectedPaths = useRootSelector(menuButtonPathsSelector);

  const menuHidden =
    (props.path.includes("MASTER_TOOLBAR") &&
      selectedPaths.some(
        (p) => p.startsWith("MASTER_TOOLBAR") && !p.startsWith(props.path)
      ) &&
      props.hidden === undefined) || props.hidden;

  useEffect(() => {
    if (menuHidden) {
      dispatch(setToggleButtonValue({ buttonId: "PREFSET", newValue: false }));
    }
  }, [menuHidden, dispatch]);

  return (
    <EramBaseButton
      {...props}
      zIndex={selected ? 10 : 0}
      onmousedown={onmousedown}
      baseBgColor={
        selected ? baseToggleButtonSelectedColor : colorNameMap.black
      }
    >
      {selected && <PrefSetMenu />}
    </EramBaseButton>
  );
};
