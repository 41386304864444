import React from "react";
import { useButtonContext } from "contexts/buttonContext";
import { Graphics } from "@pixi/react";

export const Cross = ({ width: _width, height: _height }: { width?: number; height?: number }) => {
  const { buttonWidth, buttonHeight } = useButtonContext();
  const width = _width ?? buttonWidth;
  const height = _height ?? buttonHeight;

  return (
    <Graphics
      eventMode="none"
      zIndex={10}
      draw={(graphics) => {
        graphics.clear();
        graphics.lineStyle(1, 0xffffff);
        graphics.moveTo(0, 0);
        graphics.lineTo(width, height);
        graphics.moveTo(width, 0);
        graphics.lineTo(0, height);
      }}
    />
  );
};
