import React from "react";
import { View } from "components/utils/View";
import {
  backgroundOption,
  borderOption,
  brightOption,
  fontOption,
  linesOption,
  toggleOption,
} from "~redux/slices/viewOptionSlice";

const view = "DEPT_LIST";

const optionMap = {
  background: backgroundOption(view),
  border: borderOption(view),
  tearoff: toggleOption(view, "tearoff", "TEAROFF", 7),
  lines: linesOption(view, 5, 25),
  font: fontOption(view),
  bright: brightOption(view),
  // sort: {
  //   textSelector: () => "SORT",
  // },
};

export const DeptList = () => {
  return <View width={20} height={0} view={view} optionMap={optionMap} />;
};
