import type { ColorName } from "@poscon/shared-types";

export const drawColorMap: Record<ColorName, Uint8Array> = {
  green: new Uint8Array([0x00, 0xaa, 0x00]),
  red: new Uint8Array([0xaa, 0x77, 0x6e]),
  grey: new Uint8Array([0xaa, 0xaa, 0xa9]),
  yellow: new Uint8Array([0xa0, 0xaa, 0x4b]),
};

export const getDrawColor = (color: ColorName, bright: number) =>
  [...drawColorMap[color]].map((c) => (c * (bright / 100)) / 255);
