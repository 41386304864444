import React from "react";
import type { ViewHeaderProps } from "@poscon/shared-frontend";
import { colorNameMap, computeColor, ViewHeader as BaseViewHeader } from "@poscon/shared-frontend";
import { useBrightContext } from "contexts/brightnessContext";

export const ViewHeader = ({ ...props }: ViewHeaderProps) => {
  const { fillColor, tint, borderTint, textBright } = useBrightContext();

  return (
    <BaseViewHeader
      {...props}
      opaqueColor={fillColor}
      borderTint={borderTint}
      tint={tint}
      textTint={computeColor(colorNameMap.grey, textBright)}
    />
  );
};
