import React from "react";
import { View } from "components/utils/View";
import { useRootSelector } from "~redux/hooks";
import { ViewOptionContextProvider, useInputProps, CpdlcTocSet as BaseCpdlcTocSet } from "@poscon/shared-frontend";
import { viewOptionSelector } from "~/redux/slices/viewOptionSlice";

const view = "CPDLC_TOC_SET";

export const CpdlcTocSet = () => {
  const viewOptions = useRootSelector((state) => viewOptionSelector(state, view));

  return (
    <ViewOptionContextProvider options={viewOptions}>
      <BaseCpdlcTocSet ViewComponent={View} />
    </ViewOptionContextProvider>
  );
};

type TocSetMenuProps = {
  category: "CONTACT" | "MONITOR";
};
const TocSetMenu = ({ category }: TocSetMenuProps) => {
  const { ...inputProps } = useInputProps(`${view}/header/category/${category}`, "", (s) => {
    // TODO: implement
  });
  return null;
  // return (
  //   <div className={className}>
  //     <div className={tocSetStyles.menuHeader}>
  //       <div className={tocSetStyles.radioButton} />
  //       {category}
  //     </div>
  //     <div className={tocSetStyles.table}>
  //       <div className={tocSetStyles.tableHeaderRow}>EXCEPT SECTOR:</div>
  //       <div className={tocSetStyles.tableHeaderRow}>
  //         <EramInput className={tocSetStyles.input} {...inputProps} />
  //         <div className={clsx(tocSetStyles.addButton, inputProps.value && tocSetStyles.disabledButton)}>ADD</div>
  //       </div>
  //     </div>
  //   </div>
  // );
};
