import type { PayloadAction } from "@reduxjs/toolkit";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState, RootThunkAction } from "~redux/store";
import type {
  BrightButtonId,
  ButtonPath,
  CursorButtonId,
  EramButtonId,
  FontButtonId,
  ToggleButtonId,
  ViewButtonId,
} from "types/eramButton";
import {
  bcgButtonList,
  brightButtonList,
  buttonList,
  cpdlcBrightButtonList,
  fontButtonList,
  toggleButtonList,
  viewButtons,
} from "types/eramButton";
import type { EramFontSize, WindowPosition } from "@poscon/shared-frontend";
import { setAltimeterList, setMetarList, errorTone } from "@poscon/shared-frontend";
import type {
  AltimeterListEntry,
  AltitudeLimits,
  ColorName,
  Coordinate,
  CrrGroup,
  EramMessageElement,
  GeomapFilterButtonPosition,
  LeaderLength,
  MsgOutFilterValueMap,
  Nullable,
  TrackId,
} from "@poscon/shared-types";
import { includes, initialMsgOutFilterMap } from "@poscon/shared-types";
import type { EramView } from "types/eramView";
import { eramViews } from "types/eramView";
import { defaultNexradDisplayItem } from "~/eramConstants";

export type BrightIndex = GeomapFilterButtonPosition;

export const defaultViewPositions: Partial<Record<EramView, WindowPosition>> = {
  STATUS: { x: 400, y: 100 },
  OUTAGE: { x: 400, y: 100 },
  MESSAGE_COMPOSE_AREA: { x: 100, y: 400 },
  MESSAGE_RESPONSE_AREA: { x: 100, y: 300 },
};

const initialViewPositionMap = Object.fromEntries(
  eramViews.map((value) => [value, defaultViewPositions[value] ?? { x: 100, y: 100 }]),
) as Record<EramView, WindowPosition>;

type ToggleButtonStateMap = Record<ToggleButtonId, boolean>;

export type MovableToolbarId = "MASTER_TOOLBAR" | "MCA_TOOLBAR" | "HORIZ_TOOLBAR";
export type ToolbarId = MovableToolbarId | "LEFT_TOOLBAR" | "RIGHT_TOOLBAR";

export type ToolbarButtonPosition = {
  toolbarId: ToolbarId;
  buttonId: EramButtonId | "MACRO_BUTTON";
  macroLabel?: string;
  row: number;
  col: number;
};

export type MacroButton = {
  label: string;
  commands: EramMessageElement[][];
  position: WindowPosition | null;
};

export const _drawItemTypes = ["CIRCLE", "LINE", "RECT", "TEXT"] as const;
export const drawItemTypes = [..._drawItemTypes] as string[];
export type DrawItemType = (typeof _drawItemTypes)[number];
export type DrawCircle = {
  itemType: "CIRCLE";
  editMode?: "MOVE_CENTER" | "RESIZE";
  radius: number;
  color: ColorName;
  bright: number;
};
export type DrawLine = {
  itemType: "LINE";
  editMode?: "ADD_POINT";
  coordinates: Coordinate[];
  color: ColorName;
  bright: number;
};
export type DrawRect = {
  itemType: "RECT";
  editMode?: "MOVE_ORIGIN" | "MOVE_CORNER";
  bottomRight: Coordinate | null;
  color: ColorName;
  bright: number;
};
export type DrawText = {
  itemType: "TEXT";
  editMode?: "MOVE" | "EDIT_TEXT";
  fontSize: EramFontSize;
  text: string;
  color: ColorName;
  bright: number;
};
export type DrawElement = DrawCircle | DrawLine | DrawRect | DrawText;
export type DrawItem<T extends DrawItemType = DrawItemType> = {
  // map or situation display
  anchor: "MAP" | "DISP";
  id: string;
  origin: Coordinate;
  element: DrawElement & { itemType: T };
};

export type EramState = {
  abWidth: number;
  altimTearoffPositionMap: Record<string, WindowPosition>;
  altitudeLimits: AltitudeLimits;
  brightness: Record<BrightButtonId, number>;
  cursor: Record<CursorButtonId, number>;
  drawCircleRadius: 2 | 3 | 4 | 5 | 6 | 7 | 8;
  drawColorBright: number;
  drawFontSize: 1 | 2 | 3;
  drawItems: DrawItem[];
  fdbLdr: LeaderLength;
  font: Record<Exclude<FontButtonId, "LINE_4_FONT">, EramFontSize> & { LINE_4_FONT: 0 | -1 | -2 };
  history: number;
  macroButtonMap: Record<string, MacroButton>;
  selectedMenuButtonPaths: ButtonPath[];
  metarTearoffPositionMap: Record<string, WindowPosition>;
  msgOutFilterMap: MsgOutFilterValueMap;
  nexradAltLabelIndex: number;
  nexradLevel: 0 | 1 | 2 | 3;
  tearOffButtonPositionMap: Record<EramButtonId, WindowPosition | null>;
  toggleButtonState: ToggleButtonStateMap;
  toolbarButtonPositions: ToolbarButtonPosition[];
  toolbarPositions: [MovableToolbarId[], MovableToolbarId[]];
  vectorLength: number;
  viewPositionMap: Record<EramView, WindowPosition>;
  zStack: EramView[];
  crrGroups: CrrGroup[];
  drawColor: ColorName;
};

const initialState: EramState = {
  abWidth: 1,
  altimTearoffPositionMap: {},
  altitudeLimits: { limits: { min: 0, max: 999 } },
  brightness: Object.fromEntries(
    [...bcgButtonList.flat(), ...cpdlcBrightButtonList.flat(), ...brightButtonList.flat()].map((button) => {
      if ((cpdlcBrightButtonList.flat() as EramButtonId[]).includes(button)) {
        return [button, 80];
      }
      switch (button) {
        case "SLDB_BRIGHT":
        case "LDB_BRIGHT":
        case "DWELL_BRIGHT":
          return [button, 20];
        case "LINE_4_BRIGHT":
          return [button, -20];
        case "NEXRAD_BRIGHT":
        case "BCKGRD_BRIGHT":
          return [button, 10];
        case "UNP_TGT_BRIGHT":
          return [button, 35];
        case "BUTTON_BRIGHT":
          return [button, 50];
        case "TEXT_BRIGHT":
        case "SATCOMM_BRIGHT":
          return [button, 70];
        case "AB_BRDR_BRIGHT":
        case "TB_BRDR_BRIGHT":
        case "PORTAL_BRIGHT":
          return [button, 0];
        case "PR_TGT_BRIGHT":
        case "FDB_BRIGHT":
        case "ON_FREQ_BRIGHT":
          return [button, 80];
        default:
          return [button, 10];
      }
    }),
  ) as Record<BrightButtonId, number>,
  cursor: { CURSOR_SPEED: 2, CURSOR_SIZE: 1, CURSOR_VOLUME: 1 },
  drawCircleRadius: 5,
  drawColorBright: 90,
  drawFontSize: 2,
  drawItems: [],
  fdbLdr: 1,
  font: Object.fromEntries(
    [...fontButtonList.flat(), "STATUS", "OUTAGE"].map((button) => {
      if (button === "LINE_4_FONT") {
        return [button, -1];
      }
      return [button, 1];
    }),
  ) as EramState["font"],
  history: 0,
  macroButtonMap: {},
  metarTearoffPositionMap: {},
  msgOutFilterMap: initialMsgOutFilterMap,
  nexradAltLabelIndex: 0,
  nexradLevel: 1,
  selectedMenuButtonPaths: [],
  tearOffButtonPositionMap: Object.fromEntries(
    buttonList.map((key) => {
      switch (key) {
        case "TOOLBAR":
          return [key, { x: 10, y: 200 }];
        case "STATUS":
          return [key, { x: 1000, y: 70 }];
        case "OUTAGE":
          return [key, { x: 1100, y: 70 }];
        case "VECTOR":
          return [key, { x: 1200, y: 70 }];
        case "RECORD":
          return [key, { x: 1300, y: 70 }];
        default:
          return [key, null];
      }
    }),
  ) as Record<EramButtonId, WindowPosition | null>,
  toggleButtonState: Object.fromEntries(
    toggleButtonList.map((button) => {
      switch (button) {
        case "MAP_1":
        case "MAP_11":
        case "CRR_RDB":
        case "MASTER_TOOLBAR":
        case "AB_OPEN":
        case "DEST":
          return [button, true];
        default:
          return [button, false];
      }
    }),
  ) as ToggleButtonStateMap,
  toolbarButtonPositions: [],
  toolbarPositions: [["MASTER_TOOLBAR", "HORIZ_TOOLBAR"], ["MCA_TOOLBAR"]],
  vectorLength: 0,
  viewPositionMap: initialViewPositionMap,
  zStack: [],
  crrGroups: [],
  drawColor: "yellow",
};

export const eramStateSlice = createSlice({
  name: "eram",
  initialState,
  reducers: {
    resetEramState() {
      return initialState;
    },
    setViewPosition(state, action: PayloadAction<{ view: EramView; pos: WindowPosition }>) {
      state.viewPositionMap[action.payload.view] = {
        x: Math.round(action.payload.pos.x),
        y: Math.round(action.payload.pos.y),
      };
    },
    pushZStack(state, action: PayloadAction<EramView>) {
      state.zStack = [...state.zStack.filter((view) => view !== action.payload), action.payload];
    },
    setTearOffButtonPosition(
      state,
      action: PayloadAction<{
        button: EramButtonId | "MACRO_BUTTON";
        position: WindowPosition;
        macroLabel?: Nullable<string>;
      }>,
    ) {
      const pos = {
        x: Math.round(action.payload.position.x),
        y: Math.round(action.payload.position.y),
      };
      if (action.payload.button === "MACRO_BUTTON") {
        if (action.payload.macroLabel) {
          state.macroButtonMap[action.payload.macroLabel]!.position = pos;
        }
        return;
      }
      state.tearOffButtonPositionMap[action.payload.button] = pos;
    },
    delTearOffButton(
      state,
      action: PayloadAction<{ buttonId: EramButtonId | "MACRO_BUTTON"; macroLabel?: string }>,
    ) {
      if (action.payload.buttonId === "MACRO_BUTTON") {
        if (action.payload.macroLabel) {
          state.macroButtonMap[action.payload.macroLabel]!.position = null;
        }
        return;
      }
      state.tearOffButtonPositionMap[action.payload.buttonId] = null;
    },
    setVectorLength(state, action: PayloadAction<number>) {
      state.vectorLength = action.payload;
    },
    setHistory(state, action: PayloadAction<number>) {
      state.history = action.payload;
    },
    setFdbLdr(state, action: PayloadAction<LeaderLength>) {
      state.fdbLdr = action.payload;
    },
    setAbWidth(state, action: PayloadAction<number>) {
      state.abWidth = action.payload;
    },
    setNexradAltLabelIndex(state, action: PayloadAction<number>) {
      state.nexradAltLabelIndex = action.payload;
    },
    setNexradLevel(state, action: PayloadAction<EramState["nexradLevel"]>) {
      state.nexradLevel = action.payload;
    },
    setDrawCircleRadius(state, action: PayloadAction<EramState["drawCircleRadius"]>) {
      state.drawCircleRadius = action.payload;
    },
    setDrawFontSize(state, action: PayloadAction<EramState["drawFontSize"]>) {
      state.drawFontSize = action.payload;
    },
    setDrawColorBright(state, action: PayloadAction<number>) {
      state.drawColorBright = action.payload;
    },
    toggleButton(state, action: PayloadAction<ToggleButtonId>) {
      const buttonId = action.payload;
      const newValue = !state.toggleButtonState[buttonId];
      if (buttonId === "DEST" || buttonId === "TYPE") {
        state.toggleButtonState.DEST = false;
        state.toggleButtonState.TYPE = false;
      }
      state.toggleButtonState[buttonId] = newValue;
    },
    setToggleButtonValue(state, action: PayloadAction<{ buttonId: ToggleButtonId; newValue: boolean }>) {
      const { buttonId, newValue } = action.payload;
      if (buttonId === "DEST" || buttonId === "TYPE") {
        state.toggleButtonState.DEST = false;
        state.toggleButtonState.TYPE = false;
      }
      state.toggleButtonState[buttonId] = newValue;
    },
    toggleMenuButtonPathValue(state, action: PayloadAction<ButtonPath>) {
      const paths = state.selectedMenuButtonPaths;
      const index = paths.indexOf(action.payload);
      if (index === -1) {
        paths.push(action.payload);
      } else {
        paths.splice(index, 1);
      }
    },
    removeMenuButtonPath(state, action: PayloadAction<ButtonPath>) {
      state.selectedMenuButtonPaths = state.selectedMenuButtonPaths.filter((p) => p !== action.payload);
    },
    setFontSize(state, action: PayloadAction<{ buttonId: FontButtonId; value: number }>) {
      // @ts-ignore
      state.font[action.payload.buttonId] = action.payload.value;
    },
    setBrightness(state, action: PayloadAction<{ buttonId: BrightButtonId; value: number }>) {
      state.brightness[action.payload.buttonId] = action.payload.value;
    },
    setCursorProps(state, action: PayloadAction<{ buttonId: CursorButtonId; value: number }>) {
      state.cursor[action.payload.buttonId] = action.payload.value;
    },
    setAltLimits(state, action: PayloadAction<AltitudeLimits>) {
      state.altitudeLimits = action.payload;
    },
    moveToolbarUp(state, action: PayloadAction<MovableToolbarId>) {
      const toolbar = action.payload;
      const [topList, bottomList] = state.toolbarPositions;
      let openTop = topList.filter((t) => state.toggleButtonState[t]);
      let openBottom = bottomList.filter((t) => state.toggleButtonState[t]);
      const upperIndex = openTop.indexOf(toolbar);
      const lowerIndex = openBottom.indexOf(toolbar);

      // toolbar is already in top list
      if (upperIndex > -1) {
        openTop = openTop.filter((t) => t !== toolbar);
        openTop.splice(upperIndex - 1, 0, toolbar);
        // toolbar is in bottom list and is first
      } else if (lowerIndex === 0) {
        openBottom = openBottom.filter((t) => t !== toolbar);
        openTop.push(toolbar);
      } else {
        openBottom = openBottom.filter((t) => t !== toolbar);
        openBottom.splice(upperIndex - 1, 0, toolbar);
      }
      state.toolbarPositions = [
        [...openTop, ...topList.filter((t) => !state.toggleButtonState[t])],
        [...bottomList.filter((t) => !state.toggleButtonState[t]), ...openBottom],
      ];
    },
    moveToolbarDown(state, action: PayloadAction<MovableToolbarId>) {
      const toolbar = action.payload;
      const [topList, bottomList] = state.toolbarPositions;
      let openTop = topList.filter((t) => state.toggleButtonState[t]);
      let openBottom = bottomList.filter((t) => state.toggleButtonState[t]);
      const upperIndex = openTop.indexOf(toolbar);
      const lowerIndex = openBottom.indexOf(toolbar);
      // toolbar is already in bottom list
      if (lowerIndex > -1) {
        openBottom = openBottom.filter((t) => t !== toolbar);
        openBottom.splice(lowerIndex + 1, 0, toolbar);
        // toolbar is in top list and at the end
      } else if (upperIndex === openTop.length - 1) {
        openTop = openTop.slice(0, -1);
        openBottom = [toolbar, ...openBottom];
      } else {
        openTop = openTop.filter((t) => t !== toolbar);
        openTop.splice(upperIndex + 1, 0, toolbar);
      }
      state.toolbarPositions = [
        [...openTop, ...topList.filter((t) => !state.toggleButtonState[t])],
        [...bottomList.filter((t) => !state.toggleButtonState[t]), ...openBottom],
      ];
    },
    addToolbarButton(state, action: PayloadAction<ToolbarButtonPosition>) {
      const { buttonId, macroLabel } = action.payload;
      state.toolbarButtonPositions = [
        ...state.toolbarButtonPositions.filter(
          (b) => (buttonId === "MACRO_BUTTON" && b.macroLabel !== macroLabel) || b.buttonId !== buttonId,
        ),
        action.payload,
      ];
    },
    removeToolbarButton(
      state,
      action: PayloadAction<{ buttonId: EramButtonId | "MACRO_BUTTON"; macroLabel?: Nullable<string> }>,
    ) {
      const { buttonId, macroLabel } = action.payload;
      state.toolbarButtonPositions = state.toolbarButtonPositions.filter(
        (b) => (buttonId === "MACRO_BUTTON" && b.macroLabel !== macroLabel) || b.buttonId !== buttonId,
      );
    },
    setCrrGroups(state, action: PayloadAction<CrrGroup[]>) {
      state.crrGroups = action.payload;
    },
    setCrrGroup(state, action: PayloadAction<CrrGroup>) {
      const index = state.crrGroups.findIndex((e) => e.name === action.payload.name);
      if (index === -1) {
        state.crrGroups.push(action.payload);
        return;
      }
      state.crrGroups[index] = action.payload;
    },
    removeCrrGroup(state, action: PayloadAction<string>) {
      state.crrGroups = state.crrGroups.filter((e) => e.name !== action.payload);
    },
    addMacro(state, action: PayloadAction<MacroButton>) {
      if (Object.keys(state.macroButtonMap).length < 99) {
        state.macroButtonMap[action.payload.label] = action.payload;
      }
    },
    updateMacro(state, action: PayloadAction<{ macro: MacroButton; newLabel: string; oldLabel: string }>) {
      const { macro, newLabel, oldLabel } = action.payload;
      if (oldLabel in state.macroButtonMap) {
        delete state.macroButtonMap[oldLabel];
        state.macroButtonMap[newLabel] = { ...macro, label: newLabel };
      }
      return state;
    },
    removeMacro(state, action: PayloadAction<string>) {
      delete state.macroButtonMap[action.payload];
    },
    removeAllMacros(state) {
      state.macroButtonMap = {};
    },
    setMsgOutFilterValue(
      state,
      action: PayloadAction<{ category: keyof MsgOutFilterValueMap; row: string; value: boolean }>,
    ) {
      const { category, row, value } = action.payload;
      if (row in state.msgOutFilterMap[category]) {
        (state.msgOutFilterMap as any)[category][row] = value;
      }
    },
    resetMsgOutFilter(state) {
      state.msgOutFilterMap = { ...initialMsgOutFilterMap };
    },
    addDrawItem(state, action: PayloadAction<DrawItem>) {
      state.drawItems.push(action.payload);
    },
    removeDrawItem(state, action: PayloadAction<string>) {
      state.drawItems = state.drawItems.filter((item) => item.id !== action.payload);
    },
    updateDrawItem(state, action: PayloadAction<DrawItem>) {
      const index = state.drawItems.findIndex((item) => item.id === action.payload.id);
      if (index !== -1) {
        state.drawItems[index] = action.payload;
      }
    },
    removeAllDrawItems(state) {
      state.drawItems = [];
    },
    setAltimTearoffPosition(state, action: PayloadAction<{ airport: string; position: WindowPosition }>) {
      state.altimTearoffPositionMap[action.payload.airport] = action.payload.position;
    },
    setMetarTearoffPosition(state, action: PayloadAction<{ airport: string; position: WindowPosition }>) {
      state.metarTearoffPositionMap[action.payload.airport] = action.payload.position;
    },
    filterAltimTearoffs(state, action: PayloadAction<string[]>) {
      state.altimTearoffPositionMap = Object.fromEntries(
        Object.entries(state.altimTearoffPositionMap).filter(([k]) => action.payload.includes(k)),
      );
    },
    filterMetarTearoffs(state, action: PayloadAction<string[]>) {
      state.metarTearoffPositionMap = Object.fromEntries(
        Object.entries(state.metarTearoffPositionMap).filter(([k]) => action.payload.includes(k)),
      );
    },
    removeMetarTearoffs(state, action: PayloadAction<string[]>) {
      state.metarTearoffPositionMap = Object.fromEntries(
        Object.entries(state.metarTearoffPositionMap).filter(([k]) => !action.payload.includes(k)),
      );
    },
    removeAltimTearoffs(state, action: PayloadAction<string[]>) {
      state.altimTearoffPositionMap = Object.fromEntries(
        Object.entries(state.altimTearoffPositionMap).filter(([k]) => !action.payload.includes(k)),
      );
    },
    setDrawColor(state, action: PayloadAction<ColorName>) {
      state.drawColor = action.payload;
    },
    setEramState(state, action: PayloadAction<EramState>) {
      return action.payload;
    },
  },
});

export const {
  setAltimTearoffPosition,
  setMetarTearoffPosition,
  removeMetarTearoffs,
  removeAltimTearoffs,
  setViewPosition,
  pushZStack,
  setHistory,
  setTearOffButtonPosition,
  delTearOffButton,
  setVectorLength,
  toggleButton,
  setToggleButtonValue,
  toggleMenuButtonPathValue,
  removeMenuButtonPath,
  setBrightness,
  setFontSize,
  setAbWidth,
  setNexradAltLabelIndex,
  setNexradLevel,
  setCursorProps,
  setAltLimits,
  moveToolbarUp,
  moveToolbarDown,
  addToolbarButton,
  removeToolbarButton,
  setCrrGroup,
  removeCrrGroup,
  setCrrGroups,
  addMacro,
  updateMacro,
  removeMacro,
  removeAllMacros,
  setMsgOutFilterValue,
  resetMsgOutFilter,
  setDrawCircleRadius,
  setDrawFontSize,
  setDrawColor,
  setDrawColorBright,
  setFdbLdr,
  addDrawItem,
  removeDrawItem,
  updateDrawItem,
  removeAllDrawItems,
  setEramState,
  resetEramState,
} = eramStateSlice.actions;

export const deltaVectorLength = (sign: 1 | -1): RootThunkAction => {
  return (dispatch, getState) => {
    const vectorLength = getState().eram.vectorLength;
    if (vectorLength + sign >= 0 && vectorLength + sign <= 4) {
      dispatch(setVectorLength(vectorLength + sign));
    } else {
      void errorTone.tryPlay();
    }
  };
};

export const reducer = eramStateSlice.reducer;

export const closeView = (eramView: EramView): RootThunkAction => {
  return (dispatch) => {
    if (includes(viewButtons, eramView)) {
      dispatch(toggleButton(eramView as ViewButtonId));
    }
  };
};

export const showMasterToolbarSelector = (state: RootState) => state.eram.toggleButtonState.MASTER_TOOLBAR;
export const showMcaToolbarSelector = (state: RootState) => state.eram.toggleButtonState.MCA_TOOLBAR;
export const showHorizToolbarSelector = (state: RootState) => state.eram.toggleButtonState.HORIZ_TOOLBAR;
export const showLeftToolbarSelector = (state: RootState) => state.eram.toggleButtonState.LEFT_TOOLBAR;
export const showRightToolbarSelector = (state: RootState) => state.eram.toggleButtonState.RIGHT_TOOLBAR;

export const tearOffPositionMapSelector = (state: RootState) => state.eram.tearOffButtonPositionMap;
export const tearOffButtonPositionSelector = (state: RootState, button: EramButtonId) =>
  button ? state.eram.tearOffButtonPositionMap[button] : null;
export const brightValuesSelector = (state: RootState) => state.eram.brightness;
export const toggleButtonStateSelector = (state: RootState) => state.eram.toggleButtonState;
export const toggleButtonValueSelector = (state: RootState, toggleButtonId: ToggleButtonId) =>
  state.eram.toggleButtonState[toggleButtonId];
export const menuButtonPathsSelector = (state: RootState) => state.eram.selectedMenuButtonPaths;
export const menuButtonPathSelectedSelector = (state: RootState, path: ButtonPath) =>
  state.eram.selectedMenuButtonPaths.includes(path);
export const zStackSelector = (state: RootState) => state.eram.zStack;
export const viewPositionMapSelector = (state: RootState) => state.eram.viewPositionMap;
export const viewPositionSelector = (state: RootState, view: EramView) => state.eram.viewPositionMap[view];
export const altitudeLimitsSelector = (state: RootState) => state.eram.altitudeLimits;
export const nxLevelSelector = (state: RootState) => state.eram.nexradLevel;
export const nxAltDisplayItemSelector = (state: RootState) =>
  state.eramTemp.rPosConfig?.nexradLabels[state.eram.nexradAltLabelIndex] ?? defaultNexradDisplayItem;
export const nxBrightSelector = (state: RootState) => state.eram.brightness.NEXRAD_BRIGHT;
export const crrGroupsSelector = (state: RootState) => state.eram.crrGroups;
export const macroButtonMapSelector = (state: RootState) => state.eram.macroButtonMap;
export const msgOutFilterValuesSelector = (state: RootState) => state.eram.msgOutFilterMap;
export const trackCrrGroupSelector = (state: RootState, trackId: TrackId) =>
  state.eram.crrGroups.find((g) => g.aircraft.includes(trackId));
export const openToolbarListSelector = createSelector(
  [(state: RootState) => state.eram.toggleButtonState, (state: RootState) => state.eram.toolbarPositions],
  (toggleButtonState, [topList, bottomList]) => {
    const openTop = topList.filter((t) => toggleButtonState[t]);
    const openBottom = bottomList.filter((t) => toggleButtonState[t]);
    return [openTop, openBottom] as const;
  },
);

const toolbarRaisedButtonIdMap = {
  HORIZ_TOOLBAR: "HORIZ_RAISED",
  LEFT_TOOLBAR: "LEFT_RAISED",
  RIGHT_TOOLBAR: "RIGHT_RAISED",
  MCA_TOOLBAR: "MCA_RAISED",
  MASTER_TOOLBAR: "MASTER_RAISED",
} as const;
export const toolbarIsRaisedSelector = (state: RootState, toolbarId: ToolbarId) =>
  state.eram.toggleButtonState[toolbarRaisedButtonIdMap[toolbarId]];
export const drawItemsSelector = (state: RootState) => state.eram.drawItems;
export const drawColorBrightSelector = (state: RootState) => state.eram.drawColorBright;
export const drawCircleRadiusSelector = (state: RootState) => state.eram.drawCircleRadius;
export const isDrawEditingSelector = createSelector([drawItemsSelector], (items) => {
  return items.some((item) => item.element.editMode);
});
export const drawColorSelector = (state: RootState) => state.eram.drawColor;

export const metarTearoffMapSelector = (state: RootState) => state.eram.metarTearoffPositionMap;
export const altimTearoffMapSelector = (state: RootState) => state.eram.altimTearoffPositionMap;
export const metarTearoffSelector = (state: RootState, airport: string) =>
  state.eram.metarTearoffPositionMap[airport];
export const altimTearoffSelector = (state: RootState, airport: string) =>
  state.eram.altimTearoffPositionMap[airport];

export function setEramAltimeterList(list: AltimeterListEntry[]): RootThunkAction {
  return (dispatch) => {
    dispatch(setAltimeterList(list));
    dispatch(eramStateSlice.actions.filterAltimTearoffs(list.map((v) => v.station)));
  };
}
export function setEramMetarList(list: string[]): RootThunkAction {
  return (dispatch) => {
    dispatch(setMetarList(list));
    dispatch(eramStateSlice.actions.filterMetarTearoffs(list));
  };
}
