import React from "react";
import { View } from "components/utils/View";
import { backgroundOption, borderOption, brightOption, fontOption } from "~redux/slices/viewOptionSlice";

const view = "AUTO_HO_INHIB";

const optionMap = {
  background: backgroundOption(view),
  border: borderOption(view),
  font: fontOption(view),
  bright: brightOption(view),
};

export const AutoHoInhib = () => {
  return <View width={30} height={0} view={view} optionMap={optionMap} />;
};
