import { setSettingsState } from "~redux/slices/settingsSlice";
import { setViewOptionState } from "~redux/slices/viewOptionSlice";
import {
  closeSpecificViewOption,
  setIsProcessingCommand,
  setMcaFeedback,
  setSelectedViewOption,
  toggleSelectedViewOption,
} from "@poscon/shared-frontend";
import { setMapScale, setRangeCenterOverride } from "~redux/slices/eramTempStateSlice";

export const whitelistedActions: string[] = [
  setSettingsState.type,
  setViewOptionState.type,
  setSelectedViewOption.type,
  closeSpecificViewOption.type,
  toggleSelectedViewOption.type,
  setMcaFeedback.type,
  setMapScale.type,
  setRangeCenterOverride.type,
  setIsProcessingCommand.type,
];
