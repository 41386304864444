import React, { useRef } from "react";
import { AdvFreetextTemplate, advFreetextTemplateView } from "@poscon/shared-frontend";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { processEramMessage } from "~redux/thunks/processEramMessage";
import type { Container as PixiContainer } from "pixi.js";
import { useDragging } from "hooks/useDragging";
import { layerZIndexMap } from "~/layerZIndexMap";
import { useEventMode } from "components/utils/InteractiveContainer";
import { viewPositionSelector } from "~redux/slices/eramStateSlice";

const view = advFreetextTemplateView;

export const CpdlcAdvFreetextTemplate = () => {
  const dispatch = useRootDispatch();
  const ref = useRef<PixiContainer | null>(null);
  const pos = useRootSelector((state) => viewPositionSelector(state, view));
  const { startDrag } = useDragging(ref, view);
  const eventMode = useEventMode();

  return (
    <AdvFreetextTemplate
      ref={ref}
      pos={pos}
      startDrag={startDrag}
      zIndex={layerZIndexMap.prompts}
      eventMode={eventMode}
      dispatchCommand={(command) => dispatch(processEramMessage(command))}
    />
  );
};
