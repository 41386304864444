import React from "react";
import { Container, Graphics, Sprite } from "@pixi/react";
import { computeColor, GeomapContextProvider, useUiIsLocked } from "@poscon/shared-frontend";
import { useRootSelector } from "~redux/hooks";
import { useSituationDisplay } from "contexts/sdContext";
import type { Graphics as PixiGraphics, Sprite as PixiSprite } from "pixi.js";
import { Texture } from "pixi.js";
import { Map } from "components/map/Map";
import { ToolbarContainer } from "components/Toolbar";
import { layerZIndexMap } from "~/layerZIndexMap";

const baseBgColor = new Uint8Array([0x00, 0x00, 0xad]);
const baseAbColor = new Uint8Array([0xd0, 0xd0, 0xd0]);

export const SituationDisplay = React.forwardRef<PixiGraphics>((_, ref) => {
  const { rect } = useSituationDisplay();
  const maskRef = React.useRef<PixiSprite>(null);
  const _abWidth = useRootSelector((state) => state.eram.abWidth);
  const abBright = useRootSelector((state) => state.eram.brightness.AB_BRDR_BRIGHT);
  const backgroundBrightness = useRootSelector((state) => state.eram.brightness.BCKGRD_BRIGHT);
  const uiIsLocked = useUiIsLocked();

  const abWidth = !uiIsLocked ? _abWidth : Math.max(_abWidth, 2);
  const abTint = computeColor(baseAbColor, abBright / 100);

  return (
    <>
      <Container
        name="SITUATION_DISPLAY"
        zIndex={layerZIndexMap.situationDisplay}
        mask={maskRef.current}
        x={rect.x}
        y={0}
      >
        <Sprite
          x={-1}
          name="SD_MASK"
          texture={Texture.WHITE}
          width={rect.width + 2}
          height={rect.height}
          ref={maskRef}
          eventMode="none"
        />
        <Graphics
          name="SITUATION_DISPLAY"
          ref={ref}
          eventMode="static"
          draw={(graphics) => {
            graphics.clear();
            const color = computeColor(baseBgColor, backgroundBrightness / 100, 0);
            graphics.beginFill(color);
            graphics.drawRect(0, 0, rect.width, rect.height);
            graphics.endFill();
          }}
        />
      </Container>
      <ToolbarContainer maskRef={maskRef} x={rect.x} />
      <GeomapContextProvider>
        <Map maskRef={maskRef} />
      </GeomapContextProvider>
      <Graphics
        x={rect.x}
        name="AB_BORDER"
        draw={(graphics) => {
          graphics.clear();
          graphics.beginFill(abTint);
          graphics.drawRect(-abWidth, 0, abWidth, rect.height);
          graphics.drawRect(rect.width, 0, abWidth, rect.height);
          graphics.endFill();
        }}
      />
    </>
  );
});
