import React from "react";
import type { LsConfig } from "@poscon/shared-types";
import { mod } from "@poscon/shared-types";
import { useProjection } from "contexts/sdContext";
import { situationDisplayStore } from "~/situationDisplayStore";
import { Graphics } from "@pixi/react";

export const LongitudinalScale = ({ config: { position, distance, bearing } }: { config: LsConfig }) => {
  const projection = useProjection();

  const [x, y] = projection(position)!;
  const pixelLength = situationDisplayStore.nmToPx(distance);

  const lineAngle = mod(bearing, 360);

  return (
    <Graphics
      zIndex={10}
      x={x}
      y={y}
      angle={lineAngle + 180}
      draw={(graphics) => {
        graphics.clear();
        graphics.lineStyle(1, 0xffffff, 1, 0.5, true);
        graphics.moveTo(0, 0);
        graphics.lineTo(0, pixelLength);

        Array.from({ length: distance + 1 }).forEach((_, j) => {
          const alongDist = (j * pixelLength) / distance;
          const l = j % 5 === 0 ? 16 : 6;
          graphics.moveTo(0, alongDist);
          graphics.lineTo(l, alongDist);
        });
      }}
    />
  );
};
