import React from "react";
import { View } from "components/utils/View";
import {
  backgroundOption,
  borderOption,
  brightOption,
  counterOption,
  fontOption,
  linesOption,
  viewOptionSelector,
} from "~redux/slices/viewOptionSlice";
import { ViewOptionContextProvider } from "contexts/viewOptionContext";
import { useRootSelector } from "~redux/hooks";
import type { EramFontSize } from "@poscon/shared-frontend";
import {
  ScrollBar,
  useScrollProps,
  Circle,
  sectorSaaDisplaySelector,
  eramFontDimensionMap,
  eramFontNameMap,
  colorNameMap,
  computeColor,
  eramTextDimensionMap,
  Line,
  MenuElement,
  saaListSelector,
} from "@poscon/shared-frontend";
import { BitmapText, Container } from "@pixi/react";
import type { ArtccSAA, SaaDisplaySetting } from "@poscon/shared-types";
import { saaDisplaySettingCycle } from "@poscon/shared-types";
import type { ColorSource } from "pixi.js";
import { eramHubConnection } from "~/eramHubConnection";

const view = "SAA_FILTER";

export const optionMap = {
  background: backgroundOption(view),
  border: borderOption(view),
  lines: linesOption(view, 5, 25),
  font: fontOption(view),
  pendingFont: counterOption(view, "pendingFont", "PEND FONT", 1, 3, 11),
  bright: brightOption(view),
  color: counterOption(view, "color", "COLOR", 0, 100, 10, "delta", 2),
};

const saaDisplaySettingTextMap: Record<SaaDisplaySetting, string> = {
  BOUNDARY: "B",
  BOUNDARY_ALT_LABEL: "BAL",
  BOUNDARY_LABEL: "BL",
  OFF: "",
};

const charWidth = 40;

type SaaFilterRowProps = {
  y: number;
  saa: ArtccSAA;
  fontSize: EramFontSize;
  tint: ColorSource;
  hotTint: ColorSource;
  pendingTint: ColorSource;
};
const SaaFilterRow = ({ y, saa, fontSize, tint, hotTint, pendingTint }: SaaFilterRowProps) => {
  const saaDisplay = useRootSelector((state) => sectorSaaDisplaySelector(state, saa.saaId));
  const fontName = eramFontNameMap[fontSize];
  const fontDim = eramFontDimensionMap[fontName];

  const coldText = saaDisplaySettingTextMap[saaDisplay.cold];
  const hotText = saaDisplaySettingTextMap[saaDisplay.hot];

  return (
    <Container y={y + 1} zIndex={1}>
      <MenuElement
        x={4}
        textY={3}
        width={fontDim.width * 4 + 2}
        height={fontDim.height + 4}
        text={coldText}
        fontSize={fontSize}
        tint={tint}
        fillColor={coldText.length > 0 ? colorNameMap.darkGrey : 0}
        onmousedown={() => {
          eramHubConnection.emit("setSectorSaaDisplay", saa.saaId, {
            ...saaDisplay,
            cold: saaDisplaySettingCycle.at((saaDisplaySettingCycle.indexOf(saaDisplay.cold) + 1) % 3)!,
          });
        }}
      />
      <MenuElement
        x={fontDim.width * 4 + 9}
        textY={3}
        width={fontDim.width * 4 + 2}
        height={fontDim.height + 4}
        text={hotText}
        fontSize={fontSize}
        tint={hotTint}
        fillColor={hotText.length > 0 ? colorNameMap.darkGrey : 0}
        onmousedown={() => {
          eramHubConnection.emit("setSectorSaaDisplay", saa.saaId, {
            ...saaDisplay,
            hot: saaDisplaySettingCycle.at((saaDisplaySettingCycle.indexOf(saaDisplay.hot) + 1) % 4)!,
          });
        }}
      />
      <Circle
        x={fontDim.width * 12}
        y={Math.floor(fontDim.height / 2 + 3)}
        radius={Math.floor(fontDim.height / 2 - 1)}
        borderTint={colorNameMap.mediumGrey}
        fillTint={coldText || hotText ? tint : 0}
        onmousedown={() => {
          if (saaDisplay.cold !== "OFF" || saaDisplay.hot !== "OFF") {
            eramHubConnection.emit("setSectorSaaDisplay", saa.saaId, { cold: "OFF", hot: "OFF" });
          } else {
            eramHubConnection.emit("setSectorSaaDisplay", saa.saaId, {
              cold: saaDisplaySettingCycle.at(Math.max(saaDisplaySettingCycle.indexOf(saaDisplay.cold), 1))!,
              hot: saaDisplaySettingCycle.at(Math.max(saaDisplaySettingCycle.indexOf(saaDisplay.hot), 1))!,
            });
          }
        }}
      />
      <MenuElement
        x={fontDim.width * 14}
        y={2}
        width={fontDim.width + 2}
        height={fontDim.height}
        fillColor={colorNameMap.yellow}
      />
      <BitmapText
        x={fontDim.width * 16}
        y={3}
        text={saa.saaName}
        fontName={fontName}
        tint={tint}
        style={{ fontName, tint }}
      />
    </Container>
  );
};

export const SaaFilter = () => {
  const viewOptions = useRootSelector((state) => viewOptionSelector(state, view));
  const saaList = useRootSelector(saaListSelector);
  const { lines, font, bright } = viewOptions;
  const scrollProps = useScrollProps("saaFilter", saaList.length, lines, { scrollDelta: lines });

  const fontDim = eramTextDimensionMap[font];
  const fontName = eramFontNameMap[font];
  const width = charWidth * fontDim.width;
  const tint = computeColor(colorNameMap.lightGrey, bright / 100);
  const pendingTint = computeColor(colorNameMap.yellow, bright / 100);
  const hotTint = computeColor(colorNameMap.orange, bright / 100);

  const tableHeight = lines * (fontDim.height + 8);
  const height = tableHeight + fontDim.height + 6;

  return (
    <ViewOptionContextProvider options={viewOptions}>
      <View width={charWidth} height={height} view={view} optionMap={optionMap}>
        <BitmapText x={4} y={2} text="COLD" tint={tint} fontName={fontName} style={{ fontName, tint }} />
        <BitmapText
          x={9 + Math.floor(fontDim.width * 4.5)}
          y={2}
          text="HOT"
          tint={hotTint}
          fontName={fontName}
          style={{ fontName, tint: hotTint }}
        />
        <BitmapText
          x={18 + fontDim.width * 8}
          y={2}
          text="DISPLAY"
          tint={hotTint}
          fontName={fontName}
          style={{ fontName, tint: hotTint }}
        />
        <Line y={fontDim.height + 4} length={charWidth * fontDim.width - 1} tint={tint} />
        <Container y={fontDim.height + 6} zIndex={1}>
          {saaList.slice(scrollProps.scroll, scrollProps.scroll + lines).map((saa, index) => (
            <SaaFilterRow
              key={saa.saaId}
              y={index * (fontDim.height + 8)}
              saa={saa}
              fontSize={font}
              tint={tint}
              hotTint={hotTint}
              pendingTint={pendingTint}
            />
          ))}
          <ScrollBar x={width - fontDim.width - 3} height={tableHeight - 2} fontSize={font} {...scrollProps} />
        </Container>
      </View>
    </ViewOptionContextProvider>
  );
};
