import React from "react";
import type { EramFontSize } from "@poscon/shared-frontend";
import { useRootSelector } from "~redux/hooks";
import type { Nullable } from "@poscon/shared-types";

const baseTbColor = [0x39 / 0xff, 0x44 / 0xff, 0x41 / 0xff] as const;

const ToolbarContext = React.createContext<
  Nullable<{
    fillColor: [number, number, number];
    tbBorderColor: [number, number, number];
    fontSize: EramFontSize;
  }>
>(null);

export const ToolbarContextProvider = ({ children }: { children: React.ReactNode }) => {
  const fontSize = useRootSelector((state) => state.eram.font.TOOLBAR_FONT) as EramFontSize;
  const toolbarBright = useRootSelector((state) => state.eram.brightness.TOOLBAR_BRIGHT);
  const tbBorderBright = useRootSelector((state) => state.eram.brightness.TB_BRDR_BRIGHT);
  const alpha = 0.1 + 0.9 * (toolbarBright / 100);
  const fillColor = baseTbColor.map((c) => c * alpha) as [number, number, number];
  const tbBorderAlpha = 0.1 + 0.9 * (tbBorderBright / 100);
  const tbBorderColor = baseTbColor.map((c) => c * tbBorderAlpha) as [number, number, number];

  return (
    <ToolbarContext.Provider value={{ fillColor, fontSize, tbBorderColor }}>{children}</ToolbarContext.Provider>
  );
};
export const useToolbarContext = () => {
  const contextValue = React.useContext(ToolbarContext);
  if (contextValue === null) {
    throw new Error("useToolbarContext must be used within a ToolbarContextProvider");
  }
  return contextValue;
};
