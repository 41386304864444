import type { EramState } from "~redux/slices/eramStateSlice";
import type { ViewOptionState } from "~redux/slices/viewOptionSlice";
import type { SettingsState } from "~redux/slices/settingsSlice";
import type { AltimeterListEntry, Coordinate } from "@poscon/shared-types";
import type { RootState } from "~redux/store";
import { situationDisplayStore } from "~/situationDisplayStore";
import { WebviewWindow } from "@tauri-apps/api/webviewWindow";
import { altimeterAirportsSelector, metarAirportsSelector } from "@poscon/shared-frontend";

export type TauriPrefsetState = {
  fullscreen: boolean;
  outerSize: { width: number; height: number };
  outerPosition: { x: number; y: number };
  maximized: boolean;
};

export type EramPrefset = {
  eram: EramState;
  range: number;
  center: Coordinate;
  metarList: string[];
  altimList: AltimeterListEntry[];
  viewOptions: ViewOptionState;
  settings: SettingsState;
  tauri: TauriPrefsetState;
};

async function getTauriState(): Promise<TauriPrefsetState> {
  if (!window.__TAURI__) {
    return {
      fullscreen: false,
      outerSize: { width: 1600, height: 900 },
      outerPosition: { x: 0, y: 0 },
      maximized: false,
    };
  }
  const fullscreen = await WebviewWindow.getCurrent().isFullscreen();
  const outerSize = await WebviewWindow.getCurrent().outerSize();
  const outerPosition = await WebviewWindow.getCurrent().outerPosition();
  const maximized = await WebviewWindow.getCurrent().isMaximized();
  return {
    fullscreen,
    outerSize: { width: outerSize.width, height: outerSize.height },
    outerPosition: { x: outerPosition.x, y: outerPosition.y },
    maximized,
  };
}

export async function createPrefset(state: RootState): Promise<EramPrefset> {
  const eram = {
    ...state.eram,
    selectedMenuButtonPaths: [],
    crrGroups: state.eram.crrGroups.map((g) => ({ ...g, aircraft: [] })),
  };
  return {
    eram,
    range: situationDisplayStore.range,
    center: situationDisplayStore.lonLatCenter,
    metarList: metarAirportsSelector(state),
    altimList: altimeterAirportsSelector(state),
    viewOptions: state.viewOptions,
    tauri: await getTauriState(),
    settings: state.settings,
  };
}
