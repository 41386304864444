import React from "react";
import type { ViewMenuProps } from "components/utils/ViewMenu";
import { ViewMenu } from "components/utils/ViewMenu";
import { useViewOptions } from "contexts/viewOptionContext";
import {
  AltimSetTemplate,
  altimSetTemplateViewMenu,
  altimViewTemplateColWidth,
  updateViewMenuFields,
  viewMenuFieldsSelector,
} from "@poscon/shared-frontend";
import { useRootDispatch, useRootSelector } from "~redux/hooks";

export const ASViewMenu = (props: ViewMenuProps<"ALTIM_SET">) => {
  const dispatch = useRootDispatch();
  const templateFields = useRootSelector((state) => viewMenuFieldsSelector(state, altimSetTemplateViewMenu));
  const viewOptions = useViewOptions(props.view);

  const options = {
    ...props.options,
    template: {
      ...props.options!.template!,
      onClick: () => dispatch(updateViewMenuFields(altimSetTemplateViewMenu, "open", true)),
    },
  };

  const templateWidth = altimViewTemplateColWidth * viewOptions.column;

  return (
    <ViewMenu
      {...props}
      options={!templateFields.open ? options : {}}
      minWidth={templateFields.open ? templateWidth : undefined}
    >
      {templateFields.open ? <AltimSetTemplate /> : null}
    </ViewMenu>
  );
};
