import React from "react";
import { View } from "components/utils/View";
import {
  backgroundOption,
  borderOption,
  brightOption,
  fontOption,
  linesOption,
  toggleOption,
} from "~redux/slices/viewOptionSlice";

const view = "MRP_LIST";

export const optionMap = {
  background: backgroundOption(view),
  border: borderOption(view),
  tearoff: toggleOption(view, "tearoff", "TEAROFF", 7),
  lines: linesOption(view, 5, 25),
  font: fontOption(view),
  bright: brightOption(view),
  sort: {
    textSelector: () => "SORT",
  },
};

export const MeterRefPointList = () => {
  return <View width={40} height={0} view="MRP_LIST" />;
};
