import type { EramButtonId } from "types/eramButton";
import React from "react";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { useCursorContext } from "contexts/cursorContext";
import { TBE, TBH, TBP } from "~/eramConstants";
import type { EramButtonProps } from "components/buttons/EramButton";
import { EramBaseButton } from "components/buttons/EramButton";
import { eramButtonActionMap } from "~redux/thunks/eramButtonActionMap";
import type { FederatedPointerEvent } from "pixi.js";
import { colorNameMap } from "@poscon/shared-frontend";

function isContinuousCounterButton(buttonId: EramButtonId) {
  return buttonId.endsWith("_BRIGHT") || buttonId.startsWith("BCG_") || buttonId === "DRAW_COLOR";
}

export const EramCounterButton = (props: EramButtonProps) => {
  const dispatch = useRootDispatch();
  const { onCounterError, onTBHError } = useCursorContext();

  const value = useRootSelector((state) => eramButtonActionMap[props.buttonId]?.value?.(state));
  const buttonAction = eramButtonActionMap[props.buttonId]?.action;

  const onmousedown = (event: FederatedPointerEvent) => {
    if (value !== undefined && buttonAction) {
      let intervalId: ReturnType<typeof setInterval>;
      switch (event.button) {
        case TBP:
          if (isContinuousCounterButton(props.buttonId)) {
            dispatch(buttonAction(props.buttonId, -2, onCounterError));
            intervalId = setInterval(() => dispatch(buttonAction(props.buttonId, -2, onCounterError)), 100);
            window.addEventListener("mouseup", () => clearInterval(intervalId), { once: true });
          } else {
            dispatch(buttonAction(props.buttonId, -1, onCounterError));
          }
          break;
        case TBE:
          if (isContinuousCounterButton(props.buttonId)) {
            dispatch(buttonAction(props.buttonId, 2, onCounterError));
            const intervalId = setInterval(() => dispatch(buttonAction(props.buttonId, 2, onCounterError)), 100);
            window.addEventListener("mouseup", () => clearInterval(intervalId), { once: true });
          } else {
            dispatch(buttonAction(props.buttonId, 1, onCounterError));
          }
          break;
        case TBH:
          onTBHError();
          break;
        default:
          break;
      }
    }
  };

  return (
    <EramBaseButton
      {...props}
      onmousedown={onmousedown}
      baseBgColor={props.buttonId === "DRAW_COLOR" ? colorNameMap.black : colorNameMap.counterGreen}
    />
  );
};
