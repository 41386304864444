import React from "react";
import { useRootSelector } from "~redux/hooks";
import { crrGroupsSelector } from "~redux/slices/eramStateSlice";
import type { CrrGroup } from "@poscon/shared-types";
import { stringToTokenArray } from "@poscon/shared-types";
import { useGetSdCoordFromLonLat } from "contexts/sdContext";
import { BitmapText, Container } from "@pixi/react";
import { dispatchInsertCommandEvent, eramFontNameMap, eramTextDimensionMap, TBP } from "@poscon/shared-frontend";
import { drawColorMap } from "~/utils/drawColorMap";
import { InteractiveContainer } from "../utils/InteractiveContainer";

const fontName = eramFontNameMap[2];
const fontDimensions = eramTextDimensionMap[2];

type CrrGroupLabelProps = {
  group: CrrGroup;
};
export const CrrGroupLabel = ({ group }: CrrGroupLabelProps) => {
  const getPixelCoordFromLonLat = useGetSdCoordFromLonLat();

  const [x, y] = getPixelCoordFromLonLat(group.coordinate)!;
  const tint = drawColorMap[group.color];

  return (
    <Container x={x} y={y} name={`CRR_GROUP_${group.name}`} eventMode="static">
      <BitmapText
        eventMode="static"
        onmousedown={(e) => {
          if (e.button === TBP) {
            dispatchInsertCommandEvent(stringToTokenArray(`LF ${group.name}`));
          }
        }}
        x={-Math.round(fontDimensions.width / 2)}
        y={-Math.round(fontDimensions.height / 2)}
        text={`*${group.name}`}
        tint={tint}
        style={{ fontName, tint }}
        scale={1}
      />
    </Container>
  );
};

export const CrrGroupLabels = () => {
  const crrGroups = useRootSelector(crrGroupsSelector);

  return (
    <InteractiveContainer zIndex={5}>
      {Object.values(crrGroups).map((group) => (
        <CrrGroupLabel key={group.name} group={group} />
      ))}
    </InteractiveContainer>
  );
};
