import {
  ViewOptionContextProvider as TempViewOptionContextProvider,
  useViewOptions as useTempViewOptions,
} from "@poscon/shared-frontend";
import type { EramView } from "types/eramView";
import type { ViewOptionState } from "~redux/slices/viewOptionSlice";

export const ViewOptionContextProvider = TempViewOptionContextProvider;

export const useViewOptions = <V extends EramView = EramView>(view?: V) => {
  return useTempViewOptions() as ViewOptionState[V];
};
