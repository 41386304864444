import { cpdlcAdvView, cpdlcHistView, cpdlcMsgOutView, cpdlcTocSetView } from "@poscon/shared-frontend";
import { Range } from "@poscon/shared-types";

export const toolbarMenuButtonList = [
  ["MASTER_TOOLBAR", "MCA_TOOLBAR", "HORIZ_TOOLBAR", "LEFT_TOOLBAR", "RIGHT_TOOLBAR"],
  ["MASTER_RAISED", "MCA_RAISED", "HORIZ_RAISED", "LEFT_RAISED", "RIGHT_RAISED"],
] as const;
const _toolbarMenuButtonList = [...toolbarMenuButtonList.flat()] as const;
export type ToolbarMenuButtonId = (typeof _toolbarMenuButtonList)[number];
export const toolbarButtonList = [
  ["DRAW", "ATC_TOOLS", "AB_SETTING", "RANGE_MENU", "CURSOR", "BRIGHT", "FONT", "DB_FIELDS", "VECTOR"],
  ["VIEWS", "CHECK_LISTS", "COMMAND_MENUS", "MAP", "ALT_LIM", "RADAR_FILTER", "PREFSET", "DELETE_TEAROFF"],
] as const;
export const abSettingButtonList = [["AB_WIDTH", "AB_OPEN"]] as const;

export const recordMenuButtonList = [["MACRO_CREATE", "MACRO_EDIT", "MACRO_DELETE", "CLR_ALL_MACROS"]] as const;
export const commandCategoryButtonList = [
  ["CMD_MENU_1", "CMD_MENU_2", "CMD_MENU_3", "CMD_MENU_4", "CMD_MENU_5"],
  ["CMD_MENU_6", "CMD_MENU_7", "CMD_MENU_8", "CMD_MENU_9", "CMD_MENU_10"],
] as const;
export type CommandCategoryButtonId = (typeof commandCategoryButtonList)[number][number];
export type CheckListButtonId = `CHKLST_${Range<102>}`;
export const checkListsButtonList = Array.from({ length: 17 }, (_, i) => Array.from({ length: 6 }, (_, j) => `CHKLST_${i * 6 + j}` as CheckListButtonId));
export const viewsButtonList = [
  [
    "ALTIM_SET",
    "AUTO_HO_INHIB",
    "CFR",
    "CODE_VIEW",
    "CONFLCT_ALERT",
    cpdlcAdvView,
    cpdlcHistView,
    cpdlcMsgOutView,
    cpdlcTocSetView,
    "CRR",
  ],
  [
    "DEPT_LIST",
    "FLIGHT_EVENT",
    "GROUP_SUP",
    "HOLD_LIST",
    "INBND_LIST",
    "MRP_LIST",
    "SAA_FILTER",
    "UA",
    "WX_REPORT",
  ],
] as const;
export const viewButtons = [
  ...viewsButtonList.flat(),
  ...checkListsButtonList.flat(),
  "STATUS",
  "RANGE_MENU",
] as const;
export type ViewButtonId = (typeof viewButtons)[number];
export const cursorButtonList = [["CURSOR_SPEED", "CURSOR_SIZE", "CURSOR_VOLUME"]] as const;
const _cursorButtonList = [...cursorButtonList.flat()] as const;
export type CursorButtonId = (typeof _cursorButtonList)[number];

export const fontButtonList = [
  ["LINE_4_FONT", "FDB_FONT", "TOOLBAR_FONT"],
  ["LDB_FONT", "RDB_FONT", "OUTAGE_FONT"],
] as const;
export const fontButtons = [...fontButtonList.flat()] as const;
export type FontButtonId = (typeof fontButtons)[number];

export const mapButtonList = [
  ["MAP_1", "MAP_2", "MAP_3", "MAP_4", "MAP_5", "MAP_6", "MAP_7", "MAP_8", "MAP_9", "MAP_10"],
  ["MAP_11", "MAP_12", "MAP_13", "MAP_14", "MAP_15", "MAP_16", "MAP_17", "MAP_18", "MAP_19", "MAP_20"],
] as const;
const _mapButtonList = [...mapButtonList.flat()] as const;
export type MapButtonId = (typeof _mapButtonList)[number];
export const bcgButtonList = [
  ["BCG_1", "BCG_2", "BCG_3", "BCG_4", "BCG_5", "BCG_6", "BCG_7", "BCG_8", "BCG_9", "BCG_10"],
  ["BCG_11", "BCG_12", "BCG_13", "BCG_14", "BCG_15", "BCG_16", "BCG_17", "BCG_18", "BCG_19", "BCG_20"],
] as const;
export const cpdlcBrightButtonList = [
  ["NDA_CDA_BRIGHT", "PID_BRIGHT", "TIMEOUT_BRIGHT", "CAA_BRIGHT"],
  ["ELIG_BRIGHT", "TOC_IC_BRIGHT", "UPLINK_BRIGHT"],
] as const;
export const brightButtonList = [
  [
    "MAP_BRIGHT",
    "CPDLC_BRIGHT",
    "BCKGRD_BRIGHT",
    "CURSOR_BRIGHT",
    "TEXT_BRIGHT",
    "PR_TGT_BRIGHT",
    "UNP_TGT_BRIGHT",
    "PR_HST_BRIGHT",
    "UNP_HST_BRIGHT",
    "LDB_BRIGHT",
    "SLDB_BRIGHT",
    "WX_BRIGHT",
    "NEXRAD_BRIGHT",
    "GHOST_BRIGHT",
  ],
  [
    "BCKLGHT_BRIGHT",
    "BUTTON_BRIGHT",
    "BORDER_BRIGHT",
    "TOOLBAR_BRIGHT",
    "TB_BRDR_BRIGHT",
    "AB_BRDR_BRIGHT",
    "FDB_BRIGHT",
    "PORTAL_BRIGHT",
    "SATCOMM_BRIGHT",
    "ON_FREQ_BRIGHT",
    "LINE_4_BRIGHT",
    "DWELL_BRIGHT",
    "FENCE_BRIGHT",
    "DBFEL_BRIGHT",
    "OUTAGE_BRIGHT",
  ],
] as const;
const _brightButtons = [
  ...brightButtonList
    .flat()
    .filter(
      (button): button is Exclude<(typeof brightButtonList)[number][number], "MAP_BRIGHT" | "CPDLC_BRIGHT"> =>
        !["MAP_BRIGHT", "CPDLC_BRIGHT"].includes(button),
    ),
  ...cpdlcBrightButtonList.flat(),
  ...bcgButtonList.flat(),
] as const;
export type BrightButtonId = (typeof _brightButtons)[number];
export const brightButtons = _brightButtons as BrightButtonId[];

export const dbFieldsButtonList = [
  ["NON_RVSM", "VRI", "CODE", "SPEED", "DEST", "TYPE", "FDB_LDR", "BCAST_FLID"],
  ["NON_ADSB", "SAT_COMM", "TFM_REROUTE", "CRR_RDB", "STA_RDB", "DELAY_RDB", "DELAY_FORMAT", "PORTAL_FENCE"],
] as const;
const _dbFieldsButtonList = [...dbFieldsButtonList.flat()] as const;
export type DbFieldsButtonId = (typeof _dbFieldsButtonList)[number];

export const radarFilterButtonList = [
  ["ALL_LDBS", "PR_LDB", "UNP_LDB", "ALL_PRIM", "NON_MODE_C"],
  ["SELECT_BEACON", "PERM_ECHO", "STROBE_LINES", "HISTORY", "GHOST"],
] as const;
const _radarFilterButtonList = [...radarFilterButtonList.flat()] as const;
export type RadarFilterButtonId = (typeof _radarFilterButtonList)[number];

export const atcToolsButtonList = [["CRR_FIX", "SPEED_ADVSRY", "ATC_TOOLS_WX"]] as const;
const _atcToolsButtonList = [...atcToolsButtonList.flat()] as const;
export type AtcToolsButtonId = (typeof _atcToolsButtonList)[number];
export const atcToolsWxButtonList = [
  ["NX_ALT", "NX_LVL"],
  ["WX_1", "WX_2", "WX_3"],
] as const;

export const drawButtonList = [
  ["DRAW_RECT", "DRAW_CIRCLE", "DRAW_CIRCLE_SIZE", "DRAW_LINE", "DRAW_TEXT", "DRAW_COLOR", "DRAW_COLOR_PALETTE"],
  ["DRAW_FONT", "DRAW_FONT_SIZE", "DRAW_ANCHOR_MAP", "DRAW_DEL", "DRAW_DEL_ALL"],
] as const;
export type DrawButtonId = (typeof drawButtonList)[number][number];

export const toggleButtonList = [
  ...mapButtonList.flat(),
  ...toolbarMenuButtonList.flat(),
  ...viewsButtonList.flat(),
  ...checkListsButtonList.flat(),
  ...commandCategoryButtonList.flat(),
  ...radarFilterButtonList
    .flat()
    .filter((button): button is Exclude<RadarFilterButtonId, "HISTORY"> => button !== "HISTORY"),
  ...dbFieldsButtonList
    .flat()
    .filter((button): button is Exclude<DbFieldsButtonId, "FDB_LDR"> => button !== "FDB_LDR"),
  ...atcToolsButtonList
    .flat()
    .filter((button): button is Exclude<AtcToolsButtonId, "ATC_TOOLS_WX"> => button !== "ATC_TOOLS_WX"),
  ...atcToolsWxButtonList[1],
  "ALT_LIM",
  "PREFSET",
  "RANGE_MENU",
  "AB_OPEN",
  "STATUS",
  "SETTINGS_MENU",
] as const;
export type ToggleButtonId = (typeof toggleButtonList)[number];
export const disabledToggleButtons: ToggleButtonId[] = ["SPEED_ADVSRY"];

export const buttonList = [
  "",
  "TOOLBAR",
  "RECORD",
  "STATUS",
  "OUTAGE",
  "SETTINGS_MENU",
  ...toolbarMenuButtonList.flat(),
  ...toolbarButtonList.flat(),
  ...drawButtonList.flat(),
  ...viewsButtonList.flat(),
  ...checkListsButtonList.flat(),
  ...cursorButtonList.flat(),
  ...mapButtonList.flat(),
  ...brightButtonList.flat(),
  ...bcgButtonList.flat(),
  ...cpdlcBrightButtonList.flat(),
  ...recordMenuButtonList.flat(),
  ...dbFieldsButtonList.flat(),
  ...fontButtonList.flat(),
  ...radarFilterButtonList.flat(),
  ...atcToolsButtonList.flat(),
  ...atcToolsWxButtonList.flat(),
  ...abSettingButtonList.flat(),
  ...commandCategoryButtonList.flat(),
] as const;

export type EramButtonId = (typeof buttonList)[number];

export const masterToolbarButtonList: [EramButtonId[], EramButtonId[]] = [
  ["DRAW", "ATC_TOOLS", "AB_SETTING", "RANGE_MENU", "CURSOR", "BRIGHT", "FONT", "DB_FIELDS"],
  ["VIEWS", "CHECK_LISTS", "COMMAND_MENUS", "MAP", "ALT_LIM", "RADAR_FILTER", "PREFSET", "DELETE_TEAROFF"],
];

// these 5 buttons, and any button created by a controller, cannot be torn off and are always visible, TI 6110.100 - section 2.5.1
export const priorityButtonList = ["TOOLBAR", "STATUS", "OUTAGE", "VECTOR", "RECORD"] as const;
export type PriorityButtonId = (typeof priorityButtonList)[number];
export function isPriorityButton(buttonId: EramButtonId | "MACRO_BUTTON"): buttonId is PriorityButtonId {
  return buttonId !== "MACRO_BUTTON" && (priorityButtonList as readonly EramButtonId[]).includes(buttonId);
}
export const nonTransparentButtonList = ["TOOLBAR", "STATUS", "OUTAGE"] as const;
export function isNonTransparentButton(buttonId: EramButtonId | "MACRO_BUTTON") {
  return buttonId !== "MACRO_BUTTON" && (nonTransparentButtonList as readonly EramButtonId[]).includes(buttonId);
}

const _buttonMenuMap = {
  TOOLBAR: toolbarMenuButtonList,
  DRAW: drawButtonList,
  VIEWS: viewsButtonList,
  CHECK_LISTS: checkListsButtonList,
  CURSOR: cursorButtonList,
  BRIGHT: brightButtonList,
  DB_FIELDS: dbFieldsButtonList,
  FONT: fontButtonList,
  RADAR_FILTER: radarFilterButtonList,
  MAP: mapButtonList,
  ATC_TOOLS: atcToolsButtonList,
  ATC_TOOLS_WX: atcToolsWxButtonList,
  MAP_BRIGHT: bcgButtonList,
  CPDLC_BRIGHT: cpdlcBrightButtonList,
  RECORD: recordMenuButtonList,
  AB_SETTING: abSettingButtonList,
  COMMAND_MENUS: commandCategoryButtonList,
} as const;
export type MenuButtonId = keyof typeof _buttonMenuMap;
export const buttonMenuMap = _buttonMenuMap as Partial<Record<EramButtonId, readonly (readonly EramButtonId[])[]>>;
export const menuButtonList = Object.keys(_buttonMenuMap) as MenuButtonId[];

export const momentaryButtonList: (EramButtonId | "MACRO_BUTTON")[] = ["VRI", "CODE", "SPEED", "DELAY_FORMAT"];

export type ButtonPath = string;
