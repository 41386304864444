import React, { useLayoutEffect } from "react";
import { useRootSelector } from "~redux/hooks";
import { situationDisplayStore } from "~/situationDisplayStore";
import { LongitudinalScale } from "components/map/LongitudinalScale";
import { EramDrawItems } from "components/utils/EramDrawItem";
import { CrrGroupLabels } from "components/map/crrGroupLabels";
import { useDrawContext } from "contexts/drawContext";
import { useSituationDisplay } from "contexts/sdContext";
import { useGeomapConfig, usePixiMouseEventListener } from "@poscon/shared-frontend";
import { Geomaps } from "components/map/Geomaps";
import { RouteLines } from "components/map/RouteLines";
import { Container } from "@pixi/react";
import type { Container as PixiContainer, Sprite as PixiSprite } from "pixi.js";
import { layerZIndexMap } from "~/layerZIndexMap";
import { dispatchMapClickEvent } from "~/customEvents";
import { trackManager } from "~/trackManager";
import { InteractiveContainer } from "components/utils/InteractiveContainer";
import { nexradManager } from "~/nexradManager";
import { SaaMap } from "components/map/SaaMap";
import {
  delTearOffCommandActiveSelector,
  lsConfigSelector,
  mapScaleSelector,
  rangeCenterOverrideSelector,
} from "~/redux/slices/eramTempStateSlice";

type MapProps = {
  maskRef: React.MutableRefObject<PixiSprite | null>;
};
export const Map = React.memo(({ maskRef }: MapProps) => {
  const tracksContainerRef = React.useRef<PixiContainer>(null);
  const nexradContainerRef = React.useRef<PixiContainer>(null);
  const geomapConfig = useGeomapConfig();
  const { sdRef } = useSituationDisplay();
  const delTearOffCommandActive = useRootSelector(delTearOffCommandActiveSelector);
  const mapScale = useRootSelector(mapScaleSelector);
  const rangeCenterOverride = useRootSelector(rangeCenterOverrideSelector);
  const showCrrFixes = useRootSelector((state) => state.eram.toggleButtonState.CRR_FIX);
  const lsConfig = useRootSelector(lsConfigSelector);
  const { drawMode } = useDrawContext();

  useLayoutEffect(() => {
    if (tracksContainerRef.current) {
      trackManager.tracksContainer = tracksContainerRef.current;
    }
    if (nexradContainerRef.current) {
      nexradManager.container = nexradContainerRef.current;
    }
  }, []);

  usePixiMouseEventListener((event) => {
    if (!delTearOffCommandActive && drawMode === "CLOSED" && (event.target as any)?.name === "SITUATION_DISPLAY") {
      const sdCoordinate = situationDisplayStore.getSdCoordinates([event.clientX, event.clientY]);
      const geoCoordinate = situationDisplayStore.getLonLatFromSdCoord(sdCoordinate);
      dispatchMapClickEvent({
        button: event.button,
        sdCoordinate,
        geoCoordinate,
      });
    }
  }, sdRef);

  return (
    <>
      <Container mask={maskRef.current} zIndex={layerZIndexMap.map}>
        <Container
          sortableChildren
          scale={mapScale}
          x={rangeCenterOverride[0]}
          y={rangeCenterOverride[1]}
        >
          <Container name="NEXRAD" eventMode="none" sortableChildren zIndex={1} ref={nexradContainerRef} />
          {geomapConfig && (
            <>
              <Geomaps key={`MAP${geomapConfig.facility}${geomapConfig.geomapId}`} geomapConfig={geomapConfig} />
              <SaaMap
                key={`SAA${geomapConfig.facility}${geomapConfig.geomapId}`}
                geomapId={geomapConfig.geomapId}
              />
            </>
          )}
          {lsConfig && <LongitudinalScale config={lsConfig} />}
        </Container>
        {showCrrFixes && <CrrGroupLabels />}
        <RouteLines />
        <EramDrawItems />
      </Container>
      <InteractiveContainer
        ref={tracksContainerRef}
        mask={maskRef.current}
        zIndex={layerZIndexMap.tracks}
        name="TRACKS"
        sortableChildren
      />
    </>
  );
});
