import React from "react";
import { useRootSelector } from "~redux/hooks";
import { BrightContext, baseBorderColor, colorNameMap, computeColor } from "@poscon/shared-frontend";

// TODO: move context to shared-frontend package and initialize default values to be used in shared components in EDST.
// this will avoid prop drilling colors in shared frontend components.

export const BrightContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { BUTTON_BRIGHT, BORDER_BRIGHT, TEXT_BRIGHT } = useRootSelector((state) => state.eram.brightness);
  const borderTint = computeColor(baseBorderColor, BORDER_BRIGHT / 100);
  const tint = computeColor(colorNameMap.white, TEXT_BRIGHT / 100);
  const fillColor = computeColor(colorNameMap.grey, BUTTON_BRIGHT / 100);

  return (
    <BrightContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        borderTint,
        tint,
        fillColor,
        buttonBright: BUTTON_BRIGHT / 100,
        borderBright: BORDER_BRIGHT / 100,
        textBright: TEXT_BRIGHT / 100,
      }}
    >
      {children}
    </BrightContext.Provider>
  );
};

export const useBrightContext = () => {
  const contextValue = React.useContext(BrightContext);
  if (contextValue === null) {
    throw new Error("useBrightContext must be used within a BrightContextProvider");
  }
  return contextValue;
};
