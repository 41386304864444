import type { EramView } from "types/eramView";
import {
  advCreationTemplateView,
  advDeleteView,
  advFreetextTemplateView,
  advUplinkTemplateView,
  cpdlcAdvView,
  cpdlcHistView,
  cpdlcMsgOutView,
  cpdlcTocSetView,
} from "@poscon/shared-frontend";

export const viewTitleMap: Partial<
  Record<
    EramView,
    {
      title: string;
      optionTitle: string;
    }
  >
> = {
  [advDeleteView]: { optionTitle: "", title: "DELETE ADVISORY" },
  [advCreationTemplateView]: {
    optionTitle: "",
    title: "ADVISORY CREATION/EDIT TEMPLATE",
  },
  [cpdlcAdvView]: {
    title: "ADVISORIES",
    optionTitle: "ADV",
  },
  [advUplinkTemplateView]: { optionTitle: "", title: "UPLINK TEMPLATE" },
  WX_REPORT: {
    title: "WX",
    optionTitle: "WX",
  },
  [advFreetextTemplateView]: {
    title: "",
    optionTitle: "FREETEXT TEMPLATE",
  },
  [cpdlcHistView]: {
    title: "HISTORY",
    optionTitle: "HIST",
  },
  [cpdlcMsgOutView]: {
    title: "MESSAGE OUT",
    optionTitle: "MSG OUT",
  },
  [cpdlcTocSetView]: {
    title: "SECTOR TOC SETTINGS",
    optionTitle: "TOC SET",
  },
  ALTIM_SET: {
    title: "ALTIM SET",
    optionTitle: "AS",
  },
  AUTO_HO_INHIB: {
    title: "AUTO HO INHIB",
    optionTitle: "AHI",
  },
  CFR: {
    title: "CFR",
    optionTitle: "CFR",
  },
  CODE_VIEW: {
    title: "CODE",
    optionTitle: "CODE",
  },
  CONFLCT_ALERT: {
    title: "CONFLICT ALERT",
    optionTitle: "CA",
  },
  CRR: {
    title: "CRR",
    optionTitle: "CRR",
  },
  DEPT_LIST: {
    title: "DEPARTURE",
    optionTitle: "DEPT",
  },
  FLIGHT_EVENT: {
    title: "FLIGHT EVENT",
    optionTitle: "FEL",
  },
  GROUP_SUP: {
    title: "GROUP SUP",
    optionTitle: "GS",
  },
  HOLD_LIST: {
    title: "HOLD",
    optionTitle: "HOLD",
  },
  INBND_LIST: {
    title: "INBOUND",
    optionTitle: "INBND",
  },
  MESSAGE_COMPOSE_AREA: { optionTitle: "MCA", title: "" },
  MESSAGE_RESPONSE_AREA: { optionTitle: "RA", title: "" },
  MRP_LIST: {
    title: "MRP",
    optionTitle: "MRP",
  },
  OUTAGE: {
    title: "OUTAGE",
    optionTitle: "OUTAGE",
  },
  RANGE_MENU: { optionTitle: "RANGE", title: "RANGE" },
  SAA_FILTER: {
    title: "SAA FILTER",
    optionTitle: "SAA FIL",
  },
  STATUS: {
    title: "STATUS",
    optionTitle: "STATUS",
  },
  TIME: { optionTitle: "TIME", title: "" },
  UA: {
    title: "UA",
    optionTitle: "UA",
  },
};

export const TBP: MouseEvent["button"] = 0;
export const TBE: MouseEvent["button"] = 1;
export const TBH: MouseEvent["button"] = 2;

export const functionKeyInsertValueMap = {
  F1: "QQ",
  F2: "QP",
  F3: "QZ",
  F4: "QX FP",
  F5: "QX",
  F6: "QB",
  F7: "AM",
  F8: "QT",
  F9: "QP J",
  F10: "QL",
  F11: "QU",
  F12: "QF",
} as Record<string, string>;

export const defaultNexradDisplayItem = {
  labelLine1: "NX 000",
  labelLine2: "600",
  nexradLayerFloor: 0,
  nexradLayerCeiling: 600,
};
