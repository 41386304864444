import React, { useEffect, useState } from "react";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import {
  altitudeLimitsSelector,
  ToolbarButtonPosition,
} from "~redux/slices/eramStateSlice";
import { processEramMessage } from "~redux/thunks/processEramMessage";
import type { EramButtonProps } from "components/buttons/EramButton";
import { EramBaseButton } from "components/buttons/EramButton";
import { stringToParsedTokenArray } from "@poscon/shared-types";
import { baseToggleButtonSelectedColor } from "components/buttons/EramToggleButton";
import type {
  BitmapText as PixiBitmapText,
  FederatedPointerEvent,
} from "pixi.js";
import {
  colorNameMap,
  computeColor,
  eramFontDimensionMap,
  eramFontNameMap,
  EramInput,
  eramTextDimensionMap,
  useInputProps,
  usePixiMouseEventListener,
  useUiIsLocked,
  WindowPosition,
} from "@poscon/shared-frontend";
import { useButtonContext } from "contexts/buttonContext";
import { ButtonMenuContainer } from "components/buttons/ButtonMenuContainer";
import { BitmapText, Container } from "@pixi/react";
import { eramButtonActionMap } from "~redux/thunks/eramButtonActionMap";

const fontDimension = eramFontDimensionMap[eramFontNameMap[1]];

const altLimRegex = /^\d\d\dB\d\d\d$/;

type AltLimInputElement = "ALL" | "TARGETS" | "LDBS";
const altLimInputLabels = {
  ALL: "ALTITUDE LIMITS",
  TARGETS: "TARGETS",
  LDBS: "LDBS",
};
type AltLimInputProps = {
  initialValue?: string;
  elementType: AltLimInputElement;
  showInvalid: boolean;
  onLabelClick?: (event: FederatedPointerEvent) => void;
  setInput: (value: string) => void;
  onEnter: () => void;
  inputX: number;
  y: number;
};
const AltLimInput = ({
  elementType,
  showInvalid,
  onLabelClick,
  setInput,
  onEnter,
  initialValue = "",
  inputX,
  y,
}: AltLimInputProps) => {
  const labelRef = React.useRef<PixiBitmapText>(null);
  const { value, ...inputProps } = useInputProps(
    `button/altLim/${elementType}`,
    initialValue,
    onEnter,
    {
      resetOnMouseDown: true,
      maxLength: 7,
      initialShowInput: true,
      keyPredicate: (_, newInput) => {
        return /^\d{0,3}B?\d{0,3}$/.test(newInput);
      },
    },
  );
  const textBright =
    useRootSelector((state) => state.eram.brightness.TEXT_BRIGHT) / 100;
  const fontName = eramFontNameMap[1];
  const inputWidth = eramFontDimensionMap[fontName].width * 8 + 2;

  usePixiMouseEventListener((event) => onLabelClick?.(event), labelRef, true);

  const tint = computeColor(colorNameMap.white, textBright);

  useEffect(() => {
    setInput(value);
  }, [value, setInput]);

  return (
    <Container eventMode="static">
      <BitmapText
        ref={labelRef}
        text={altLimInputLabels[elementType]}
        x={4}
        y={y}
        eventMode="static"
        style={{ tint, fontName }}
      />
      <EramInput
        {...inputProps}
        value={value}
        width={fontDimension.width * 8 + 4}
        height={fontDimension.height + 3}
        x={inputX}
        y={y - 1}
        fontSize={1}
        tint={tint}
        fill={0}
        borderTint={colorNameMap.grey}
      />
      {showInvalid && (
        <BitmapText
          text="INVALID"
          x={inputX + inputWidth + 5}
          y={y}
          eventMode="none"
          style={{
            tint: computeColor(colorNameMap.yellow, textBright),
            fontName,
          }}
        />
      )}
    </Container>
  );
};

type AltLimInputContainerProps = {
  buttonPos?: WindowPosition;
  toolbarPos?: Pick<ToolbarButtonPosition, "row" | "col">;
  close: () => void;
};
const AltLimInputContainer = ({ close, buttonPos, toolbarPos }: AltLimInputContainerProps) => {
  const dispatch = useRootDispatch();
  const { buttonHeight } = useButtonContext();
  const altLimits = useRootSelector(altitudeLimitsSelector);
  const [showAll, setShowAll] = useState(true);
  const [showAllInvalid, setShowAllInvalid] = useState(false);
  const [showTargetsInvalid, setShowTargetsInvalid] = useState(false);
  const [showLdbInvalid, setShowLdbInvalid] = useState(false);
  const [allInputValue, setAllInputValue] = useState("");
  const [targetsInputValue, setTargetsInputValue] = useState("");
  const [ldbInputValue, setLdbInputValue] = useState("");

  const invalidSpacing =
    (showAll && showAllInvalid) ||
      (!showAll && (showTargetsInvalid || showLdbInvalid))
      ? eramTextDimensionMap[1].width * 8
      : 0;

  const onEnter = () => {
    const allValid = altLimRegex.test(allInputValue);
    const targetsValid = altLimRegex.test(targetsInputValue);
    const ldbValid = altLimRegex.test(ldbInputValue);

    if (showAll) {
      if (allValid) {
        dispatch(
          processEramMessage(stringToParsedTokenArray(`QD ${allInputValue}`)),
        );
        close();
        return;
      }
      setShowAllInvalid(true);
    } else {
      if (targetsValid && ldbValid) {
        dispatch(
          processEramMessage(
            stringToParsedTokenArray(
              `QD ${targetsInputValue} ${ldbInputValue}`,
            ),
          ),
        );
        close();
        return;
      }
      setShowTargetsInvalid(!targetsValid);
      setShowLdbInvalid(!ldbValid);
    }
  };

  const initialLimits = altLimits.limits
    ? `${altLimits.limits.min.toString().padStart(3, "0")}B${altLimits.limits.max.toString().padStart(3, "0")}`
    : "";
  const initialTargets = altLimits.targets
    ? `${altLimits.targets.min.toString().padStart(3, "0")}B${altLimits.targets.max.toString().padStart(3, "0")}`
    : "";
  const initialLdbs = altLimits.ldbs
    ? `${altLimits.ldbs.min.toString().padStart(3, "0")}B${altLimits.ldbs.max.toString().padStart(3, "0")}`
    : "";

  return (
    <ButtonMenuContainer
      buttonId="ALT_LIM"
      parentX={buttonPos?.x}
      parentY={buttonPos?.y}
      width={
        4 + eramTextDimensionMap[1].width * (showAll ? 26 : 18) + invalidSpacing
      }
      height={
        showAll ? buttonHeight + 2 : eramTextDimensionMap[1].height * 2 + 23
      }
      fillColor={0x272d2a}
      anchor={toolbarPos?.row === 0 ? "top" : "bottom"}
    >
      {showAll ? (
        <AltLimInput
          key="ALL"
          initialValue={initialLimits}
          onLabelClick={() => setShowAll((prev) => !prev)}
          showInvalid={showAllInvalid}
          elementType="ALL"
          setInput={setAllInputValue}
          onEnter={onEnter}
          inputX={
            eramTextDimensionMap[1].width * (altLimInputLabels.ALL.length + 2)
          }
          y={buttonHeight / 2 - eramTextDimensionMap[1].height / 2}
        />
      ) : (
        <>
          <AltLimInput
            key="TARGETS"
            initialValue={initialTargets}
            onLabelClick={() => setShowAll((prev) => !prev)}
            showInvalid={showTargetsInvalid}
            elementType="TARGETS"
            setInput={setTargetsInputValue}
            onEnter={onEnter}
            inputX={
              eramTextDimensionMap[1].width *
              (altLimInputLabels.TARGETS.length + 2)
            }
            y={6}
          />
          <AltLimInput
            key="LDBS"
            initialValue={initialLdbs}
            onLabelClick={() => setShowAll((prev) => !prev)}
            showInvalid={showLdbInvalid}
            elementType="LDBS"
            setInput={setLdbInputValue}
            onEnter={onEnter}
            inputX={
              eramTextDimensionMap[1].width *
              (altLimInputLabels.TARGETS.length + 2)
            }
            y={eramTextDimensionMap[1].height + 14}
          />
        </>
      )}
    </ButtonMenuContainer>
  );
};

export const EramAltLimButton = (props: EramButtonProps) => {
  const uiIsLocked = useUiIsLocked();
  const dispatch = useRootDispatch();
  const selected = useRootSelector((state) =>
    eramButtonActionMap[props.buttonId]?.selected?.(state, props.path),
  );
  const buttonAction = () =>
    eramButtonActionMap[props.buttonId]?.action?.(props.path);
  const onmousedown = () => {
    if (!uiIsLocked && buttonAction) {
      dispatch(buttonAction());
    }
  };
  return (
    <EramBaseButton
      {...props}
      onmousedown={onmousedown}
      baseBgColor={
        selected ? baseToggleButtonSelectedColor : colorNameMap.black
      }
      zIndex={selected ? 10 : 0}
    >
      {selected && <AltLimInputContainer toolbarPos={props.toolbarPosition} buttonPos={props.position} close={onmousedown} />}
    </EramBaseButton>
  );
};
