import React, { useRef } from "react";
import { CpdlcTocHeld, cpdlcTocHeldView, setSelectedHeldToc, useCenterCursor } from "@poscon/shared-frontend";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import type { Container as PixiContainer } from "pixi.js";
import { viewPositionSelector } from "~redux/slices/eramStateSlice";
import { useDragging } from "hooks/useDragging";
import { layerZIndexMap } from "~/layerZIndexMap";
import { useEventMode } from "components/utils/InteractiveContainer";
import type { FlightplanId } from "@poscon/shared-types";
import { cpdlcSessionSelector, flightplanSelector } from "~redux/slices/aircraftSlice";
import { processEramMessage } from "~redux/thunks/processEramMessage";

const view = cpdlcTocHeldView;

type TocHeldProps = {
  fpId: FlightplanId;
};
export const CpdlcTocHeldPrompt = ({ fpId }: TocHeldProps) => {
  const dispatch = useRootDispatch();
  const ref = useRef<PixiContainer | null>(null);
  const pos = useRootSelector((state) => viewPositionSelector(state, view));
  const { startDrag } = useDragging(ref, view);
  const eventMode = useEventMode();
  const fp = useRootSelector((state) => flightplanSelector(state, fpId));
  const session = useRootSelector((state) => cpdlcSessionSelector(state, fpId));

  useCenterCursor(ref);

  return fp && session ? (
    <CpdlcTocHeld
      ref={ref}
      pos={pos}
      startDrag={startDrag}
      zIndex={layerZIndexMap.prompts + 1}
      eventMode={eventMode}
      session={session}
      fp={fp}
      dispatchCommand={(command) => dispatch(processEramMessage(command))}
      closeMenu={() => dispatch(setSelectedHeldToc(null))}
    />
  ) : null;
};
