import React from "react";
import { View } from "components/utils/View";
import {
  backgroundOption,
  borderOption,
  brightOption,
  fontOption,
  linesOption,
} from "~redux/slices/viewOptionSlice";

const view = "GROUP_SUP";

const optionMap = {
  background: backgroundOption(view),
  border: borderOption(view),
  lines: linesOption(view, 5, 25),
  font: fontOption(view),
  bright: brightOption(view),
};

export const GroupSuppression = () => {
  return <View width={16} height={0} view="GROUP_SUP" optionMap={optionMap} />;
};
