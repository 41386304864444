import type { MutableRefObject } from "react";
import type React from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import type { WindowPosition } from "@poscon/shared-frontend";
import { connectionSelector } from "@poscon/shared-frontend";
import {
  mergeRefs,
  colorNameMap,
  computeColor,
  eramFontNameMap,
} from "@poscon/shared-frontend";
import type { ButtonPath, EramButtonId } from "types/eramButton";
import {
  isNonTransparentButton,
  isPriorityButton,
  masterToolbarButtonList,
} from "types/eramButton";
import { useTearOffContext } from "contexts/tearOffContext";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import {
  macroButtonMapSelector,
  menuButtonPathsSelector,
  setTearOffButtonPosition,
  tearOffButtonPositionSelector,
} from "~redux/slices/eramStateSlice";
import { TBE, TBP } from "~/eramConstants";
import { eramButtonActionMap } from "~redux/thunks/eramButtonActionMap";
import type {
  ColorSource,
  Container as PixiContainer,
  FederatedEventHandler,
  Graphics as PixiGraphics,
  Rectangle,
} from "pixi.js";
import { buttonTextPaddingY, useButtonContext } from "contexts/buttonContext";
import { useSituationDisplay } from "contexts/sdContext";
import { Cross } from "components/utils/Cross";
import { BitmapText, Container, Graphics } from "@pixi/react";
import { InteractiveContainer } from "components/utils/InteractiveContainer";
import { LogicalPosition } from "@tauri-apps/api/window";
import { WebviewWindow } from "@tauri-apps/api/webviewWindow";
import { layerZIndexMap } from "~/layerZIndexMap";
import { useWindowSize } from "usehooks-ts";

const baseTearoffColor = new Uint8Array([0xd0, 0xd0, 0x46]);
const baseBorderColor = new Uint8Array([0xad, 0xad, 0xad]);

function getButtonPosition(
  buttonId: EramButtonId | "MACRO_BUTTON",
  position: WindowPosition,
  windowWidth: number,
  windowHeight: number,
  buttonWidth: number,
  buttonHeight: number,
  sdRect: Rectangle,
) {
  if (isPriorityButton(buttonId)) {
    return {
      x: Math.max(
        sdRect.x,
        Math.min(sdRect.x + sdRect.width - buttonWidth, position.x),
      ),
      y: Math.max(
        sdRect.y,
        Math.min(sdRect.y + sdRect.height - buttonHeight - 1, position.y),
      ),
    };
  }
  if (windowWidth === 0 || windowHeight === 0) {
    return position;
  }
  return {
    x: Math.min(windowWidth - buttonWidth, position.x),
    y: Math.min(windowHeight - buttonHeight - 1, position.y),
  };
}

export type EramButtonProps<
  T extends EramButtonId | "MACRO_BUTTON" = EramButtonId,
> = {
  path: ButtonPath;
  overrideText?: string | null;
  disabled?: boolean;
  // actual button function
  onmousedown?: FederatedEventHandler;
  onTearOffClick?: FederatedEventHandler;
  delTearOff?: FederatedEventHandler;
  zIndex?: number;
  // torn off button position
  position?: WindowPosition;
  // hidden because another submenu is open within the menu where the button is displayed
  hidden?: boolean;
  // whether the button is in the menu of another button, i.e., inside a ButtonContainer
  isSubMenu?: boolean;
  isLockedOnMasterToolbar?: boolean;
  toolbarDirection?: "horizontal" | "vertical";
  buttonId: T;
  containerRef?: MutableRefObject<PixiContainer | null>;
  bodyRef?: MutableRefObject<PixiContainer | null>;
  macroLabel?: string;
  baseBgColor?: Uint8Array;
  baseTextColor?: Uint8Array;
  textColorOverride?: ColorSource;
  toolbarPosition?: { row: number; col: number };
  momentaryTriangleColor?: Uint8Array;
  children?: React.ReactNode;
};

type EramBaseButtonProps = EramButtonProps<EramButtonId | "MACRO_BUTTON">;
export const EramBaseButton = ({
  buttonId,
  containerRef,
  bodyRef,
  children,
  ...props
}: EramBaseButtonProps) => {
  const dispatch = useRootDispatch();
  const localTearOffRef = useRef<PixiGraphics | null>(null);
  const localBodyRef = useRef<PixiContainer | null>(null);
  const connection = useRootSelector(connectionSelector);
  const selectedPaths = useRootSelector(menuButtonPathsSelector);
  const { BUTTON_BRIGHT, BORDER_BRIGHT, TEXT_BRIGHT } = useRootSelector(
    (state) => state.eram.brightness,
  );
  const _disabled = useRootSelector(
    (state) =>
      (buttonId !== "MACRO_BUTTON" &&
        eramButtonActionMap[buttonId as EramButtonId]?.disabled?.(state)) ??
      false,
  );
  const [hover, setHover] = useState<null | "tearOff" | "body">(null);
  const { rect: sdRect } = useSituationDisplay();

  const { fontWidth, buttonWidth, buttonHeight, fontSize } = useButtonContext();

  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const _text = useRootSelector((state) =>
    buttonId === "MACRO_BUTTON"
      ? props.macroLabel
      : eramButtonActionMap[buttonId]?.text?.(state),
  );
  const {
    startTearOff,
    pendingDelTearOffButtonList,
    pendingDelMacroButtonList,
    toggleDelTearOffButtonPending,
    delTearOffCommandActive,
    submitDelTearOffEvent,
  } = useTearOffContext();
  const tearOffButtonPos = useRootSelector((state) =>
    buttonId === "MACRO_BUTTON"
      ? macroButtonMapSelector(state)[props.macroLabel!]
      : tearOffButtonPositionSelector(state, buttonId),
  );
  const toolbarButtonList = useRootSelector(
    (state) => state.eram.toolbarButtonPositions,
  );

  const text = props.overrideText ?? _text;

  const prevOptionValueRef = useRef(text);
  const prevPosRef = useRef<WindowPosition>({ x: -1, y: -1 });

  useEffect(() => {
    if (window.__TAURI__ && localBodyRef.current && buttonId !== "RANGE_MENU") {
      const rect = localBodyRef.current.getBounds();
      if (!prevPosRef.current) {
        prevPosRef.current = { x: rect.x, y: rect.y };
      } else if (
        prevPosRef.current.x !== rect.x ||
        prevPosRef.current.y !== rect.y
      ) {
        if (connection?.isActive && text !== prevOptionValueRef.current) {
          const newCursorPos = {
            x: rect.x + rect.width / 2,
            y: rect.y + rect.height / 2,
          };
          setTimeout(
            () =>
              WebviewWindow.getCurrent().setCursorPosition(
                new LogicalPosition(newCursorPos.x - 1, newCursorPos.y - 1),
              ),
            20,
          );
        }
      }
      setTimeout(() => {
        prevOptionValueRef.current = text;
      }, 20);
      prevPosRef.current = { x: rect.x, y: rect.y };
    }
  });

  const menuHidden =
    (props.path.includes("MASTER_TOOLBAR") &&
      selectedPaths.some(
        (p) => p.startsWith("MASTER_TOOLBAR") && !p.startsWith(props.path) && !p.endsWith("PREFSET")
      ) &&
      props.hidden === undefined) ||
    props.hidden;

  const isTornOff =
    buttonId !== "MACRO_BUTTON" &&
    ((((!props.toolbarDirection &&
      !masterToolbarButtonList.flat().includes(buttonId)) ||
      props.isLockedOnMasterToolbar) &&
      toolbarButtonList.filter((b) => b.buttonId === buttonId).length > 0) ||
      (!props.position && tearOffButtonPos !== null));

  const zIndex = isNonTransparentButton(buttonId)
    ? layerZIndexMap.priorityButtons
    : props.zIndex ?? 0;

  const showEmptyButton = /^CHKLST_\d?\d$/.test(buttonId) && !text;

  const disabled = buttonId !== "MACRO_BUTTON" && (props.disabled || _disabled);

  const textContent =
    props.overrideText === null
      ? ""
      : text?.replaceAll("_", " ") ?? buttonId.replaceAll("_", " ");

  const position = useMemo(() => {
    return props.position && sdRect && sdRect.width > 0 && sdRect.height > 0
      ? getButtonPosition(
        buttonId,
        props.position,
        windowWidth,
        windowHeight,
        buttonWidth,
        buttonHeight,
        sdRect,
      )
      : props.position;
  }, [
    sdRect,
    buttonHeight,
    buttonId,
    buttonWidth,
    props.position,
    windowHeight,
    windowWidth,
  ]);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (
      position &&
      props.position &&
      (props.position.x !== position.x || props.position.y !== position.y)
    ) {
      setTimeout(
        () =>
          dispatch(
            setTearOffButtonPosition({
              button: buttonId,
              macroLabel: props.macroLabel,
              position,
            }),
          ),
        20,
      );
    }
    return () => clearTimeout(timeoutId);
  }, [position, props.position, buttonId, dispatch, props.macroLabel]);

  const withTearoff = !buttonId.startsWith("DRAW_");
  const bodyX = withTearoff ? fontWidth + 1 : 0;
  const bodyWidth = buttonWidth - bodyX;

  let x: number;
  let y: number;
  if (props.toolbarPosition) {
    x = props.toolbarPosition.col * (buttonWidth + 1);
    y = props.toolbarPosition.row * (buttonHeight + 1);
    if (props.toolbarDirection && !props.isSubMenu) {
      y += 1;
    }
  } else {
    x = position?.x ?? 0;
    y = position?.y ?? 0;
  }

  const containerName = `BUTTON_${buttonId === "MACRO_BUTTON" ? `MACRO_${props.macroLabel}` : buttonId}`;

  const graphicsEventMode =
    buttonId === "DRAW_COLOR_PALETTE" ? "none" : "static";

  const bgColor = computeColor(
    props.baseBgColor ?? colorNameMap.black,
    BUTTON_BRIGHT / 100,
  );

  return (
    <InteractiveContainer
      name={containerName}
      x={x}
      y={y}
      zIndex={zIndex}
      ref={containerRef}
      onmousedown={(event) => {
        if (
          delTearOffCommandActive &&
          !isPriorityButton(buttonId) &&
          !props.isLockedOnMasterToolbar
        ) {
          event.stopImmediatePropagation();
          if (event.button === TBP) {
            toggleDelTearOffButtonPending(buttonId, props.macroLabel);
          }
          if (event.button === TBE) {
            submitDelTearOffEvent({ buttonId, macroLabel: props.macroLabel });
          }
        }
      }}
    >
      {withTearoff && (
        <Graphics
          name="TEAROFF"
          eventMode={graphicsEventMode}
          ref={localTearOffRef}
          onmousedown={(event) => {
            if (props.onTearOffClick) {
              event.stopImmediatePropagation();
              props.onTearOffClick(event);
            } else if (buttonId && !isTornOff && localTearOffRef.current) {
              startTearOff(
                event,
                buttonId,
                localTearOffRef.current,
                props.macroLabel,
              );
            }
          }}
          onmouseenter={() => {
            setHover("tearOff");
          }}
          onmouseleave={() => {
            setHover((prev) => (prev === "tearOff" ? null : prev));
          }}
          draw={(graphics) => {
            graphics.clear();
            graphics.lineStyle(
              1,
              hover === "tearOff" && !isTornOff
                ? 0xf0f0f0
                : computeColor(baseBorderColor, BORDER_BRIGHT / 100),
            );
            graphics.beginFill(
              computeColor(
                isTornOff ? baseBorderColor : baseTearoffColor,
                BUTTON_BRIGHT / 100,
              ),
            );
            graphics.drawRect(0, 0, fontWidth, buttonHeight);
            graphics.endFill();
          }}
          visible={!menuHidden && !showEmptyButton}
        />
      )}
      <Container x={bodyX} name="BODY" ref={mergeRefs(localBodyRef, bodyRef)}>
        <Graphics
          eventMode={graphicsEventMode}
          onmouseenter={() => {
            setHover("body");
          }}
          onmouseleave={() => {
            setHover((prev) => (prev === "body" ? null : prev));
          }}
          onmousedown={(event) => {
            if (
              (props.overrideText === null || textContent !== "") &&
              (!delTearOffCommandActive || buttonId === "DELETE_TEAROFF")
            ) {
              event.stopImmediatePropagation();
              props.onmousedown?.(event);
            }
          }}
          draw={(graphics) => {
            graphics.clear();
            graphics.lineStyle(
              1,
              hover === "body"
                ? 0xf0f0f0
                : computeColor(baseBorderColor, BORDER_BRIGHT / 100),
            );
            graphics
              .beginFill(bgColor)
              .drawRect(0, 0, bodyWidth, buttonHeight)
              .endFill();
            if (props.momentaryTriangleColor) {
              graphics.lineStyle();
              graphics
                .beginFill(
                  computeColor(
                    props.momentaryTriangleColor,
                    BUTTON_BRIGHT / 100,
                  ),
                )
                .drawPolygon([
                  bodyWidth - 13,
                  1,
                  bodyWidth - 1,
                  1,
                  bodyWidth - 1,
                  12,
                ])
                .endFill();
            }
          }}
          visible={!menuHidden && !showEmptyButton}
        />
        <BitmapText
          text={textContent}
          eventMode="none"
          anchor={[0.5, 0]}
          x={Math.floor(bodyWidth / 2)}
          y={buttonTextPaddingY}
          fontName={eramFontNameMap[fontSize]}
          tint={
            props.textColorOverride ??
            computeColor(
              props.baseTextColor ?? colorNameMap.white,
              (TEXT_BRIGHT - (disabled ? 20 : 0)) / 100,
            )
          }
          maxWidth={fontWidth * 7}
          style={{
            fontName: eramFontNameMap[fontSize],
            tint: computeColor(
              props.baseTextColor ?? colorNameMap.white,
              (TEXT_BRIGHT - (disabled ? 20 : 0)) / 100,
            ),
            align: "center",
            maxWidth: fontWidth * 7,
          }}
          visible={!menuHidden && !showEmptyButton}
        />
      </Container>
      {!menuHidden && children}
      {!props.isLockedOnMasterToolbar &&
        !isPriorityButton(buttonId) &&
        ((buttonId !== "MACRO_BUTTON" &&
          pendingDelTearOffButtonList.includes(buttonId)) ||
          (props.macroLabel &&
            pendingDelMacroButtonList.includes(props.macroLabel))) &&
        !props.isSubMenu && <Cross />}
    </InteractiveContainer>
  );
};

export const EramButton = EramBaseButton;
