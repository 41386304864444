import React, { useEffect, useMemo, useRef, useState } from "react";
import type { FederatedPointerEvent, Graphics as PixiGraphics } from "pixi.js";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { useDragging } from "hooks/useDragging";
import { ViewMenu } from "components/utils/ViewMenu";
import {
  brightOption,
  buttonOption,
  counterOption,
  fontOption,
  viewOptionSelector,
} from "~redux/slices/viewOptionSlice";
import { pushZStack, viewPositionSelector } from "~redux/slices/eramStateSlice";
import { BitmapText, Container, Graphics } from "@pixi/react";
import type { EramFontSize } from "@poscon/shared-frontend";
import { setMraMessage } from "@poscon/shared-frontend";
import {
  useViewOptionSelected,
  colorNameMap,
  computeColor,
  eramFontNameMap,
  eramTextDimensionMap,
  mraMsgSelector,
  ScrollBar,
  usePixiMouseEventListener,
} from "@poscon/shared-frontend";
import { TBE, TBP } from "~/eramConstants";
import { useZIndex } from "hooks/useZIndex";
import { useBrightContext } from "contexts/brightnessContext";
import { InteractiveContainer } from "components/utils/InteractiveContainer";
import { ViewOptionContextProvider } from "contexts/viewOptionContext";
import { layerZIndexMap } from "~/layerZIndexMap";
import { chunkRows } from "@poscon/shared-types";
import { Rectangle } from "pixi.js";
import { useWindowSize } from "usehooks-ts";

const view = "MESSAGE_RESPONSE_AREA";

const optionMap = {
  width: counterOption(view, "width", "WIDTH", 30, 50, 8, "delta", 20),
  font: fontOption(view),
  bright: brightOption(view),
  clear: buttonOption(view, "CLEAR", 5, () => setMraMessage([])),
};

export const MessageResponseArea = React.memo(() => {
  const dispatch = useRootDispatch();
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const ref = useRef<PixiGraphics>(null);
  const viewOptions = useRootSelector((state) => viewOptionSelector(state, "MESSAGE_RESPONSE_AREA"));
  const _pos = useRootSelector((state) => viewPositionSelector(state, "MESSAGE_RESPONSE_AREA"));
  const [pageNumber, setPageNumber] = useState(0);
  const [scrollY, setScrollY] = useState(0);
  const msgPages = useRootSelector(mraMsgSelector);
  const { width: fontWidth, height: fontHeight } = eramTextDimensionMap[viewOptions.font as EramFontSize];
  const zIndex = useZIndex(view, ref);
  const { borderTint } = useBrightContext();

  const shouldScrollRows = msgPages.length === 1;

  const totalRows = useMemo(
    () => msgPages[pageNumber]?.flatMap((r) => chunkRows(r, viewOptions.width)).flat() ?? [],
    [msgPages, viewOptions.width],
  );
  const rows = shouldScrollRows ? totalRows.slice(scrollY, scrollY + 10) : totalRows;

  const { startDrag } = useDragging(ref, "MESSAGE_RESPONSE_AREA");

  const { selected: showOptions, openViewOption } = useViewOptionSelected(view);

  useEffect(() => {
    dispatch(pushZStack(view));
    setPageNumber(0);
    setScrollY(0);
  }, [msgPages]);

  usePixiMouseEventListener((event) => {
    switch (event.button) {
      case TBE:
        openViewOption();
        break;
      case TBP:
        void startDrag(event);
        break;
      default:
        break;
    }
  }, ref);

  const showScroll = msgPages.length > 1 || (shouldScrollRows && totalRows.length > 10);

  const baseWidth = fontWidth * viewOptions.width + 7;
  const width = baseWidth + (showScroll ? fontWidth + 4 : 0);
  const height = fontHeight * Math.max(4, rows.length) + 2;

  const pos = {
    x: Math.min(_pos.x, windowWidth - width),
    y: Math.min(_pos.y, windowHeight - height - 1),
  };

  const onScrollClick = (event: FederatedPointerEvent, sign: 1 | -1) => {
    event.stopImmediatePropagation();
    if (msgPages.length > 1) {
      setPageNumber((prev) => Math.max(0, Math.min(prev + sign, msgPages.length - 1)));
    } else if (totalRows.length > 10) {
      setScrollY((prev) => Math.max(0, Math.min(prev + sign, totalRows.length - 10)));
    }
  };

  const fontName = eramFontNameMap[viewOptions.font as EramFontSize];
  const tint = computeColor(colorNameMap.white, viewOptions.bright / 100);

  return (
    <ViewOptionContextProvider options={viewOptions}>
      <InteractiveContainer name="MRA" x={pos.x} y={pos.y} sortableChildren zIndex={101 + zIndex}>
        <Graphics
          ref={ref}
          eventMode="static"
          hitArea={new Rectangle(0, 0, baseWidth, height)}
          draw={(graphics) => {
            graphics.clear();
            graphics.lineStyle(1, borderTint);
            graphics.beginFill(0x000000).drawRect(0, 0, width, height).endFill();
          }}
        />
        <BitmapText
          zIndex={1}
          text={rows.join("\n")}
          x={4}
          y={1}
          eventMode="none"
          fontName={fontName}
          tint={tint}
          style={{ fontName, tint }}
        />
        {showScroll && (
          <ScrollBar
            x={baseWidth + 1}
            y={2}
            scrollUp={(event) => onScrollClick(event, -1)}
            scrollDown={(event) => onScrollClick(event, 1)}
            scrollUpDisabled={shouldScrollRows ? scrollY === 0 : pageNumber === 0}
            scrollDownDisabled={
              shouldScrollRows ? scrollY + 10 >= totalRows.length : pageNumber === msgPages.length - 1
            }
            height={height - 4}
            tint={tint}
            disabledTint={computeColor(colorNameMap.grey, viewOptions.bright / 100)}
            borderTint={borderTint}
            fontSize={viewOptions.font as EramFontSize}
          />
        )}
      </InteractiveContainer>
      {showOptions && (
        <Container x={pos.x} y={pos.y} sortableChildren zIndex={layerZIndexMap.viewMenu}>
          <ViewMenu view={view} parentWidth={width} options={optionMap} initialFocusOverrideIndex={3} />
        </Container>
      )}
    </ViewOptionContextProvider>
  );
});
