import type { RootState } from "~redux/store";
import { initialTimeOptions } from "components/utils/Time";
import type {
  ViewToggleOption as _ViewToggleOption,
  ViewCounterOption as _ViewCounterOption,
  ViewButtonOption as _ViewButtonOption,
  ViewOptionMapValue as _ViewOptionMapValue,
} from "@poscon/shared-frontend";
import {
  cpdlcAdvView,
  cpdlcHistView,
  cpdlcMsgOutView,
  cpdlcTocSetView,
  initialCpdlcAdvOptions,
  initialCpdlcHistViewOptions,
  initialMsgOutOptions,
  viewOptionFactory,
} from "@poscon/shared-frontend";
import type { EramView } from "types/eramView";
import { initialAltimSetOptions } from "components/initialViewOptions/InitialAltimSetOptions";
import { initialAHIOptions } from "components/initialViewOptions/InitialAHIOptions";
import { initialCodeOptions } from "components/initialViewOptions/InitialCodeOptions";
import { initialCfrOptions } from "components/initialViewOptions/initialCfrOptions";
import { initialChecklistOptions } from "components/initialViewOptions/initialChecklistOptions";
import { initialConflictAlertOptions } from "components/initialViewOptions/initialConflictAlertOptions";
import { initialCrrOptions } from "components/initialViewOptions/initialCrrOptions";
import { initialTocSetOptions } from "components/initialViewOptions/initialTocSetOptions";
import { initialDeptListOptions } from "components/initialViewOptions/initialDeptListOptions";
import { initialGroupSupOptions } from "components/initialViewOptions/initialGroupSupOptions";
import { initialHoldListOptions } from "components/initialViewOptions/initialHoldListOptions";
import { initialInbndListOptions } from "components/initialViewOptions/initialInbndListOptions";
import { initialMrpOptions } from "components/initialViewOptions/initialMrpOptions";
import { initialSaaOptions } from "components/initialViewOptions/initialSaaOptions";
import { initialStatusOptions } from "components/initialViewOptions/initialStatusOptions";
import { initialWxOptions } from "components/initialViewOptions/initialWxOptions";
import { initialMcaOptions } from "components/initialViewOptions/initialMcaOptions";
import { initialMraOptions } from "components/initialViewOptions/initialMraOptions";
import { initialRangeMenuOptions } from "components/initialViewOptions/initialRangeMenuOptions";
import { CheckListButtonId } from "~/types/eramButton";

const initialState = {
  [cpdlcAdvView]: initialCpdlcAdvOptions,
  [cpdlcHistView]: initialCpdlcHistViewOptions,
  [cpdlcMsgOutView]: initialMsgOutOptions,
  [cpdlcTocSetView]: initialTocSetOptions,
  ALTIM_SET: initialAltimSetOptions,
  AUTO_HO_INHIB: initialAHIOptions,
  CFR: initialCfrOptions,
  CODE_VIEW: initialCodeOptions,
  CONFLCT_ALERT: initialConflictAlertOptions,
  CRR: initialCrrOptions,
  DEPT_LIST: initialDeptListOptions,
  GROUP_SUP: initialGroupSupOptions,
  HOLD_LIST: initialHoldListOptions,
  INBND_LIST: initialInbndListOptions,
  MESSAGE_COMPOSE_AREA: initialMcaOptions,
  MESSAGE_RESPONSE_AREA: initialMraOptions,
  MRP_LIST: initialMrpOptions,
  RANGE_MENU: initialRangeMenuOptions,
  SAA_FILTER: initialSaaOptions,
  STATUS: initialStatusOptions,
  TIME: initialTimeOptions,
  WX_REPORT: initialWxOptions,
  ...Object.fromEntries(Array.from({ length: 17 * 6 }, (_, i) => [`CHKLST_${i}`, initialChecklistOptions] as const)) as Record<CheckListButtonId, typeof initialChecklistOptions>,
};

export type ViewOptionState = typeof initialState & Partial<Record<EramView, unknown>>;

export const { viewOptionSlice, viewOptionSelector, setViewOption, deltaViewOption, setViewOptionState } =
  viewOptionFactory<EramView, ViewOptionState>(initialState);

export const reducer = viewOptionSlice.reducer;

export type ViewCounterOption = _ViewCounterOption<RootState>;
export type ViewToggleOption = _ViewToggleOption<RootState>;
export type ViewButtonOption = _ViewButtonOption<RootState>;
export type ViewOptionMapValue = _ViewOptionMapValue<RootState>;

export {
  backgroundOption,
  brightOption,
  counterOption,
  fontOption,
  linesOption,
  toggleOption,
  buttonOption,
  borderOption,
} from "@poscon/shared-frontend";
