import type { EramFontSize } from "@poscon/shared-frontend";

export const initialSaaOptions = {
  lines: 15,
  background: true,
  bright: 80,
  font: 2 as EramFontSize,
  pendingFont: 2,
  border: true,
  color: 80,
};
