import React, { useState } from "react";
import type { Graphics } from "pixi.js";
import { assert } from "@poscon/shared-types";
import { useSituationDisplay } from "contexts/sdContext";
import { useStableCallback } from "@poscon/shared-frontend";

type DragContextValue = {
  anyDragging: boolean;
  setAnyDragging: React.Dispatch<React.SetStateAction<boolean>>;
  draggingHandler: (event: MouseEvent, inSD?: boolean) => void;
  getDraggingOutlinePositionAndDimension: () => {
    x: number;
    y: number;
    width: number;
    height: number;
  };
  setDraggingOutlinePositionAndDimension: (x: number, y: number, width: number, height: number) => void;
  setDraggingOutlineVisible: (visible: boolean) => void;
};
export const DragContext = React.createContext<DragContextValue | null>(null);

type DragContextProviderProps = {
  children: React.ReactNode;
  draggingOutlineRef: React.RefObject<Graphics | null>;
};
export const DragContextProvider = ({ children, draggingOutlineRef }: DragContextProviderProps) => {
  const { rect } = useSituationDisplay();
  const [anyDragging, setAnyDragging] = useState(false);

  const setDraggingOutlinePositionAndDimension = useStableCallback(
    (x: number, y: number, width: number, height: number) => {
      assert(draggingOutlineRef.current !== null, "draggingOutlineRef.current is null");
      draggingOutlineRef.current.x = x;
      draggingOutlineRef.current.y = y;
      draggingOutlineRef.current.clear();
      draggingOutlineRef.current.lineStyle(1, 0xffffff);
      draggingOutlineRef.current.drawRect(0, 0, width, height);
    },
  );

  const setDraggingOutlineVisible = useStableCallback((visible: boolean) => {
    assert(draggingOutlineRef.current !== null, "draggingOutlineRef.current is null");
    draggingOutlineRef.current.visible = visible;
  });

  const getDraggingOutlinePositionAndDimension = useStableCallback(() => {
    assert(draggingOutlineRef.current !== null, "draggingOutlineRef.current is null");
    return {
      x: draggingOutlineRef.current.x,
      y: draggingOutlineRef.current.y,
      height: draggingOutlineRef.current.height,
      width: draggingOutlineRef.current.width,
    };
  });

  const draggingHandler = useStableCallback((event: MouseEvent, inSD = false) => {
    assert(draggingOutlineRef.current !== null, "draggingOutlineRef.current is null");
    event.stopPropagation();
    let x = event.pageX;
    let y = event.pageY;
    const { width, height } = getDraggingOutlinePositionAndDimension();
    if (inSD) {
      const { width: outlineWidth, height: outlineHeight } = getDraggingOutlinePositionAndDimension();
      x = Math.min(Math.max(x, rect.x), rect.x + rect.width - outlineWidth + 1);
      y = Math.min(Math.max(y, rect.y), rect.y + rect.height - outlineHeight + 1);
    } else {
      x = Math.min(x, window.innerWidth - width + 1);
      y = Math.min(y, window.innerHeight - height + 1);
    }
    draggingOutlineRef.current.x = Math.max(1, x);
    draggingOutlineRef.current.y = Math.max(0, y);
  });

  return (
    <DragContext.Provider
      value={{
        anyDragging,
        setAnyDragging,
        setDraggingOutlinePositionAndDimension,
        setDraggingOutlineVisible,
        getDraggingOutlinePositionAndDimension,
        draggingHandler,
      }}
    >
      {children}
    </DragContext.Provider>
  );
};

export const useDragContext = () => {
  const contextValue = React.useContext(DragContext);
  if (contextValue === null) {
    throw new Error("useDragContext must be used within a DragContextProvider");
  }
  return contextValue;
};

export const useAnyDragging = () => {
  const { anyDragging, setAnyDragging } = useDragContext();
  return { anyDragging, setAnyDragging };
};
