import React from "react";
import { View } from "components/utils/View";
import {
  backgroundOption,
  borderOption,
  brightOption,
  fontOption,
  viewOptionSelector,
} from "~redux/slices/viewOptionSlice";
import { useRootSelector } from "~redux/hooks";
import type { EramFontSize } from "@poscon/shared-frontend";
import { eramFontDimensionMap, eramFontNameMap } from "@poscon/shared-frontend";
import { ViewOptionContextProvider } from "contexts/viewOptionContext";

const view = "STATUS";

const optionMap = {
  background: backgroundOption(view),
  border: borderOption(view),
  font: fontOption(view),
  bright: brightOption(view),
};

export const Status = () => {
  const viewOptions = useRootSelector((state) => viewOptionSelector(state, view));

  const fontDimension = eramFontDimensionMap[eramFontNameMap[viewOptions.font as EramFontSize]];

  const height = fontDimension.height * 2 + 4;
  return (
    <ViewOptionContextProvider options={viewOptions}>
      <View width={20} height={height} view={view} optionMap={optionMap} />
    </ViewOptionContextProvider>
  );
};
