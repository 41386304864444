import React from "react";
import { useTearOff } from "hooks/useTearOff";
import type { EramButtonId } from "types/eramButton";
import type { EramButtonProps } from "components/buttons/EramButton";
import { ButtonTypeMap } from "components/buttons/ButtonTypeMap";

type TearOffButtonProps = {
  buttonId: EramButtonId | "MACRO_BUTTON";
  macroLabel?: string;
  componentProps?: EramButtonProps;
};
export const EramTearOffButton = React.memo(({ buttonId, macroLabel, componentProps }: TearOffButtonProps) => {
  const { position, startDrag } = useTearOff(buttonId, macroLabel);

  const Component = ButtonTypeMap[buttonId];

  return (
    <Component
      {...componentProps}
      path={`/${buttonId}`}
      buttonId={buttonId}
      position={position}
      onTearOffClick={startDrag}
      macroLabel={macroLabel}
    />
  );
});
