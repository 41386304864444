import { checkListsButtonList, viewsButtonList } from "types/eramButton";
import {
  advCreationTemplateView,
  advDeleteView,
  advFreetextTemplateView,
  advUplinkTemplateView,
  aircraftMenuList,
  cpdlcMiniMOView,
  cpdlcPidView,
  cpdlcTocHeldView,
  eligibilityOverrideView,
} from "@poscon/shared-frontend";

export const aselMenus = [
  ...aircraftMenuList,
  // PROMPTS
  "PREV_ROUTE_MENU",
  "CANCEL_HOLD_MENU",
  "CHANGE_DEST_MENU",
  "PO_MENU",
  cpdlcMiniMOView,
  cpdlcPidView,
] as const;

export const miscMenus = [
  advCreationTemplateView,
  advUplinkTemplateView,
  advDeleteView,
  advFreetextTemplateView,
  eligibilityOverrideView,
  cpdlcTocHeldView,
] as const;

export const eramViews = [
  "SETTINGS_MENU",
  "MESSAGE_COMPOSE_AREA",
  "MESSAGE_RESPONSE_AREA",
  "STATUS",
  "OUTAGE",
  "TIME",
  "RANGE_MENU",
  ...miscMenus,
  ...checkListsButtonList.flat(),
  ...viewsButtonList.flat(),
  ...aselMenus,
] as const;

export type EramView = (typeof eramViews)[number];
export type EramAselMenu = (typeof aselMenus)[number];
export type EramMiscMenu = (typeof miscMenus)[number];
