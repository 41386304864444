import React, { useCallback, useEffect, useState } from "react";
import type { EramMessageElement, Nullable } from "@poscon/shared-types";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import type { MacroButton } from "~redux/slices/eramStateSlice";
import {
  addMacro,
  macroButtonMapSelector,
  updateMacro,
} from "~redux/slices/eramStateSlice";
import type { EramButtonId } from "types/eramButton";
import type { EramButtonProps } from "components/buttons/EramButton";
import { EramBaseButton } from "components/buttons/EramButton";
import { EramCommandButton } from "components/buttons/EramCommandButton";
import { baseToggleButtonSelectedColor } from "components/buttons/EramToggleButton";
import {
  EramInput,
  useInputProps,
  colorNameMap,
  eramFontNameMap,
  eramTextDimensionMap,
  dispatchInsertCommandEvent,
} from "@poscon/shared-frontend";

import { BitmapText, Container, Graphics } from "@pixi/react";
import { ButtonMenuContainer } from "components/buttons/ButtonMenuContainer";
import { Rectangle } from "pixi.js";
import { useBrightContext } from "contexts/brightnessContext";

const fontName = eramFontNameMap[1];
const fontDimension = eramTextDimensionMap[1];

/* eslint-disable react/no-array-index-key */

type MacroInputRowProps = {
  name?: string;
  initialValue: string;
  index: number;
  setInput: (value: string, index: number) => void;
  addRow?: () => void;
  y?: number;
};
const MacroInputRow = ({
  initialValue,
  setInput,
  addRow,
  index,
  name = `MACRO_EDIT_ROW_${index}`,
  y,
}: MacroInputRowProps) => {
  const { tint } = useBrightContext();
  const { value, setInputValue, ...inputProps } = useInputProps(
    `button/macro/row/${name}`,
    initialValue,
    () => {
      if (index !== -1 && !value.endsWith(";")) {
        setInputValue(`${value};`);
      }
      addRow?.();
    },
    {
      maxLength: 12,
      initialShowInput: true,
      resetOnMouseDown: true,
    },
  );

  useEffect(() => {
    setInput(value, index);
  }, [index, value, setInput]);

  return (
    <EramInput
      {...inputProps}
      name={name}
      width={12 * fontDimension.width}
      x={0}
      y={y ?? index * (fontDimension.height + 2) + 2}
      tint={tint}
      value={value}
    />
  );
};
type MacroEditAreaProps = {
  macroButton?: MacroButton;
  onClose: (commands: EramMessageElement[][], label: string) => void;
};
const MacroEditArea = ({ macroButton, onClose }: MacroEditAreaProps) => {
  const [label, setLabel] = useState(macroButton?.label ?? "");
  const [commands, setCommands] = useState<EramMessageElement[][]>(
    macroButton ? structuredClone(macroButton.commands) : [[]],
  );
  const [showInvalid, setShowInvalid] = useState(false);

  const setCommandValue = useCallback((value: string, index: number) => {
    setCommands((prev) => {
      const newCommands = [...prev];
      newCommands[index] = value.split("").map((c) => ({ token: c }));
      return newCommands;
    });
  }, []);

  const addRow = () => {
    if (commands.length < 6) {
      setCommands((prev) => [...prev, []]);
    }
  };

  const width = 12 * fontDimension.width + 2;

  return (
    <ButtonMenuContainer
      buttonId="MACRO_EDIT"
      width={width}
      height={
        (commands.length + (showInvalid ? 3 : 2)) * (fontDimension.height + 2) +
        12
      }
      fillColor={0x303030}
    >
      {commands.map((command, index) => (
        <MacroInputRow
          key={index}
          index={index}
          initialValue={command.map((c) => c.token).join("")}
          setInput={setCommandValue}
          addRow={addRow}
        />
      ))}
      <Container
        name="LABEL_AND_SAVE_MSG"
        x={-1}
        y={commands.length * (fontDimension.height + 2) + 4}
      >
        <Graphics
          eventMode="none"
          name="SAVE_MSG"
          draw={(graphics) => {
            graphics.clear();
            graphics.lineStyle(1, 0xadadad);
            graphics.moveTo(0, 0);
            graphics.lineTo(width - 1, 0);
            graphics.moveTo(0, fontDimension.height + 6);
            graphics.lineTo(width - 1, fontDimension.height + 6);
            if (showInvalid) {
              graphics.moveTo(0, fontDimension.height * 2 + 10);
              graphics.lineTo(width - 1, fontDimension.height * 2 + 10);
            }
          }}
        />
        <MacroInputRow
          index={-1}
          initialValue={label}
          setInput={setLabel}
          y={1}
        />
        <BitmapText
          name="SAVE_MSG"
          text="SAVE MSG"
          x={fontDimension.width * 2 + 1}
          y={fontDimension.height + 9}
          hitArea={
            new Rectangle(
              -fontDimension.width * 2,
              0,
              width,
              fontDimension.height,
            )
          }
          style={{ fontName, tint: 0xd0d0d0 }}
          eventMode="static"
          onmousedown={() => {
            if (label.length === 0 || commands.some((c) => c.length === 0)) {
              setShowInvalid(true);
              return;
            }
            const _commands = commands.reduce<EramMessageElement[][]>(
              (acc, cur) => {
                acc.push(cur);
                return acc;
              },
              [],
            );
            onClose(_commands, label);
          }}
        />
        {showInvalid && (
          <BitmapText
            eventMode="none"
            name="INVALID"
            text="INVALID"
            x={fontDimension.width * 2.5}
            y={fontDimension.height * 2 + 13}
            style={{ fontName, tint: 0xadad00 }}
          />
        )}
      </Container>
    </ButtonMenuContainer>
  );
};

type MacroEditRowProps = {
  index: number;
  label: string;
  selected: boolean;
  onmousedown: () => void;
};
const MacroEditRow = ({
  index,
  label,
  selected,
  onmousedown,
}: MacroEditRowProps) => {
  const tint = selected ? 0 : 0xd0d0d0;

  return (
    <Container
      x={1}
      y={1 + index * (fontDimension.height + 3)}
      eventMode="static"
    >
      <Graphics
        eventMode="static"
        hitArea={
          new Rectangle(0, 0, 8 * fontDimension.width, fontDimension.height)
        }
        onmousedown={onmousedown}
        draw={(graphics) => {
          graphics.clear();
          if (selected) {
            graphics.beginFill(0xd0d0d0);
            graphics.drawRect(
              0,
              0,
              8 * fontDimension.width,
              fontDimension.height,
            );
          }
        }}
      />
      <BitmapText
        text={label}
        tint={tint}
        style={{ fontName, tint }}
        eventMode="none"
      />
    </Container>
  );
};

const MacroEditList = () => {
  const dispatch = useRootDispatch();
  const macroButtonMap = useRootSelector(macroButtonMapSelector);
  const [selectedLabel, setSelectedLabel] = useState<Nullable<string>>(null);
  const { fillColor } = useBrightContext();

  const macroLabels = Object.keys(macroButtonMap);

  const onmousedownHandler = (label: string) => {
    setSelectedLabel((prev) => {
      if (prev === label) {
        return null;
      }
      return label;
    });
  };

  const onClose = (commands: EramMessageElement[][], label: string) => {
    if (selectedLabel) {
      const macroButton = macroButtonMap[selectedLabel]!;
      dispatch(
        updateMacro({
          macro: {
            ...macroButton,
            commands: commands.filter((c) => c.length > 0),
          },
          newLabel: label,
          oldLabel: macroButton.label,
        }),
      );
      setSelectedLabel(null);
    }
  };

  const width = 8 * fontDimension.width + 5;

  return (
    <ButtonMenuContainer
      buttonId="MACRO_EDIT"
      width={width}
      height={macroLabels.length * (fontDimension.height + 3)}
      fillColor={fillColor}
    >
      {macroLabels.map((label, index) => (
        <MacroEditRow
          key={label}
          index={index}
          label={label}
          selected={label === selectedLabel}
          onmousedown={() => onmousedownHandler(label)}
        />
      ))}
      {selectedLabel && (
        <MacroEditArea
          key={selectedLabel}
          macroButton={macroButtonMap[selectedLabel]}
          onClose={onClose}
        />
      )}
    </ButtonMenuContainer>
  );
};
export const EramEditMacroButton = (props: EramButtonProps) => {
  const [selected, setSelected] = useState(false);

  return (
    <EramBaseButton
      {...props}
      onmousedown={() => setSelected((prev) => !prev)}
      baseBgColor={
        selected ? baseToggleButtonSelectedColor : colorNameMap.black
      }
      zIndex={selected ? 10 : 0}
    >
      {selected && <MacroEditList />}
    </EramBaseButton>
  );
};
export const EramCreateMacroButton = (props: EramButtonProps) => {
  const dispatch = useRootDispatch();
  const [selected, setSelected] = useState(false);

  const onClose = (commands: EramMessageElement[][], label: string) => {
    dispatch(
      addMacro({
        commands: commands.filter((c) => c.length > 0),
        label,
        position: { x: 100, y: 100 },
      }),
    );
    setSelected(false);
  };

  return (
    <EramBaseButton
      {...props}
      onmousedown={() => setSelected((prev) => !prev)}
      baseBgColor={
        selected ? baseToggleButtonSelectedColor : colorNameMap.black
      }
      zIndex={selected ? 10 : 0}
    >
      {selected && <MacroEditArea onClose={onClose} />}
    </EramBaseButton>
  );
};
export const EramMacroButton = (
  props: EramButtonProps<EramButtonId | "MACRO_BUTTON">,
) => {
  const commands = useRootSelector(
    (state) => macroButtonMapSelector(state)?.[props.macroLabel!]?.commands,
  );

  const onmousedown = () => {
    if (commands) {
      dispatchInsertCommandEvent(
        commands.reduce((acc, cur) => {
          acc.push(...cur, { token: ";" });
          return acc;
        }, []),
      );
    }
  };

  return <EramCommandButton {...props} onmousedown={onmousedown} />;
};
