import React, { createContext, useEffect, useState } from "react";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import {
  removeAltimTearoffs,
  removeMetarTearoffs,
  setAltimTearoffPosition,
  setMetarTearoffPosition,
} from "~redux/slices/eramStateSlice";
import { LogicalPosition } from "@tauri-apps/api/window";
import { TBE, TBP } from "~/eramConstants";
import type { SubmitDelTearOffEvent } from "contexts/tearOffContext";
import { useDragContext } from "contexts/dragContext";
import type { FederatedPointerEvent, Graphics as PixiGraphics } from "pixi.js";
import { clipCursorToWindow, usePixiMouseEventListener, useStableCallback } from "@poscon/shared-frontend";
import { WebviewWindow } from "@tauri-apps/api/webviewWindow";
import { delTearOffCommandActiveSelector } from "~/redux/slices/eramTempStateSlice";

type WeatherTearOffContextValue = {
  startTearOff: (
    event: FederatedPointerEvent,
    airport: string,
    itemType: "METAR" | "ALTIM",
    element: PixiGraphics,
  ) => void;
  toggleDeletionItem: (airport: string, itemType: "METAR" | "ALTIM") => void;
  delTearOffCommandActive: boolean;
  pendingDeletionItems: TearingItem[];
};

export const WeatherTearOffContext = createContext<WeatherTearOffContextValue | null>(null);

type TearingItem = {
  airport: string;
  itemType: "METAR" | "ALTIM";
};

export const WeatherTearOffContextProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useRootDispatch();
  const delTearOffCommandActive = useRootSelector(delTearOffCommandActiveSelector);
  const abIsOpen = useRootSelector((state) => state.eram.toggleButtonState.AB_OPEN);
  const [tearingItem, setTearingItem] = useState<TearingItem | null>(null);
  const [pendingDeletionItems, setPendingDeletionItems] = useState<TearingItem[]>([]);
  const {
    setDraggingOutlineVisible,
    setDraggingOutlinePositionAndDimension,
    getDraggingOutlinePositionAndDimension,
    draggingHandler,
    anyDragging,
    setAnyDragging,
  } = useDragContext();

  const startTearOff = useStableCallback(
    (event: FederatedPointerEvent, airport: string, itemType: "METAR" | "ALTIM", graphics: PixiGraphics) => {
      if (!anyDragging && (event.button === TBP || event.button === TBE)) {
        event.stopImmediatePropagation();
        const pos = graphics.getGlobalPosition();
        let previewPos = { x: event.pageX, y: event.pageY };
        if (window.__TAURI__) {
          if (abIsOpen) {
            void clipCursorToWindow(graphics.width - 1, graphics.height);
          }
          previewPos = { x: pos.x, y: pos.y };
          void WebviewWindow.getCurrent().setCursorPosition(new LogicalPosition(previewPos.x, previewPos.y));
        }
        setTearingItem({ airport, itemType });
        setDraggingOutlinePositionAndDimension(previewPos.x - 1, previewPos.y, graphics.width, graphics.height);
        setAnyDragging(true);
        setDraggingOutlineVisible(true);
        window.addEventListener("mousemove", draggingHandler, { passive: true, capture: true });
      }
    },
  );

  const stopDrag = useStableCallback(() => {
    if (tearingItem) {
      const { x, y } = getDraggingOutlinePositionAndDimension();
      const newPos = { x: x + 1, y: y + 1 };
      if (tearingItem.itemType === "METAR") {
        dispatch(setMetarTearoffPosition({ airport: tearingItem.airport, position: newPos }));
      } else {
        dispatch(setAltimTearoffPosition({ airport: tearingItem.airport, position: newPos }));
      }
      if (window.__TAURI__ && abIsOpen) {
        void WebviewWindow.getCurrent().setCursorGrab(false);
      }
      setAnyDragging(false);
      setDraggingOutlineVisible(false);
      setTearingItem(null);
      window.removeEventListener("mousemove", draggingHandler, { capture: true });
    }
  });
  const toggleDeletionItem = (airport: string, itemType: "METAR" | "ALTIM") => {
    if (pendingDeletionItems.find((item) => item.airport === airport && item.itemType === itemType)) {
      setPendingDeletionItems((items) =>
        items.filter((item) => item.airport !== airport || item.itemType !== itemType),
      );
    } else {
      setPendingDeletionItems((items) => [...items, { airport, itemType }]);
    }
  };

  usePixiMouseEventListener(
    (event) => {
      if (tearingItem && (event.button === TBP || event.button === TBE)) {
        event.stopImmediatePropagation();
        setDraggingOutlineVisible(false);
        stopDrag();
      }
    },
    undefined,
    true,
  );

  useEffect(() => {
    if (!delTearOffCommandActive && pendingDeletionItems.length > 0) {
      setPendingDeletionItems([]);
    }
    const submitDelTearOff = ({ detail }: SubmitDelTearOffEvent) => {
      if (delTearOffCommandActive) {
        const pendingMetarAirports = pendingDeletionItems
          .filter((item) => item.itemType === "METAR")
          .map((item) => item.airport);
        const pendingAltimAirports = pendingDeletionItems
          .filter((item) => item.itemType === "ALTIM")
          .map((item) => item.airport);
        const { stationCode, itemType } = detail;
        if (
          stationCode &&
          itemType &&
          !pendingDeletionItems.find((item) => item.airport === stationCode && item.itemType === itemType)
        ) {
          if (itemType === "METAR") {
            pendingMetarAirports.push(stationCode);
          } else {
            pendingAltimAirports.push(stationCode);
          }
        }
        dispatch(removeMetarTearoffs(pendingMetarAirports));
        dispatch(removeAltimTearoffs(pendingAltimAirports));
        setPendingDeletionItems([]);
      }
    };
    document.addEventListener("submitDelTearOff", submitDelTearOff as EventListener);
    return () => {
      document.removeEventListener("submitDelTearOff", submitDelTearOff as EventListener);
    };
  }, [delTearOffCommandActive, dispatch, pendingDeletionItems]);

  return (
    <WeatherTearOffContext.Provider
      value={{ startTearOff, toggleDeletionItem, delTearOffCommandActive, pendingDeletionItems }}
    >
      {children}
    </WeatherTearOffContext.Provider>
  );
};

export const useWeatherTearOffContext = () => {
  const contextValue = React.useContext(WeatherTearOffContext);
  if (contextValue === null) {
    throw new Error("useWeatherTearOffContext must be used within a WeatherTearOffContextProvider");
  }
  return contextValue;
};
