import React, { useRef } from "react";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { momentaryButtonList } from "types/eramButton";
import type { EramButtonProps } from "components/buttons/EramButton";
import { EramBaseButton } from "components/buttons/EramButton";
import { eramButtonActionMap } from "~redux/thunks/eramButtonActionMap";
import { clipCursorToRectangle, colorNameMap, useUiIsLocked } from "@poscon/shared-frontend";
import type { Container as PixiContainer } from "pixi.js";
import { useSituationDisplay } from "contexts/sdContext";

export const baseToggleButtonSelectedColor = new Uint8Array([0xa0, 0xa0, 0xa0]);

export const EramToggleButton = (props: EramButtonProps) => {
  const bodyRef = useRef<PixiContainer>(null);
  const uiIsLocked = useUiIsLocked();
  const dispatch = useRootDispatch();
  const selected = useRootSelector((state) => eramButtonActionMap[props.buttonId]?.selected?.(state));
  const buttonAction = eramButtonActionMap[props.buttonId]?.action;
  const { unclipCursorFromElement } = useSituationDisplay();
  const onmousedown = () => {
    if (!uiIsLocked && buttonAction) {
      dispatch(buttonAction());
      if (momentaryButtonList.includes(props.buttonId)) {
        if (bodyRef.current) {
          void clipCursorToRectangle(bodyRef.current.getBounds());
        }
        window.addEventListener(
          "mouseup",
          () => {
            void unclipCursorFromElement();
            dispatch(buttonAction());
          },
          { once: true },
        );
      }
    }
  };
  return (
    <EramBaseButton
      {...props}
      bodyRef={bodyRef}
      onmousedown={onmousedown}
      baseBgColor={selected ? baseToggleButtonSelectedColor : colorNameMap.black}
      momentaryTriangleColor={
        momentaryButtonList.includes(props.buttonId)
          ? selected
            ? colorNameMap.black
            : colorNameMap.white
          : undefined
      }
    />
  );
};
