import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "~redux/store";

export type SettingsState = {
  uhdDatablocks: boolean;
};

const initialState: SettingsState = {
  uhdDatablocks: false,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    toggleUhdDatablocks(state) {
      state.uhdDatablocks = !state.uhdDatablocks;
    },
    setSettingsState(state, action: PayloadAction<SettingsState>) {
      return action.payload;
    },
  },
});

export const { toggleUhdDatablocks, setSettingsState } = settingsSlice.actions;

export const reducer = settingsSlice.reducer;

export const uhdDatablocksSelector = (state: RootState) => state.settings.uhdDatablocks;
