import React from "react";
import { View } from "components/utils/View";
import {
  backgroundOption,
  borderOption,
  brightOption,
  buttonOption,
  fontOption,
  linesOption,
  toggleOption,
} from "~redux/slices/viewOptionSlice";

const view = "INBND_LIST";

export const optionMap = {
  background: backgroundOption(view),
  border: borderOption(view),
  tearoff: toggleOption(view, "tearoff", "TEAROFF", 7),
  lines: linesOption(view, 5, 25),
  font: fontOption(view),
  bright: brightOption(view),
  sort: buttonOption(view, "SORT", 4),
};

export const InboundList = () => {
  return <View width={20} height={0} view="INBND_LIST" optionMap={optionMap} />;
};
