import { useEffect, useRef } from "react";
import { macroButtonMapSelector, tearOffButtonPositionSelector } from "~redux/slices/eramStateSlice";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { LogicalPosition } from "@tauri-apps/api/window";
import type { EramButtonId } from "types/eramButton";
import { isPriorityButton } from "types/eramButton";
import { useTearOffContext } from "contexts/tearOffContext";
import { TBE, TBP } from "~/eramConstants";
import type { FederatedPointerEvent } from "pixi.js";
import { useButtonContext } from "contexts/buttonContext";
import { useDragContext } from "contexts/dragContext";
import { clipCursorToSituationDisplay } from "~/utils/cursor";
import { WebviewWindow } from "@tauri-apps/api/webviewWindow";
import { clipCursorToWindow, setSelectedViewOption, useStableCallback } from "@poscon/shared-frontend";

/**
 * hook to provide startDrag/endDrag functions with a previewStyle to render the previewWindow
 * @param buttonId
 * @param macroLabel
 * @returns
 */
export const useTearOff = (buttonId: EramButtonId | "MACRO_BUTTON", macroLabel?: string) => {
  const dispatch = useRootDispatch();
  const { setTearingButton } = useTearOffContext();
  const { buttonWidth, buttonHeight } = useButtonContext();
  const position = useRootSelector((state) =>
    buttonId === "MACRO_BUTTON"
      ? macroButtonMapSelector(state)[macroLabel!]!.position
      : tearOffButtonPositionSelector(state, buttonId),
  )!;
  const {
    setDraggingOutlineVisible,
    setDraggingOutlinePositionAndDimension,
    draggingHandler: _draggingHandler,
    setAnyDragging,
  } = useDragContext();
  const draggingHandlerRef = useRef((event: MouseEvent) => {
    _draggingHandler(event, isPriorityButton(buttonId) || !abIsOpen);
  });

  const abIsOpen = useRootSelector((state) => state.eram.toggleButtonState.AB_OPEN);

  useEffect(() => {
    draggingHandlerRef.current = (event: MouseEvent) => {
      _draggingHandler(event, isPriorityButton(buttonId) || !abIsOpen);
    };
  }, [_draggingHandler, abIsOpen, buttonId]);

  const startDrag = useStableCallback(async (event: FederatedPointerEvent) => {
    if (event.button === TBP || event.button === TBE) {
      event.stopImmediatePropagation();
      dispatch(setSelectedViewOption(null));
      let previewPos;
      if (window.__TAURI__) {
        if (isPriorityButton(buttonId) || !abIsOpen) {
          await clipCursorToSituationDisplay(buttonWidth - 1, buttonHeight - 1);
        } else {
          await clipCursorToWindow(buttonWidth - 1, buttonHeight - 1);
        }
        previewPos = { x: position.x, y: position.y };
        await WebviewWindow.getCurrent().setCursorPosition(new LogicalPosition(previewPos.x, previewPos.y));
      } else {
        previewPos = { x: event.clientX, y: event.clientY };
      }
      setDraggingOutlinePositionAndDimension(previewPos.x, previewPos.y, buttonWidth, buttonHeight);
      setDraggingOutlineVisible(true);
      setAnyDragging(true);
      setTearingButton(buttonId, macroLabel);
      window.addEventListener("mousemove", (event) => draggingHandlerRef.current(event), {
        passive: true,
        capture: true,
      });
    }
  });

  return { startDrag, position };
};
