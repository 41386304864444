import type { EramView } from "types/eramView";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import type React from "react";
import { pushZStack, zStackSelector } from "~redux/slices/eramStateSlice";
import { usePixiMouseEventListener } from "@poscon/shared-frontend";
import type { DisplayObject } from "pixi.js";

export const useZIndex = (view: EramView, ref: React.RefObject<DisplayObject>) => {
  const dispatch = useRootDispatch();
  const zStack = useRootSelector(zStackSelector);
  usePixiMouseEventListener(() => {
    if (zStack.indexOf(view) !== zStack.length - 1 || zStack.length === 0) {
      dispatch(pushZStack(view));
    }
  }, ref);
  return zStack.indexOf(view) + 2;
};
